import React, {Component} from 'react';
import {generalActions} from '../Redux/Actions/generalActions';
import {connect} from 'react-redux';

// import firebase from 'firebase/app';
import * as firebase from 'firebase';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css';
// import { askForPermissioToReceiveNotifications } from '../Helpers/firebase';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Utility from '../Helpers/Utility';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';


class IntroductionScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: '',
            eventId: this.props.match.params.idEvent,
            eventType: "default",
            event: "default",
        };
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (!participant) {
            this.props.history.push("/");
        } else {
            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeEventInfoChange = unsubscribe;

                if (this.state.eventType === "default") {
                    this.setState({
                        ...this.state,
                        eventType: 'setSplashPage',
                        event: callback.data,
                        participantInfo: participant,
                    });
                } else {

                }

                //Jump to rooms if splash image is not defined.
                if (callback.data.splashImageUrl === "default") {
                    this.props.history.push("/event/" + this.state.eventId + "/rooms");
                }                
            });
        }
        /* setTimeout(function(){
            this.goTo();
        }.bind(this),3000); */
    }


    componentWillUnmount() {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }
    }

    goTo() {

        this.props.history.push("/event/" + this.state.eventId + "/rooms");
    }

    render() {
        var selectedLanguageStrings;
        let userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        return (
            <main role="main">
                <div>
                    {(this.state.event === "default") ? (
                        <div></div>

                    ) : (
                        <div className="container" onClick={() => {
                            this.goTo()
                        }}>
                            <Tooltip
                                // options
                                title="Tap to start"
                                position="top"
                                trigger={"mouseenter"}
                                // distance="15"
                                animation={"fade"}
                                animateFill={false}
                                duration={400}
                                hideDelay={5}
                                followCursor={true}
                            >
                                {/* <SplashComponent
                                splashImage={this.state.event.splashImageUrl}
                                eventId={this.state.eventId}
                                // goTo={this.goTo.bind(this)}
                                // startTimerFunction={this.startTimerToGo}
                                splashImage={this.props.splashPageData.imageUrl}
                                event={this.state.event}
                            /> */}
                                <div className="image-container"
                                     style={{backgroundImage: "url(" + this.state.event.splashImageUrl + ")"}}>
                                </div>
                            </Tooltip>

                            <div className="d-flex justify-content-center fixedFooter">
                                <div className="startText text-center"><i
                                    className="fas fa-hand-pointer text-secondary pr-3"> {selectedLanguageStrings.tap_to_start}</i>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onSetSplashPage: (splashImageUrl, status) => dispatch(generalActions.setSplashPage(splashImageUrl, status)),
        onUpdateClientNotificationToken: (userId, eventId, token) => dispatch(generalActions.updateClientNotificationToken(userId, eventId, token)),
    };
}

function mapStateToProps(state) {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
        eventType: state.generalReducers.eventType,
        splashPageData: state.generalReducers.splashPageData,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionScreen);