export const actionTypes = {
    /********************* General actions  ************************/
    GET_EVENT_DATA: 'GET_EVENT_DATA',
    GET_EVENT_DATA_BY_ID: 'GET_EVENT_DATA_BY_ID',
    PARTICIPANT_PREFERENCES_UPDATED_SUCCESS:'PARTICIPANT_PREFERENCES_UPDATED_SUCCESS',
    GET_PARTICIPANT_DATA_SUCCESS:'GET_PARTICIPANT_DATA_SUCCESS',
    LOGIN_REQUEST_SUCCESS:'LOGIN_REQUEST_SUCCESS',
    SETTING_STATUS_UPDATED_SUCCESS:'SETTING_STATUS_UPDATED_SUCCESS',
    SET_SPLASH_PAGE_SUCCESS:'SET_SPLASH_PAGE_SUCCESS',
    UPDATE_USER_NOTIFICATION_TOKEN_SUCCESS:'UPDATE_USER_NOTIFICATION_TOKEN_SUCCESS',
    FETCH_ALL_EVENT_MESSAGES_SUCCESS:'FETCH_ALL_EVENT_MESSAGES_SUCCESS',
    FETCH_ALL_EVENT_MESSAGES_ADDED_SUCCESS:'FETCH_ALL_EVENT_MESSAGES_ADDED_SUCCESS',
    GET_EVENT_ROOMS_SUCCESS:'GET_EVENT_ROOMS_SUCCESS',
    GET_PARENT_ELEMENT_INFO__SUCCESS:'GET_PARENT_ELEMENT_INFO__SUCCESS',
    GET_PARENT_ELEMENT_INFO_ON_CHANGE_SUCCESS:'GET_PARENT_ELEMENT_INFO_ON_CHANGE_SUCCESS',
    GET_SURVEY_INFO_ON_CHANGE_SUCCESS:'GET_SURVEY_INFO_ON_CHANGE_SUCCESS',
    GET_PARTICIPANTS_NUMBERS_SUCCESS:"GET_PARTICIPANTS_NUMBERS_SUCCESS",
    GET_QR_TEXT:"GET_QR_TEXT",
    GET_FILE_NAME_ON_CHANGE_SUCCESS:"GET_FILE_NAME_ON_CHANGE_SUCCESS",
    UPDATE_SERVERHEARTBEAT_SUCCESS:"UPDATE_SERVERHEARTBEAT_SUCCESS",
    RESET_SERVERHEARTBEAT_SUCCESS:"RESET_SERVERHEARTBEAT_SUCCESS",
    /********************* Constants of the questions actions  ************************/
    Fetch_EVENT_INFO_CHANGE_SUCCESS: 'Fetch_EVENT_INFO_CHANGE_SUCCESS',
    Fetch_ALL_QUESTION_OPTIONS_SUCCESS: 'Fetch_ALL_QUESTION_OPTIONS_SUCCESS',
    Fetch_QUESTION_DATA_SUCCESS:'Fetch_QUESTION_DATA_SUCCESS',
    SEND_VOTE_SUCCESS:'SEND_VOTE_SUCCESS',
    SEND_ANSWER_TEXT_SUCCESS:'SEND_ANSWER_TEXT_SUCCESS',
    SEND_RATING_ANSWER_SUCCESS:'SEND_RATING_ANSWER_SUCCESS',
    GET_ALL_LIBRARY_FILES_SUCCESS:'GET_ALL_LIBRARY_FILES_SUCCESS',
    GET_ALL_SURVEY_FILES_SUCCESS:'GET_ALL_SURVEY_FILES_SUCCESS',
    GET_LIBRARY_INFO_SUCCESS:'GET_LIBRARY_INFO_SUCCESS',
    GET_ROOT_CHILDREN_SUCCESS:'GET_ROOT_CHILDREN_SUCCESS',
    GET_ALL_AGENDA_FILES_SUCCESS:'GET_ALL_AGENDA_FILES_SUCCESS',
    GET_LIBRARY_FILE_SUCCESS:"GET_LIBRARY_FILE_SUCCESS",

    /************************* Tweet actions  ****************************/
    GET_ALL_TWEETS_SUCCESS:'GET_ALL_TWEETS_SUCCESS',
    GET_ALL_PARTICIPANT_TWEETS_SUCCESS:'GET_ALL_PARTICIPANT_TWEETS_SUCCESS',
    SAVE_NEW_TWEET_SUCCESS:'SAVE_NEW_TWEET_SUCCESS',
    GET_ALL_APPROVED_TWEETS_SUCCESS:'GET_ALL_APPROVED_TWEETS_SUCCESS',
   
    

    


}