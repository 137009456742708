import React, { Component, Button } from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
import Header from '../Component/Header';
import moment from 'moment';
import Utility from '../Helpers/Utility';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';


class FileViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            libraryId: this.props.match.params.idLibrary,
            fileId: this.props.match.params.idFile,
            currentFile: "default",
            parentInfo: 'default',
            participantInfo: 'default',
            numPages: null,
            pageNumber: 1,
            pdfUrl: "default",
            loadfile: "default",
            event: "",
            fileUrl: null,
            fileName: null,
            newParticipant: false,
            userStatus: 'initialized'
        };

        this.gotoEventIntro = this.gotoEventIntro.bind(this);
        this.goToSurveyPageModal = this.goToSurveyPageModal.bind(this);
        this.goToQuestionPage = this.goToQuestionPage.bind(this);
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (!participant) {
            this.props.history.push("/");
        } else {

            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeEventInfoChange = unsubscribe;

                if (callback.data.eventStatus === "Archived") {
                    window.location.href = "/";
                }

                this.setState({
                    ...this.state,
                    event: callback.data,
                    participantInfo: participant
                });

                if (!callback.data.availableLanguages.lang_it || !callback.data.availableLanguages.lang_en) {
                    if (callback.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (callback.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
                }                
            });

        if (this.unsubscribeGetLibraryInfoOnChange) {
            this.unsubscribeGetLibraryInfoOnChange();
        }
        this.props.getLibraryInfoOnChange(this.state.eventId, this.state.libraryId, (libraryData, unsubscribe) => {
            this.unsubscribeGetLibraryInfoOnChange = unsubscribe;
            
            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.roomId, (parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                this.setState({
                    ...this.state,
                    parentInfo: parentInfo.data,
                });

                var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));

                    var userAlreadyVoted = false;
                if (parentInfo.message === 'success') {
                    if (parentInfo.data.currentLiveSession.status === "live") {

                        //we only go to the tweet question if the user did not already voted for the live question
                            if (userAlreadyVoted === false && storedVotes && storedVotes.includes(parentInfo.data.currentLiveSession.id)) {
                                userAlreadyVoted = true;
                        }

                            if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(parentInfo.data.currentLiveSession.id)) {
                                userAlreadyVoted = true;
                        }

                            if (!this.props.changedEventInfo || this.props.changedEventInfo.automaticJumpPoll === "Active") {
                                if (userAlreadyVoted === false) {
                                    this.goToPollPage();
                                }
                        }
                    }
                }
            });
        });

            //TODO: not clear. To be analyzed and optimized (FC).
        this.props.onGetLibraryFileInfo(this.state.eventId, this.state.libraryId, this.state.fileId, file => {
            this.props.onGetEventDataById(this.state.eventId, callback => {
                if (callback === 'CodeNotExist') {
                    this.setState({
                        ...this.state,
                        inputClass: "is-invalid",
                    });
                } else {
                    this.setState({
                        ...this.state,
                        event: callback
                    });

                        var userStoredLanguage = Utility.getEventLanguage();

                    let participant = JSON.parse(localStorage.getItem('participant-' + callback.id));
                    let internal = this.props.match.params.bInternal;

                    if (internal === "internal")
                        this.setState({ newParticipant: false });
                    else
                        this.setState({ newParticipant: true });

                    if (participant && participant.length !== 0) {
                        // we check if the user is logging to the same event thet he has been previously logged in or is a new event 
                        if (participant.eventId === callback.id) {
                            if (internal === "internal") {
                                this.props.onUpdateParticipantData(participant, callback.id, 'newLogin', userStoredLanguage);
                                //window.history.back();

                                this.setState({ fileUrl: file.fileUrl/* , fileName: file.name */ });
                                //this.downloadFile(file);
                            } else {
                                    this.props.onUpdateParticipantData(participant, callback.id, 'newLogin', userStoredLanguage);
                                //this.props.history.push("/event/"+ callback.id + "/introduction");

                                this.setState({ fileUrl: file.fileUrl/* , fileName: file.name */ });
                                //this.downloadFile(file);
                            }
                            // this.setState({...this.state,splashImageUrl: callback.splashImageUrl});
                        } else {
                            // var defaultSelectedLanguage = "English";
                                var value = userStoredLanguage;
                            this.props.onLogin(callback.id, value, result => {
                                if (result === "success") {
                                    //this.props.history.push("/event/"+ callback.id + "/introduction");

                                    this.setState({ fileUrl: file.fileUrl/* , fileName: file.name */ });
                                    //this.downloadFile(file);
                                    // this.setState({...this.state,splashImageUrl: callback.splashImageUrl});
                                }
                            });
                        }

                    } else {
                            var value = "English";
                        this.props.onLogin(callback.id, value, result => {
                            if (result === "success") {
                                //this.props.history.push("/event/"+ callback.id + "/introduction");

                                this.setState({ fileUrl: file.fileUrl/* , fileName: file.name */ });
                                //this.downloadFile(file);
                                this.setState({ ...this.state, splashImageUrl: callback.splashImageUrl });
                            }
                        });
                    }

                }

            });

        });

        if (this.unsubscribeGetFileNameOnChange) {
            this.unsubscribeGetFileNameOnChange();
        }
        this.props.onGetFileNameOnChange(this.state.eventId, this.state.libraryId, this.state.fileId, (fileDoc, unsubscribe) => {
            this.unsubscribeGetFileNameOnChange = unsubscribe;
            this.setState({ ...this.state, fileName: fileDoc.name });
        });

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }
        this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (rootChildrenList, unsubscribe) => {
            this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
            let orederdRootChildrenList = [];
            let orderedRoomsChildrenList = [];
            let allElementsList = [];
            for (var i = 0; i < rootChildrenList.length; i++) {
                if (rootChildrenList[i].sParentId === "default") {
                    orederdRootChildrenList.push(rootChildrenList[i]);
                } else if (rootChildrenList[i].sParentId === this.state.roomId) {
                    orderedRoomsChildrenList.push(rootChildrenList[i]);
                }
            }

            orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);
                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {

                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }
                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;
    
                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                }
                            });
    
                        });
    
                    }
                });
            this.setState({ ...this.state, rootChildrenList: allElementsList });
        });


        //var storage = firebase1.storage();
        // var httpsReference = storage.refFromURL("Library-Files/Event-"+this.state.eventId+"/Library-"+this.state.libraryId + "/LibraryFiles-"+this.state.fileId);
        //console.log("httpsReference,,,,,,,,,", "Library-Files/Event-"+this.state.eventId+"/Library-"+this.state.libraryId + "/LibraryFiles-"+this.state.fileId)
        // httpsReference.getDownloadURL().then(function(url) {
        //     // Insert url into an <img> tag to "download"
        //     console.log("url,,,,,,,,,", url)
        //     // this.setState({pdfUrl:url });
        //   }).catch(function(error) {

        //     // A full list of error codes is available at
        //     // https://firebase.google.com/docs/storage/web/handle-errors
        //     switch (error.code) {
        //       case 'storage/object-not-found':
        //         // File doesn't exist
        //         break;

        //       case 'storage/unauthorized':
        //         // User doesn't have permission to access the object
        //         break;

        //       case 'storage/canceled':
        //         // User canceled the upload
        //         break;

        //       case 'storage/unknown':
        //         // Unknown error occurred, inspect the server response
        //         break;
        //     }
        //   });


            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
                    }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);
    }
    }

    componentDidUpdate(prevProps) {

        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        } else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                } else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetLibraryInfoOnChange) {
            this.unsubscribeGetLibraryInfoOnChange();
        }

        if (this.unsubscribeGetFileNameOnChange) {
            this.unsubscribeGetFileNameOnChange();
        }

        if (this.unsubscribeGetSurveyFiles) {
            this.unsubscribeGetSurveyFiles();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }
    }
        
    goToDashboardPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId);
    }

    goToPollPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId + "/poll");
    }

    goRoomPage() {

        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToAgendaPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda")
    }

    goToQuestionPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goToRootChild(item, type) {
        if (type === "agenda") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
            this.setState({ ...this.state, surveyId: item.id });
            var surveyVotes = JSON.parse(localStorage.getItem("surveyVotes"));
            if (surveyVotes && surveyVotes.includes(item.id)) {
                this.setState({
                    ...this.state,
                    surveyAnswered: true
                });
            } else {
                if (this.unsubscribeGetSurveyFiles) {
                    this.unsubscribeGetSurveyFiles();
                }
                this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                    this.unsubscribeGetSurveyFiles = unsubscribe;
                    if (surveyFilesList.length > 0) {
                        for (var i = 0; i < surveyFilesList.length; i++) {
                            if (surveyFilesList[i] && surveyFilesList[i].fileUrl) {
                                this.setState({
                                    ...this.state,
                                    surveyAnswered: false,
                                    surveyFilesList,
                                    currentSurveyFile: surveyFilesList[i],
                                });
                                return;
                            }

                        }

                    }
                })
            }
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
        } else if (type === "qrcode") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
        } else if (type === "library") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
        }
    }

    goToSurveyPageModal(survey) {

        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }

        if (survey.startInIframe) {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + survey.id);
        }
        else {
            this.props.onGetSurveyFilesData(this.props.changedEventInfo.id, survey.id, (surveyfiles) => {
                window.open(surveyfiles[0].fileUrl, "_blank");
            });
        }
    }
        

    onSelectLanguage(option) {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (option === 'English') {
            var value = 'English';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        } else {
            var value = 'Italian';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        }
    }

    downloadFile(file) {

        // this.setState({...this.state, currentFile:file, loadfile:"loading"});

        window.location.assign(file.fileUrl);
        /* const link = document.createElement('a');
        link.href = file.fileUrl;
        link.target="_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); */
        //window.history.back();
    }

    gotoEventIntro() {
        this.props.history.push("/event/" + this.state.eventId + "/introduction");
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const { pageNumber, numPages } = this.state;
        var headerComponent;
        var selectedLanguageStrings;
        let userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage && userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    goToDashboardPage={this.goToDashboardPage.bind(this)}
                    goToQuestionPage={this.goToQuestionPage.bind(this)}
                    goToAgendaPage={this.goToAgendaPage.bind(this)}
                    goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    pageName={"FileViewerScreen"}
                    surveyId={this.state.surveyId}
                    rootChildrenList={this.state.rootChildrenList}
                />
            </div>);
        }
        var containerContentComponent = (
            <div>
                <div className="row pt-5">
                    <div className="col-12 text-center">
                        <h3 style={{ color: this.props.changedEventInfo.textColor }}>{this.state.fileName}</h3>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 text-center">
                        <a className="btn btn-success text-white"
                           href={this.state.fileUrl}>{selectedLanguageStrings.open_document}</a>
                    </div>
                </div>
                {
                    this.state.newParticipant === true &&
                    <div className="row pt-5 center">
                        <div className="col-12 text-center">
                            <button className="btn btn-primary"
                                    onClick={this.gotoEventIntro}>{selectedLanguageStrings.goto_eventintro}</button>
                        </div>
                    </div>
                }
            </div>
        )

        return (
            <div>
                <div className="headercontainer">
                    {headerComponent}
                </div>
                <div>
                    <main role="main">
                        {(this.state.event.themeParticipantImageUrl === "default") ? (
                            <div className="container container-screen-default" style={{ paddingTop: "80px" }}>
                                {containerContentComponent}
                            </div>
                        ) : (
                            <div className="imageTheme"
                                 style={{backgroundImage: "url(" + this.state.event.themeParticipantImageUrl + ")"}}>
                                <div className="container container-screen" style={{ paddingTop: "80px" }}>
                                    {containerContentComponent}
                                </div>
                            </div>
                        )}
                    </main>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLibraryInfoOnChange: (eventId, libraryId, callback) => dispatch(generalActions.getLibraryInfoOnChange(eventId, libraryId, callback)),
        onGetLibraryFileInfo: (eventId, libraryId, fileId, callback) => dispatch(generalActions.getLibraryFileInfo(eventId, libraryId, fileId, callback)),
        onGetEventDataById: (eventId, callback) => dispatch(generalActions.getEventDataById(eventId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onLogin: (eventId, value, callback) => dispatch(generalActions.login(eventId, value, callback)),
        onGetFileNameOnChange: (eventId, libraryId, fileId, fileName) => dispatch(generalActions.getFileNameOnChange(eventId, libraryId, fileId, fileName)),
        onGetSurveyFilesData: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFilesData(eventId, surveyId, callback))
    };
};
const mapStateToProps = state => {
    return {
        parentInfo: state.generalReducers.parentInfo,
        changedEventInfo: state.generalReducers.changedEventInfo,
    };


};

export default connect(mapStateToProps, mapDispatchToProps)(FileViewer);