import firestore from '../Helpers/firebase';
import firebase from 'firebase/app';
import firebase1 from 'firebase';

//export let unsubscribeParentInfo = null;


export const generalFunctions = {
    login,
    getEventData,
    getEventDataById,
    updateParticipantData,
    sendVote,
    sendAnswerText,
    sendRatingAnswer,
    updateClientNotificationToken,
    getAllEventMessages,
    getEventRoomsOnChange,
    getParentInfo,
    getEventInfoOnChange,
    getLibraryFiles,
    getLibraryInfoOnChange,
    getParentInfoOnChange,
    geteventDataDefault,
    getListOfRootChildrenOnChange,
    getAllAgendaFilesOnChange,
    getSurveyFiles,
    getSurveyInfoOnChange,
    getLibraryFileInfo,
    getParticipantsNumber,
    getQRText,
    getFileNameOnChange,
    getServerHeartBeatOnChange,
    getSurveyFilesData,
    // getEventAdminInfo
};

function login(idEvent, value) {
    return firestore.collection("events/" + idEvent + "/participants")
        .add({
            dtmFirstLogin: firebase1.firestore.FieldValue.serverTimestamp(),
            dtmLastLogin: firebase1.firestore.FieldValue.serverTimestamp(),
            selectedLanguage: value
        });
}

function getEventData(eventCode, callback) {
    return firestore.collection("events").where("eventCode", "==", eventCode).where("eventIsDeleted", "==", false).get();
}

function getEventDataById(eventId) {
    return firestore.collection("events/").doc(eventId).get();
}

function getListOfRootChildrenOnChange(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/").where("elementIsDeleted", "==", false).onSnapshot(
        querySnapShot => {
            let rootChildrenList = [];
            querySnapShot.forEach(elementDoc => {
                let rootChild = elementDoc.data();
                rootChild.id = elementDoc.id;
                rootChildrenList.push(rootChild);
            });
            callback(rootChildrenList, unsubscribe);
        });
}

function getAllAgendaFilesOnChange(eventId, roomId, agendaId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + agendaId + "/files/").onSnapshot(
        querySnapShot => {
            let agendaFilesList = [];
            querySnapShot.forEach(elementDoc => {
                let agendaFile = elementDoc.data();
                agendaFile.id = elementDoc.id;
                agendaFilesList.push(agendaFile);
            });
            callback(agendaFilesList, unsubscribe);
        });
}

function getEventRoomsOnChange(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements").where("elementIsDeleted", "==", false).where("visible", "==", true).onSnapshot(
        querySnapShot => {
            let eventRoomsList = [];
            querySnapShot.forEach(elementDoc => {
                if (elementDoc.data().sType === "room") {
                    let eventRoom = elementDoc.data();
                    eventRoom.id = elementDoc.id;
                    eventRoomsList.push(eventRoom);
                }
            });
            callback(eventRoomsList, unsubscribe);
        });
}

function getLibraryInfoOnChange(eventId, libraryId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/").doc(libraryId).onSnapshot(libraryDoc => {
        if (libraryDoc.data() !== undefined) {
        callback(libraryDoc, unsubscribe);
        }
    });
}

function getFileNameOnChange(eventId, libraryId, fileId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files").doc(fileId).onSnapshot(fileDoc => {
        if (fileDoc.data() !== undefined) {
        callback(fileDoc, unsubscribe);
        }
    });
}

function getLibraryFiles(eventId, libraryId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files").orderBy("dtmCreated", "desc").onSnapshot(querySnapShot => {
        let libraryFilesList = [];
        querySnapShot.forEach(fileDoc => {
            let file = fileDoc.data();
            file.id = fileDoc.id;
            // libraryFilesList.push(file);
            libraryFilesList[fileDoc.data().fileOrderIndex] = file;

        });
        callback(libraryFilesList, unsubscribe);
    });
}

function getSurveyFiles(eventId, surveyId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + surveyId + "/files").orderBy("dtmCreated", "desc").onSnapshot(querySnapShot => {
        let surveyFilesList = [];
        querySnapShot.forEach(fileDoc => {
            let file = fileDoc.data();
            file.id = fileDoc.id;
            // libraryFilesList.push(file);
            //surveyFilesList[fileDoc.data().fileOrderIndex] = file;
            surveyFilesList.push(file);
        });
        callback(surveyFilesList, unsubscribe);
    });
}

function getSurveyFilesData(eventId, surveyId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + surveyId + "/files").orderBy("dtmCreated", "desc").get();
}

function getParentInfo(eventId, selectedRoomId) {
    return firestore.collection("events/" + eventId + "/elements/").doc(selectedRoomId).get();
}

function getParentInfoOnChange(eventId, selectedRoomId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/").doc(selectedRoomId).onSnapshot(parentInfo => {
        if (parentInfo.data() !== undefined) {
        callback(parentInfo, unsubscribe);
        }
    });
}

function updateParticipantData(participant, eventId, option, value) {
    switch (option) {
        case 'sTweetsOrder':
            Object.assign(participant, {'sTweetsOrder': value});
            localStorage.setItem("participant", JSON.stringify(participant));
            return firestore.collection("events/" + eventId + "/participants/").doc(participant.id).update({sTweetsOrder: value});
        case 'sName':
            Object.assign(participant, {'sName': value});
            localStorage.setItem("participant", JSON.stringify(participant));
            return firestore.collection("events/" + eventId + "/participants/").doc(participant.id).update({sName: value});
        case 'newLogin':
            return firestore.collection("events/" + eventId + "/participants").doc(participant.id).update({
                dtmLastLogin: firebase1.firestore.FieldValue.serverTimestamp(),
                selectedLanguage: value
            });
        case 'userInfo':
            // let participant = JSON.parse(localStorage.getItem('participant-'+callback.id));  
            Object.assign(participant, {'sEmail': value.email, 'sName': value.name});
            localStorage.setItem('participant-' + eventId, JSON.stringify(participant));
            return firestore.collection("events/" + eventId + "/participants/").doc(participant.id).update({
                sName: value.name,
                sEmail: value.email
            });

        case 'changedLanguage':
            return firestore.collection("events/" + eventId + "/participants/").doc(participant.id).update({
                selectedLanguage: value
            });

        default:
            return true;
    }
}

//Getting information of the live question session
function getEventInfoOnChange(eventId, callback) {
    let unsubscribe = firestore.collection("events/").doc(eventId)
        .onSnapshot(querySnapShot => {
            if (querySnapShot.data() !== undefined) {
            callback(querySnapShot, unsubscribe);
            }
        });
}

function geteventDataDefault(eventId) {
    return firestore.collection("events/").doc(eventId).get();
}

function sendVote(type, eventId, elementId, questionId, sessionId, value, participantsId, callback) {
    // return  firestore.collection("events/" + eventId + "/elements/"+elementId+"/questions/" +questionId+"/VotingSession/"+sessionId+"/Responses/").add({option:vote, participant:'Anita'})
    //Storing each of the participant responses as a seperate document in firebase
    //we will wait to handle sending the vote until we recive an event change which has a timestamp greater than the disconnection time 
    if (type === "SimpleTextAndMultipleChoice") {
        let firestoreResults = [];
        for (let index = 0; index < value.optionsList.length; index++) {
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession/" + sessionId + "/Responses/").add({
                option: value.optionsList[index],
                participantsId: participantsId,
                sAnswer: value.comment,
            }).then(result => {
                firestoreResults.push(result);
            });
        }
        firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession").doc(sessionId).update({ voted: true });    
        callback({message: 'SUCCESS', data: firestoreResults});
    } else {
        let firestoreResults = [];
        for (let index = 0; index < value.length; index++) {
            firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession/" + sessionId + "/Responses/").add({
                option: value[index],
                participantsId: participantsId,

            }).then(result => {
                firestoreResults.push(result);
            });
        }
        firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession").doc(sessionId).update({ voted: true });    
        callback({message: 'SUCCESS', data: firestoreResults});
    }
}


function sendAnswerText(eventId, elementId, questionId, sessionId, participantReply, participantName, participantsId) {
    
    let result = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession/" + sessionId + "/Responses/").add({
        sAnswer: participantReply,
        sName: participantName,
        participantsId: participantsId,
        dtmCreated: firebase1.firestore.FieldValue.serverTimestamp(),
        isHidden: false
    });
    firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession").doc(sessionId).update({ voted: true });    

    return result;
}

function sendRatingAnswer(eventId, elementId, questionId, sessionId, participantRating, participantName, participantsId) {
    let result = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession/" + sessionId + "/Responses/").add({
        ratingNumber: participantRating,
        sName: participantName,
        participantsId: participantsId,
        dtmCreated: firebase1.firestore.FieldValue.serverTimestamp(),
    });
    firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/VotingSession").doc(sessionId).update({ voted: true });    
    return result;
}

function updateClientNotificationToken(userId, eventId, token) {
    return firestore.collection("events/" + eventId + "/participants").doc(userId).update({notificationToken: token});
}

function getAllEventMessages(eventId, callback) {
    // return firestore.collection("events/" + eventId + "/messages").get();

    let unsubscribe = firestore.collection("events/" + eventId + "/messages").where("elementIsDeleted", "==", false).orderBy("dtmMessage", "desc").onSnapshot(snapshotEventMessages => {
        let eventMessageList = [];
        snapshotEventMessages.forEach(eventMessageDoc => {
            let eventMessageData = eventMessageDoc.data();
            eventMessageData.id = eventMessageDoc.id;
            eventMessageList.push(eventMessageData);
        });
        callback(eventMessageList, unsubscribe);
    });
}

function getSurveyInfoOnChange(eventId, elementId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(elementId).onSnapshot(function (surveyDoc) {
        if (surveyDoc.data() !== undefined) {
        let surveyElement = surveyDoc.data();
        surveyElement.id = surveyDoc.id;
        callback(surveyElement, unsubscribe);
        }
    });
}

function getLibraryFileInfo(eventId, libraryId, fileId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + libraryId + "/files").doc(fileId).get();
}

function getParticipantsNumber(eventId) {
    return firestore.collection("events/" + eventId + "/participants").get();
}

function getQRText(eventId, qrcodeId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems").doc(qrcodeId).onSnapshot(function (qrcodeDoc) {
        let qrcodeElement = qrcodeDoc.data();
        callback(qrcodeElement.qrText, unsubscribe);
    });
}

function getServerHeartBeatOnChange(eventId, callback) {
    let unsubscribe = firestore.collection("events/" + eventId + "/elements/default/heartBeat/").doc("server").onSnapshot(serverHeartBeat => {
        callback(serverHeartBeat.data(), unsubscribe);
    });
}