export const italianStrings = {

/********************* LOGIN PAGE *************************/
event_code:"Codice Evento",
enter:"Accedi",
event_code_error:"Si prega di inserire un codice evento valido.",
evet_code_request:"Il codice evento è obbligatorio.",
tap_to_start:"Tap per iniziare",
/********************* MENU *************************/
main_menu:"Menu principale",
home:"Home",
logout:"Esci",
event_messages:"Messaggi Evento",
english:"Inglese",
italian:"Italiano",
select_language:"Seleziona la lingua",
select_room:"Seleziona la Sala",
choose_event_room:"Seleziona la Sala",
lis_materials:"Lista dei materiali",

/********************* DASHBORAD *************************/
// tweets:"TWEETS",
// questions:"DOMANDE",
// live_question:"Domanda",
// live_tweets:"LIVE TWEETS",
// your_tweets: "I TUOI TWEET",
// your_tweets_note: "Non hai ancora inviato nessun tweet.",
// tweet_modal_title:"Scrivi un nuovo tweet",
// tweets_questions_menu:"Tweets/Domande",


live_question:"SONDAGGIO",
tweets:"DOMANDE",
questions:"SONDAGGI",
live_tweets:"DOMANDE LIVE",
your_questions: "LE TUE DOMANDE",
your_tweets_note: "Non hai ancora inviato nessuna domanda.",
tweet_modal_title:"Scrivi una nuova domanda",
tweets_questions_menu:"Domande/Sondaggi",
questions_menu:"Domande",


agenda:"Programma",
survey:"Survey",

/********************* TWEETS *************************/
approved_tweets:"TWEET APPROVATI",


more_recent: "Più Recenti",
less_recent: "Meno Recenti",
your_question:"La tua domanda",
your_name:"Il tuo nome",
optional:"opzionale",
send_anonymous:"Invia in modo anonimo",

cancel:"Annulla",
send:"Invia",

/********************* QUESTIONS *************************/

all_tweets:"Tutti i tweet",
send_answer:"Invia Risposta",
your_answer:"La tua risposta",
thanks_your_answer:"Grazie per la tua risposta.",
reason_comment: "Perché preferisci questa opzione?",


/********************* PROFILE *************************/
profile_modal_title:"Il tuo profilo",
your_email_address:"Il tuo indirizzo email",
profile_note:"Non condivideremo mai la tua email con nessuno.",

/******************** Library *************************/
no_materials:"Nessun file è stato caricato.",
save:"Salva",
event_message_title:"Titolo",
no_agenda_language:"Nessun programma è disponibile in questa lingua.",

open_document:"APRI IL DOCUMENTO",
goto_eventintro:"VAI ALLA HOME PAGE DELL'EVENTO",

/************* SURVEY **************/
live_survey: "Questionario",
please_answer_survey: "Per favore entra in:"

}