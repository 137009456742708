import { actionTypes } from '../Actions/actionTypes';

export function tweetsReducers(state = [], action) {
    switch (action.type) {
        case actionTypes.GET_ALL_TWEETS_SUCCESS:
            return {
                ...state,
                allTweetsList: action.allTweetsList
            }
        case actionTypes.GET_ALL_PARTICIPANT_TWEETS_SUCCESS:
            return {
                ...state,
                participantTweetsList: action.participantTweetsList
            }
        case actionTypes.SAVE_NEW_TWEET_SUCCESS:
            return state;

            case actionTypes.GET_ALL_APPROVED_TWEETS_SUCCESS:
            return {
                ...state,
                tweetsApprovedList: action.tweetsLiveList
            }

        default:
            return state;
    }
}



