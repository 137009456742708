import React, { Component } from 'react';
import moment from 'moment';

class Tweet extends Component {
    render() {
        return (
            <div>
                <div className="single-tweet bg-white box-shadow mt-2" id={this.props.tweet.id} >
                    <div className="head-single-tweet pl-3 pr-5" style={{ backgroundColor: this.props.event.primaryColor }}  >
                        <div className="row">
                            <div className="col-5">
                                {(this.props.tweet.participantDefinedName) ? (
                                    //if the participant does not define any name we will use the anonymous as the name
                                    <div>
                                        <p className="participantname-single-tweet float-left" style={{ color: this.props.event.textColor }}>
                                        <b>{this.props.tweet.participantDefinedName}</b></p>
                                    </div>
                                ) : (
                                    <div>
                                        {/* <p className="participantname-single-tweet float-left" style={{ color: this.props.textColor }}><b>Anonymous</b></p> */}
                                    </div>
                                    )}
                            </div>
                            <div className="col-7 text-right">
                                <div className="col-12">
                                    {(this.props.tweet.dtmCreated) ? (
                                        <p className="hours-single-tweet " style={{ color: this.props.event.textColor }}><b>{moment(this.props.tweet.dtmCreated.toDate()).format("MMM.DD - HH:mm")}</b></p>
                                    ) : (<div>
                                        <p className="hours-single-tweet " style={{ color: this.props.event.textColor }}>"00:00"</p>
                                    </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="tweetTextBox pl-3 pr-5" style={{ color:  this.props.event.primaryColor  }}>
                            {this.props.tweet.sText}
                        </div>
                </div>
            </div>
        );
    }
}

export default Tweet;