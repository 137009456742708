import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

/*********** CUSTOM LIBRARIES ************/
import {store} from './Redux/Store';

/********** COMPONENTS CLASSES ***********/
import './index.css';
import './App.css';
import registerServiceWorker from './registerServiceWorker';
import App from './App';

if(localStorage.getItem("participant")==null){
    
}
 ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,   
  document.getElementById('root')
);
registerServiceWorker();
// initializeFirebasePushNotification();








// import React from 'react';
// import ReactDOM from 'react-dom';
// import {Provider} from 'react-redux';
// import {BrowserRouter, Switch, Route} from 'react-router-dom';

// /*********** CUSTOM LIBRARIES ************/
// import {store} from './Redux/Store';

// /********** COMPONENTS CLASSES ***********/
// import App404 from './App404';
// import './index.css';
// import registerServiceWorker from './registerServiceWorker';
// import PollScreen from './Screen/PollScreen';
// import LoginScreen from './Screen/LogInScreen';
// import QuestionScreen from './Screen/QuestionScreen';

// if(localStorage.getItem("sToken")==null){
    
// }
// ReactDOM.render(
//     <Provider store={store}>
//         <BrowserRouter>
//             <Switch>  
//                 <Route exact path="/" component={LoginScreen} /> 
//                 <Route exact path="/event/:idEvent/polls/:screenId" component={PollScreen} />
//                 <Route exact path="/event/:idEvent/questions/:screenId" component={QuestionScreen} />
//                 <Route component={App404} />
//             </Switch>
//         </BrowserRouter>
//     </Provider>, 
//     document.getElementById('app')
// );
// registerServiceWorker();