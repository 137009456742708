import React from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';
import Utility from '../Helpers/Utility';

class Redirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            splashPage:false,
            event: ''
        };

        //this.startTimerToGo = this.startTimerToGo.bind(this);


    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        this.props.onGetEventData(this.props.match.params.eventCode, cb_event => {

            if (!this.props.match.params.idEvent || (cb_event.id === this.props.match.params.idEvent)) {
                    this.setState({
                        ...this.state,
                        event: cb_event
                    });

                    let participant = JSON.parse(localStorage.getItem('participant-' + cb_event.id));
            if (participant && participant.length !== 0 ) {
                // we check if the user is logging to the same event thet he has been previously logged in or is a new event 
                        if (participant.eventId === cb_event.id) {
                            
                            this.props.onUpdateParticipantData(participant, cb_event.id, 'newLogin', Utility.getEventLanguage());
                            this.redirect(cb_event);
                }else{
                            
                            this.props.onLogin(cb_event.id, Utility.getEventLanguage(), result => {
                        if (result === "success") {
                                    this.redirect(cb_event);
                        }
                    });
                }
            } else {
                        this.props.onLogin(cb_event.id, Utility.getEventLanguage(), result => {
                    if (result === "success") {
                                this.redirect(cb_event);
                    }
                });
                     }
                }
            else {
                    window.location.href = "/";
                }
        });
    }

    redirect(event) {
        //Check specific content requested.
        if (this.props.match.params.idElement) {
            //Room present in link.
            if (this.props.match.params.contentType) {
                //contentType present in link.
                //Check if the content is a poll or a question.
                

                if (this.props.match.params.contentType === "poll") {
                    this.props.history.push("/event/" + event.id + "/dashboard/" + this.props.match.params.idElement + "/poll/");
                }
                else if (this.props.match.params.contentType === "question") {
                    this.props.history.push("/event/" + event.id + "/dashboard/" + this.props.match.params.idElement + "/question/");
                }
                //Check if the content is a survey, an agenda or a library.
                else if (this.props.match.params.contentType === "survey" ||
                    this.props.match.params.contentType === "agenda" ||
                    this.props.match.params.contentType === "library") {
                    //Check if the content is a specific item.
                    if (this.props.match.params.idContent) {
                        //Check operation requested for the content.
                        if (this.props.match.params.functionType === "show") {
                            //Check item requested.
                            if (this.props.match.params.idItem) {
                                this.props.history.push("/event/" + event.id + "/dashboard/" + this.props.match.params.idElement + "/" + this.props.match.params.contentType + "/" + this.props.match.params.idContent + "/" + this.props.match.params.functionType + "/" + this.props.match.params.idItem + "/internal");
                            }
                            else {
                                //No specific route found. Back to initial screen.
                                this.redirectToInitialPage(event);
                            }
                        }
                        else {
                            this.props.history.push("/event/" + event.id + "/dashboard/" + this.props.match.params.idElement + "/" + this.props.match.params.contentType + "/" + this.props.match.params.idContent +"/" + this.props.location.search);
                        }
                    }
                    else {

                        //No specific route found. Back to initial screen.
                        this.redirectToInitialPage(event);
                    }
                }
            }
            else {
                //No specific route found. Back to initial screen.
                this.redirectToInitialPage(event);
            }
        }
        else {
            //No specific route found. Back to initial screen.
            this.redirectToInitialPage(event);
        }
    }

    redirectToInitialPage(event) {
            this.props.history.push("/event/" + event.id + "/introduction");
        }

    startTimerToGo(){
        var properties = this.props;
        var eventId = this.state.event.id;
        setTimeout(function(){
            properties.history.push("/event/"+ eventId + "/introduction");
    }, 5000);
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetEventData: (eventCode, callback) => dispatch(generalActions.getEventData(eventCode, callback)),
        onLogin: (eventId,value, callback) => dispatch(generalActions.login(eventId,value, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onSetSplashPage: (splashImageUrl, status) => dispatch(generalActions.setSplashPage(splashImageUrl,status)),
        onGetEventDataById: (eventId, callback) => dispatch(generalActions.getEventDataById(eventId, callback)),
        onGetParticipantsNumber: (eventId, callback) => dispatch(generalActions.getParticipantsNumber(eventId, callback)),
    };
}

export default connect(null, mapDispatchToProps)(Redirect);