import { actionTypes } from '../Actions/actionTypes';
// let participant = JSON.parse(localStorage.getItem('participant'));
// const initialState = participant ? { loggedIn: true, participant, settingStatus:false, changedEventInfo:[], eventType:'initialized'} : {};
const initialState = { settingStatus: false, changedEventInfo: [], eventType: 'initialized', serverHeartBeat: null };

export function generalReducers(state = initialState, action) {

    switch (action.type) {
        case actionTypes.GET_EVENT_DATA:
            var eventTypeValue;
            if (action.eventData.currentLiveSession !== "default") {
                eventTypeValue = 'questions';
            } else if (action.eventData.currentTweetElement !== "default") {
                eventTypeValue = 'tweets';
            } else if (action.eventData.currentTweetElement === "default" && action.eventData.currentLiveSession.status !== "live") {
                eventTypeValue = 'dashboard';
            } else {
                eventTypeValue = 'default';
            }
            return {
                ...state,
                eventData: action.eventData,
                eventType: eventTypeValue
            }

        case actionTypes.Fetch_EVENT_INFO_CHANGE_SUCCESS:
            // var eventTypeValue;
            // if (action.eventData.currentLiveSession.status === "live") {
            //     eventTypeValue = 'questions';
            // } else if (action.eventData.currentTweetElement !== "default") {
            //     eventTypeValue = 'tweets';
            // } else if (action.eventData.currentTweetElement === "default" && action.eventData.currentLiveSession.status !== "live") {
            //     eventTypeValue = 'dashboard';
            // } else {
            //     eventTypeValue = 'default';
            // }
          
            return {
                ...state,
                changedEventInfo: action.eventData,
                // eventType: eventTypeValue
            }

        case actionTypes.PARTICIPANT_PREFERENCES_UPDATED_SUCCESS:
            return {
                ...state,
                participantData: action.participantData
            }

        case actionTypes.LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                participant: action.participant
            };

        case actionTypes.SETTING_STATUS_UPDATED_SUCCESS:
            return {
                ...state,
                settingStatus: action.status
            }

        case actionTypes.SEND_VOTE_SUCCESS:
            return {
                ...state,
                votesList: action.votesList
            }

        case actionTypes.SEND_ANSWER_TEXT_SUCCESS:
            return state;

        case actionTypes.SEND_RATING_ANSWER_SUCCESS:
            return state;

        case actionTypes.SET_SPLASH_PAGE_SUCCESS:
            return {
                ...state,
                splashPageData: action.splashImageData
            }

        case actionTypes.UPDATE_USER_NOTIFICATION_TOKEN_SUCCESS:
            return state;

        case actionTypes.FETCH_ALL_EVENT_MESSAGES_SUCCESS:
            return {
                ...state,
                eventMessageList: action.eventMessageList
            }

        case actionTypes.GET_ROOT_CHILDREN_SUCCESS:
            return {
                ...state,
                rootChildrenList: action.rootChildrenList
            }
        
        case actionTypes.GET_ALL_AGENDA_FILES_SUCCESS:
            return {
                ...state,
                agendaFilesList: action.agendaFilesList
            }

        case actionTypes.FETCH_ALL_EVENT_MESSAGES_ADDED_SUCCESS:
            return {
                ...state,
                eventMessagesAdded: action.eventMessagesAdded
            }

        case actionTypes.GET_EVENT_ROOMS_SUCCESS:
            return {
                ...state,
                eventRoomsList: action.eventRoomsList
            }

        case actionTypes.GET_PARENT_ELEMENT_INFO__SUCCESS:
            return {
                ...state,
                parentInfo: action.parentInfo
            }

        case actionTypes.GET_PARENT_ELEMENT_INFO_ON_CHANGE_SUCCESS:
            return {
                ...state,
                parentInfoOnChange: action.parentInfoOnChange
            }

        case actionTypes.GET_ALL_LIBRARY_FILES_SUCCESS:
            return {
                ...state,
                libraryFilesList: action.libraryFilesList
            }
        
        case actionTypes.GET_ALL_SURVEY_FILES_SUCCESS:
            return {
                ...state,
                surveyFilesList: action.surveyFilesList
            }
        
        case actionTypes.GET_LIBRARY_INFO_SUCCESS:
            return {
                ...state,
                libraryData: action.libraryData
            }

        case actionTypes.GET_SURVEY_INFO_ON_CHANGE_SUCCESS:
            return {
                ...state,
                surveyInfo: action.surveyInfo
            }
        
        case actionTypes.GET_LIBRARY_FILE_SUCCESS:
        return {
            ...state,
            libraryFile: action.libraryFile
        }

        case actionTypes.GET_PARTICIPANTS_NUMBERS_SUCCESS:
        return state;

        case actionTypes.UPDATE_SERVERHEARTBEAT_SUCCESS:
            return {
                ...state,
                serverHeartBeat: action.serverHeartBeat
            }

        default:
            return state;
    }
}



