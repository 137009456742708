import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
class HeaderTab extends Component {
    constructor(props) {
        super(props);
        // this.idEvent = this.props.match.params.idevent;
        this.state = {
            eventTitle: '',
            eventCode: ''
        };
    }

    componentDidMount() {

    }

    render() {

        var selectedLanguageStrings;
        var selectedLanguageCustomStrings;
		let userStoredLanguage = JSON.parse(localStorage.getItem('4meet-Language')); 
        if(userStoredLanguage.selectedLanguage === 'English'){
            selectedLanguageStrings = englishStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_en;
            }
        }else{
            selectedLanguageStrings = italianStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_it;
        }
        }

        return (
            <nav className="nav-bar-tab" style={{ backgroundColor: this.props.changedEventInfo.headerBackgroundColor }}>
                <div className="container">
                    <div className="form-row ">
                        <div className="col-6 text-center  " >
                            {(this.props.parentInfo !== "default" && this.props.parentInfo.currentTweetElement === "default") ? (
                                <div className="col-12">
                                    <div className='disabled' id="navBarTabsDisabled" style={{ color: this.props.changedEventInfo.headerTextColor }}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.questions : selectedLanguageStrings.tweets}
                                    </div>
                                </div>
                            ) : (
                                    <div className="col-12" id="navTab" onClick={() => { this.props.handleTabChangeEvent("tweets") }}>
                                        <div className='' id="navBarTabs" style={{ color: this.props.changedEventInfo.headerTextColor }} >
                                            {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.questions : selectedLanguageStrings.tweets}
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="col-6 text-center" >
                            {(this.props.parentInfo !== "default" && this.props.parentInfo.currentLiveSession.status !== 'live') ? (
                                <div className="col-12">
                                    <div className='disabled' id="navBarTabsDisabled" style={{ color: this.props.changedEventInfo.headerTextColor, opacity: 0.5 }} >
                                        {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.polls : selectedLanguageStrings.questions}
                                    </div>
                                </div>
                            ) : (
                                    <div className="col-12" id="navTab" onClick={() => { this.props.handleTabChangeEvent("questions") }}>
                                        <div className='' id="navBarTabs" style={{ color: this.props.changedEventInfo.headerTextColor }}>
                                            {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.polls : selectedLanguageStrings.questions}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-12">
                            <div className={this.props.borderType} style={{ backgroundColor: this.props.changedEventInfo.headerTextColor}}></div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

// export default HeaderTab;
const mapStateToProps = state => {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    };
};


export default connect(mapStateToProps, null)(HeaderTab);