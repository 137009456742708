import React, {Component} from 'react';
import {questionActions} from '../Redux/Actions/questionsActions';
import {connect} from 'react-redux';
import {generalActions} from '../Redux/Actions/generalActions';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import StarRatingComponent from 'react-star-rating-component';


class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // elementId : this.props.eventInfo.currentQuestionElement,
            selectedOption: '',
            sessionId: '',
            questionId: '',
            elementId: '',
            cardTitle: '',
            optionsList: [],
            status: '',
            eventType: 'tweets',
            voted: '',
            answeredText: '',
            ratedQuestion: '',
            selectedOptionsList: [],
            letMultiplechoiceSelection: '',
            questionType: '',
            primaryColor: '',
            textColor: '',
            sendVoteStatus: 'disabled',
            sendAnswerStatus: 'disabled',
            participantInfo: [],
            event: '',
            rating: 0,
            likeSelected: undefined
        };
        // this.eventId = this.props.match.params.idEvent;
        // this.eventId = this.props.event.id;
        this.eventData = this.props.eventData;
        this.sendVote = this.sendVote.bind(this);
        this.sendAnswer = this.sendAnswer.bind(this);
        this.sendVoteAndComment = this.sendVoteAndComment.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onSelectedOption = this.onSelectedOption.bind(this);
        this.onFillingComment = this.onFillingComment.bind(this);
        this.sendRatingAnswer = this.sendRatingAnswer.bind(this);
    }

    componentDidMount() {

        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.event.id));
        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
        this.setState({
            ...this.state,
            primaryColor: this.props.event.primaryColor,
            textColor: this.props.event.textColor,
            participantInfo: participant,
            event: this.props.event,
        });
        if (this.props.parentInfo.currentLiveSession.liveQuestionId && this.props.parentInfo.currentLiveSession.status === "live") {
            if (storedVotes && storedVotes.includes(this.props.parentInfo.currentLiveSession.id)) {
                this.setState({
                    voted: 'voted' + this.props.parentInfo.currentLiveSession.id,
                    questionType: 'MultipleChoice'
                });
            } else if (storedAnswersText && storedAnswersText.includes(this.props.parentInfo.currentLiveSession.id)) {
                this.setState({
                    voted: 'voted' + this.props.parentInfo.currentLiveSession.id,
                    answeredText: 'answeredText',
                    questionType: 'SimpleTextQuestion'
                });
            } else {

                this.setState({
                    elementId: this.props.parentInfo.currentLiveSession.liveQuestionElement,
                    questionId: this.props.parentInfo.currentLiveSession.liveQuestionId,
                    sessionId: this.props.parentInfo.currentLiveSession.id,
                    //cardTitle: callBackQuestionData.text,
                    //letMultiplechoiceSelection: callBackQuestionData.letMultipleSelect,
                    //optionsList: callbackOptions,
                    status: '',
                    voted: '',
                    //questionType: callBackQuestionData.type,
                    sendVoteStatus: "disabled"
                });

            }
        } else {

            //As the participant stopped the voting session we need to remove the question element from the participant side
            //this.setState({ status: 'NoLiveSession' });
            // this.props.history.push("/event/" + this.state.eventId + "/tweets/0");
        }
        //	})
        /*************** BACK HISTORY BUTTON ****************/
        window.onpopstate = () => {
        }

    }


    sendVote() {
        if (this.state.sendVoteStatus !== "disabled") {
            this.props.sendResponseFunction('MultipleChoice', this.state.selectedOptionsList, this.state.sessionId);
            this.setState({
                voted: 'voted' + this.props.parentInfo.currentLiveSession.id,
                selectedOptionsList: [],
                sendVoteStatus: "disabled"
            });
        }
    }

    sendAnswer() {
        // if(this.state.sendVoteStatus !== "disabled"){
        let participantReply = document.getElementById("answerTextArea").value;
        let participantName;
        if (this.state.participantInfo && this.state.participantInfo.sName && this.state.participantInfo.sName !== "") {
            participantName = this.state.participantInfo.sName;
        } else {
            participantName = "";
        }
        let value = {participantReply: participantReply, participantName: participantName};
        this.props.sendResponseFunction('SimpleText', value, this.state.sessionId);
        this.setState({
            voted: 'voted' + this.props.parentInfo.currentLiveSession.id,
            answeredText: 'answeredText', 
            sendAnswerStatus: "disabled"});
        // }
    }

    sendVoteAndComment() {
        if (this.state.sendVoteStatus !== "disabled") {
            var comment = document.getElementById('commentTextArea').value;
            var value = {optionsList: this.state.selectedOptionsList, comment: comment};
            this.props.sendResponseFunction('SimpleTextAndMultipleChoice', value, this.state.sessionId);
            this.setState({
                voted: 'voted' + this.props.parentInfo.currentLiveSession.id,
                selectedOptionsList: [],
                sendVoteStatus: "disabled",
                likeSelected: undefined
            });
        }
    }

    sendRatingAnswer() {
        if (this.state.sendVoteStatus !== "disabled") {
            let participantName;
            if (this.state.participantInfo && this.state.participantInfo.sName && this.state.participantInfo.sName !== "") {
                participantName = this.state.participantInfo.sName;
            } else {
                participantName = "";
            }
            let value = {participantRating: this.state.rating, participantName: participantName};
            this.props.sendResponseFunction('RatingQuestion', value, this.state.sessionId);
            this.setState({
                voted: 'voted' + this.props.parentInfo.currentLiveSession.id,
                rating: 0,
                sendVoteStatus: "disabled"
            });
        }
    }


    onSelectedOption(option, like = false) {
        var options = this.state.selectedOptionsList;
        var exist = false;
        var existIndex;

        if (this.props.questionData.letMultipleSelect === true) {
            for (var i = 0; i < options.length; i++) {
                if (options[i] === option.id) {
                    exist = true;
                    existIndex = i;
                }
            }

            if (exist === false) {
                if (this.props.questionData.multipleChoiceLimitAnswer > 0) {
                    if (options.length < this.props.questionData.multipleChoiceLimitAnswer) {
                        options.push(option.id);
                    }
                } else {
                    options.push(option.id);
                }
            } else {
                options.splice(existIndex, 1);
            }
        } else {
            //If it is single choice we only store the last option of the participant if he has change his selection
            // so the options list will only contain the last selected choice of the participant
            options = [];
            options.push(option.id);
        }
        if (this.props.questionData.type === "SimpleTextAndMultipleChoice" && this.props.questionData.simpleTextAnswerMandatory === true) {
            var commentStatus = document.getElementById('commentTextArea').value;
            if (options.length !== 0) {
                if (commentStatus !== "") {
                    this.setState({...this.state, selectedOptionsList: options, sendVoteStatus: ""});
                } else {
                    this.setState({...this.state, selectedOptionsList: options, sendVoteStatus: "disabled"});
                }
            } else {
                this.setState({...this.state, selectedOptionsList: options, sendVoteStatus: "disabled"});
            }
        } else {
            if (options.length !== 0) {
                this.setState({...this.state, selectedOptionsList: options, sendVoteStatus: ""});
            } else {
                this.setState({...this.state, selectedOptionsList: options, sendVoteStatus: "disabled"});
            }
        }

        if (this.props.questionData.type === "SimpleTextAndMultipleChoice" && this.props.questionData.layout === "like-dislike") {
            if (like) {
                this.setState({likeSelected: true});
            } else {
                this.setState({likeSelected: false});
            }
        }
    }

    onFillingComment() {
        var commentStatus = document.getElementById('commentTextArea').value;
        if (commentStatus !== "") {
            if (this.state.selectedOptionsList.length !== 0) {
                this.setState({...this.state, sendVoteStatus: ""});
            } else {
                this.setState({...this.state, sendVoteStatus: "disabled"});
            }
        } else {
            this.setState({...this.state, sendVoteStatus: "disabled"});
        }
    }

    handleTabChangeEvent(eventStatus) {
        this.setState({...this.state, eventType: 'tweets'}, () => {
            this.props.history.push("/event/" + this.props.match.params.idEvent + "/" + eventStatus + "/0");
        });
    }

    handleEventChange = (e) => {
        switch (e.target.name) {

            case "answerTextArea":
                if (e.target.value !== " ") {
                    this.setState({...this.state, sendAnswerStatus: ""});
                }
                break;

            default:
                return true;

        }
    }


    onSetSidebarOpen(open) {
        // this.setState({ sidebarOpen: open });
        this.props.onSettingStatus(open, callback => {
            if (callback === 'success') {
                this.setState({sidebarOpen: open});
            }
        });
    }

    onToggle() {
        let participantData = JSON.parse(localStorage.getItem('participant-' + this.props.event.id));
        if (this.state.toggleActive === true) {
            this.setState({toggleActive: false, textToggle: 'OFF'});
            Object.assign(participantData, {'sTweetsApproved': false});
            localStorage.setItem("participant", JSON.stringify(participantData));
        } else {
            this.setState({toggleActive: true, textToggle: 'ON'});
            Object.assign(participantData, {'sTweetsApproved': true});
            localStorage.setItem("participant", JSON.stringify(participantData));
        }
    }


    onStarClick(nextValue, prevValue, name) {

        this.setState({...this.state, rating: nextValue, sendVoteStatus: " "});
    }


    render() {

        const {rating} = this.state;

        var selectedLanguageStrings;
        var selectedLanguageCustomStrings;
        if (this.props.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_en;
            }
        } else {
            selectedLanguageStrings = italianStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_it;
            }
        }

        let optionsListComponents = [];
        if (this.props.questionOptionsList) {
            optionsListComponents = (this.props.questionOptionsList || []).map((option, i) => {
                var backgroundOption = this.props.event.textColor;
                var textColorOption = this.props.event.primaryColor;
                var voteBarColor;
                var optionOrderType;
                if (this.props.questionData.optionDecorationValue === "Alphabet") {
                    optionOrderType = "a"
                } else if (this.props.questionData.optionDecorationValue === "Number") {
                    optionOrderType = "1"
                } else if (this.props.questionData.optionDecorationValue === "Bullet") {
                    optionOrderType = "disc"
                }

                if (this.state.selectedOptionsList.length > 0) {
                    for (var i = 0; i < this.state.selectedOptionsList.length; i++) {
                        if (option.id === this.state.selectedOptionsList[i]) {
                            backgroundOption = this.props.event.primaryColor;
                            textColorOption = this.props.event.textColor;
                        }
                    }
                }
                if (this.props.questionData.type === "SimpleTextAndMultipleChoice" && this.props.questionData.layout === "like-dislike") {
                    let dislikeColor;
                    let likeColor;
                    let likeBackgroundCOlor;
                    let dislikeBackgroundColor;
                    if (this.state.likeSelected === undefined) {
                        likeColor = '#28A745';
                        dislikeColor = '#DC3545';
                        likeBackgroundCOlor = 'white';
                        dislikeBackgroundColor = 'white';
                    } else if (this.state.likeSelected === true) {
                        likeColor = this.props.event.textColor;
                        dislikeColor = '#DC3545';
                        likeBackgroundCOlor = this.props.event.primaryColor;
                        dislikeBackgroundColor = 'white';
                    } else {
                        dislikeColor = this.props.event.textColor;
                        likeColor = '#28A745';
                        likeBackgroundCOlor = 'white';
                        dislikeBackgroundColor = this.props.event.primaryColor;
                    }

                    return (
                        <div className="col-12 p-0" key={option.id}>
                            {(option.type === "like") ? (
                                <div className="col-12 p-0"
                                     onClick={() => {
                                         this.onSelectedOption(option, true)
                                     }}>
                                    <div className="btn col-12 p-4"
                                         style={{backgroundColor: likeBackgroundCOlor}}>
                                        <div className="row justify-content-start">
                                            <i className="fas fa-thumbs-up optionLikeIcon pr-2"
                                               style={{color: likeColor}}></i>
                                            <label
                                                className="fontTextArea"
                                                style={{color: likeColor}}>{option.text}</label>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-12 p-0" key={option.id}
                                     onClick={() => {
                                         this.onSelectedOption(option, false)
                                     }}>
                                    <div className="btn col-12 p-4"
                                         style={{backgroundColor: dislikeBackgroundColor}}>
                                        <div className="row justify-content-start align-items-center">
                                            <i className="fas fa-thumbs-down optionLikeIcon pr-2"
                                               style={{color: dislikeColor}}></i>
                                            {/* <label className="fontTextArea text-danger" >{option.text}</label> */}
                                            <div
                                                className="fontTextArea"
                                                style={{color: dislikeColor}}>
                                                {(this.props.questionData.optionDecorationValue === "None") ? (
                                                    <div>{option.text}</div>
                                                ) : (
                                                    <li type={optionOrderType} id={option.id}>{option.text}</li>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                } else if (this.props.questionData.type === "MultipleChoice") {
                    return (
                        <div key={option.id}>
                            <div className="container-option" style={{backgroundColor: backgroundOption}}
                                 onClick={() => {
                                     this.onSelectedOption(option)
                                 }}>
                                {/* <div style={{ color: textColorOption }}>{optionDecoration}</div> */}
                                <div style={{color: textColorOption}}>
                                    {(this.props.questionData.optionDecorationValue === "None") ? (
                                        <div>{option.text}</div>
                                    ) : (
                                        <li type={optionOrderType} id={option.id}>{option.text}</li>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        //If the question option lets the participant to select multiple choice it will show the multiple choice to select othersise it will be
                        //radio button to let select only one
                        <div key={option.id}>
                            <div className="container-option" style={{backgroundColor: backgroundOption}}
                                 onClick={() => {
                                     this.onSelectedOption(option)
                                 }}>
                                {/* <div style={{ color: textColorOption }}>{optionDecoration}</div> */}
                                <div style={{color: textColorOption}}>
                                    {(this.props.questionData.optionDecorationValue === "None") ? (
                                        <div>{option.text}</div>
                                    ) : (
                                        <li type={optionOrderType} id={option.id}>{option.text}</li>
                                    )}
                                </div>
                            </div>
                        </div>

                    )
                }
            });
        }

        var questionComponent;
        var orderedOptionsListcomponent;
        if (this.props.questionData.optionDecorationValue === "Alphabet" || this.props.questionData.optionDecorationValue === "Number") {
            orderedOptionsListcomponent = <ol className="questionOptionsList">
                {optionsListComponents}
            </ol>
        } else if (this.props.questionData.optionDecorationValue === "Bullet") {
            orderedOptionsListcomponent = <ul className="questionOptionsList">
                {optionsListComponents}
            </ul>
        } else {
            orderedOptionsListcomponent = <div className="w-100">
                {optionsListComponents}
            </div>
        }

        if (this.props.questionData.type === "MultipleChoice") {
            questionComponent = (<div>
                    {(this.state.voted === 'voted' + this.props.parentInfo.currentLiveSession.id) ?
                        (
                            <div className="card questionCard" id="card-box">
                                <div className="card-header" style={{backgroundColor: this.props.event.primaryColor}}>
                                    <h4 className="questionHeader"
                                        style={{color: this.props.event.textColor}}>{selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}</h4>
                                </div>
                                <div className="card-body black-text" id="cardOptionContent">
                                    <div className="row">
                                        <div className="col-12 text-center"
                                             style={{color: this.props.event.primaryColor}}>
                                            <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {(optionsListComponents.length === 0) ? (
                                    <div className="row">
                                        <div className="col-12 text-center spinOption">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card questionCard" id="card-box">
                                        <div className="card-header"
                                             style={{backgroundColor: this.props.event.primaryColor}}>
                                            <h4 className="questionHeader" style={{color: this.props.event.textColor}}>
                                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}</h4>
                                        </div>
                                        <div className="card-body-question black-text">
                                            {/* <p className="card-title questionText">{this.props.questionData.text}</p> */}
                                            <h4 className="card-title questionText"
                                                style={{color: this.props.event.primaryColor}}>
                                                {this.props.questionData.text}
                                            </h4>
                                            <div className="card-text" id="cardOptionContent">
                                                {/* {optionsListComponents} */}
                                                {orderedOptionsListcomponent}
                                            </div>

                                            <div className="card-footer bg-white text-center ">
                                                <button className={"btn fontTextArea " + this.state.sendVoteStatus}
                                                        style={{
                                                            backgroundColor: this.props.event.primaryColor,
                                                            color: this.props.event.textColor
                                                        }} onClick={this.sendVote}>
                                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                                </button>
                                            </div>
                                            <div className="col-10">
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            )
        } else if (this.props.questionData.type === "SimpleTextAndMultipleChoice") {
            questionComponent = (<div>
                    {(this.state.voted === 'voted' + this.props.parentInfo.currentLiveSession.id) ? (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{backgroundColor: this.props.event.primaryColor}}>
                                <h4 className="questionHeader"
                                    style={{color: this.props.event.textColor}}>{selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}</h4>
                            </div>
                            <div className="card-body black-text" id="cardOptionContent">
                                <div className="row">
                                    <div className="col-12 text-center"
                                         style={{color: this.props.event.primaryColor}}>
                                        <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {(optionsListComponents.length === 0) ? (
                                <div className="row">
                                    <div className="col-12 text-center spinOption">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="card questionCard" id="card-box">
                                    <div className="card-header"
                                         style={{backgroundColor: this.props.event.primaryColor}}>
                                        <h4 className="questionHeader"
                                            style={{color: this.props.event.headerTextColor}}>
                                            {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}</h4>
                                    </div>
                                    <div className="card-body-question black-text">
                                        {/* <p className="card-title questionText">{this.props.questionData.text}</p> */}
                                        <h4 className="card-title questionText"
                                            style={{color: this.props.event.primaryColor}}>
                                            {this.props.questionData.text}
                                        </h4>
                                        {(this.props.questionData.layout == "like-dislike") ? (
                                            <div>
                                                <div className="row m-2 ml-3">
                                                    {/* {optionsListComponents} */}
                                                    {orderedOptionsListcomponent}
                                                </div>
                                                <div className="form-group commentTextAreaField">
                                                    <textarea className="form-control fontTextArea "
                                                              id="commentTextArea" rows="3"
                                                              onChange={this.onFillingComment}></textarea>
                                                </div>

                                            </div>
                                        ) : (
                                            <div>
                                                <p className="card-text" id="cardOptionContent">
                                                    {/* {optionsListComponents} */}
                                                    {orderedOptionsListcomponent}
                                                </p>
                                                <div className="form-group commentTextAreaField">
                                                    {/* <label htmlFor="commentTextArea">{selectedLanguageStrings.reason_comment}</label> */}
                                                    <textarea className="form-control fontTextArea "
                                                              id="commentTextArea" rows="3"
                                                              onChange={this.onFillingComment}></textarea>
                                                </div>
                                            </div>
                                        )}

                                        <div className="card-footer bg-white text-center ">
                                            <button className={"btn fontTextArea " + this.state.sendVoteStatus} style={{
                                                backgroundColor: this.props.event.textColor,
                                                color: this.props.event.primaryColor
                                            }} onClick={this.sendVoteAndComment}>
                                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                            </button>
                                        </div>
                                        <div className="col-10">
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        } else if (this.props.questionData.length !== 0 && this.props.questionData.type === "SimpleTextQuestion") {
            questionComponent = (
                <div>
                    {(this.state.voted === 'voted' + this.props.parentInfo.currentLiveSession.id) ? (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{backgroundColor: this.props.event.textColor}}>
                                <h4 className="questionHeader" style={{color: this.props.event.primaryColor}}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body black-text" id="cardOptionContent">
                                <div className="row">
                                    <div className="col-12 text-center" style={{color: this.props.event.primaryColor}}>
                                        <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card" id="card-box">
                            <div className="card-header" style={{backgroundColor: this.props.event.textColor}}>
                                <h4 className="questionHeader" style={{color: this.props.event.primaryColor}}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body-question black-text">
                                <form>
                                    {/* <h5><p className="card-title questionText">{this.props.questionData.text}</p></h5> */}
                                    <div className="card-title questionText fontTextArea"
                                         style={{color: this.props.event.primaryColor}}>
                                        {this.props.questionData.text}
                                    </div>
                                    <div className="form-group ml-2 mr-2">
                                        <label htmlFor="answerTextArea" style={{color: this.props.event.primaryColor}}>
                                            <h4>{selectedLanguageStrings.your_answer} : </h4></label>
                                        <textarea className="form-control fontTextArea" id="answerTextArea" rows="2"
                                                  name="answerTextArea" onChange={this.handleEventChange}></textarea>
                                    </div>
                                </form>
                            </div>
                            <div className="card-footer bg-white text-center fontTextArea">
                                <button className={"btn fontTextArea " + this.state.sendAnswerStatus} style={{
                                    backgroundColor: this.props.event.textColor,
                                    color: this.props.event.primaryColor
                                }} onClick={this.sendAnswer}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else if (this.props.questionData.length !== 0 && this.props.questionData.type === "RatingQuestion") {
            questionComponent = (
                <div>
                    {(this.state.voted === 'voted' + this.props.parentInfo.currentLiveSession.id) ? (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{backgroundColor: this.props.event.textColor}}>
                                <h4 className="questionHeader" style={{color: this.props.event.primaryColor}}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body black-text" id="cardOptionContent">
                                <div className="row">
                                    <div className="col-12 text-center" style={{color: this.props.event.primaryColor}}>
                                        <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card" id="card-box">
                            <div className="card-header" style={{backgroundColor: this.props.event.textColor}}>
                                <h4 className="questionHeader" style={{color: this.props.event.primaryColor}}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body-question black-text">
                                <div className="card-title questionText fontTextArea"
                                     style={{color: this.props.event.primaryColor}}>
                                    {this.props.questionData.text}
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        {(this.props.questionData.numberOfStars < 6) ? (
                                            <StarRatingComponent
                                                name="rate1"
                                                starCount={parseInt(this.props.questionData.numberOfStars)}
                                                value={rating}
                                                renderStarIcon={() => <span><i
                                                    className="fas fa-star starIconRating"></i></span>}
                                                onStarClick={this.onStarClick.bind(this)}
                                                emptyStarColor={"grey"}
                                                // starColor={this.props.event.primaryColor }
                                            />
                                        ) : (
                                            <StarRatingComponent
                                                name="rate1"
                                                starCount={parseInt(this.props.questionData.numberOfStars)}
                                                value={rating}
                                                renderStarIcon={() => <span><i
                                                    className="fas fa-star starIconRating2"></i></span>}
                                                onStarClick={this.onStarClick.bind(this)}
                                                emptyStarColor={"grey"}
                                                // starColor={this.props.event.primaryColor }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-center fontTextArea">
                                <button className={"btn fontTextArea " + this.state.sendVoteStatus} style={{
                                    backgroundColor: this.props.event.primaryColor,
                                    color: this.props.event.textColor
                                }} onClick={this.sendRatingAnswer}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )
        }


        return (
            <div>
                {(this.state.status !== 'NoLiveSession') ? (
                    <div className="row">
                        <div className="col-12" id="optionsCard">
                            {questionComponent}
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-12" id="optionsCard">
                            <div className="card" id="card-box">
                                <div className="card-header" style={{backgroundColor: this.props.event.primaryColor}}>
                                    {/* <h4 className="questionHeader" style={{ color: this.state.textColor }}>Live Question</h4> */}
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <div className="card-body-question black-text">
                                            <h5 className="mt-2 mb-4">No Live Question Available</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

//export default PollScreen;

const mapStateToProps = state => {

    return {
        eventInfo: state.generalReducers.eventInfo,
        eventData: state.generalReducers.eventData,
        questionOptions: state.questionReducer.questionOptions,
        questionData: state.questionReducer.questionData,
        changedEventInfo: state.generalReducers.changedEventInfo
    };

};

const mapDispatchToProps = dispatch => {
    return {
        ongetLiveSessionOptions: (eventId, elementId, questionId, liveSessionId, callback) => dispatch(questionActions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback)),
        onGetQuestion: (eventId, elementId, questionId, callback) => dispatch(questionActions.getQuestion(eventId, elementId, questionId, callback)),
        onSettingStatus: (status, callback) => dispatch(generalActions.settingStatus(status, callback)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Question);