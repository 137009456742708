import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App404 from './App404';
import './index.css';
import LoginScreen from './Screen/LogInScreen';
import Redirect from './Screen/Redirect';
import Dashboard from './Screen/Dashboard';
import QuestionScreen from './Screen/QuestionScreen';
import PollScreen from './Screen/PollScreen';
import LibraryScreen from './Screen/LibraryScreen';
import IntroductionScreen from './Screen/IntroductionScreen';
import RoomsPage from './Screen/RoomsScreen';
import AgendaScreenRoot from './Screen/AgendaScreenRoot';
import SurveScreenRoot from './Screen/SurveyScreenRoot';
import SurveyThanksPage from './Screen/SurveyThanksPage';
import RedirectLibraryFile from './Screen/RedirectLibraryFile';
import QrCodeScanner from './Screen/QrCodeScanner';
import FileViewer from './Screen/FileViewer';
import { version } from '../package.json';

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            // <div>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={LoginScreen} />
                    <Route exact path="/index.html" component={LoginScreen} />
                    <Route exact path="/ec/:eventCode" component={Redirect} />
                    <Route exact path="/ec/:eventCode/event/:idEvent/dashboard/:idElement/:contentType" component={Redirect} />
                    <Route exact path="/ec/:eventCode/event/:idEvent/dashboard/:idElement/:contentType/:idContent" component={Redirect} />
                    <Route exact path="/ec/:eventCode/event/:idEvent/dashboard/:idElement/:contentType/:idContent/:functionType/:idItem" component={Redirect} />
                    <Route exact path="/event/:idEvent/introduction" component={IntroductionScreen} />
                    <Route exact path="/event/:idEvent/rooms" component={RoomsPage} />
                    <Route exact path="/event/:idEvent/dashboard/:idElement" component={Dashboard} />
                    <Route exact path="/event/:idEvent/dashboard/:idElement/question" component={QuestionScreen} />
                    <Route exact path="/event/:idEvent/dashboard/:idElement/poll" component={PollScreen} />
                    <Route exact path="/event/:idEvent/dashboard/:idElement/library/:idItem" component={LibraryScreen} />
                    <Route path="/event/:idEvent/dashboard/:idElement/library/:idLibrary/show/:idFile/:bInternal?" component={FileViewer} />
                    <Route exact path="/event/:idEvent/dashboard/:idElement/qrcode/:idItem" component={QrCodeScanner} />
                    <Route exact path="/event/:idEvent/dashboard/:idElement/agenda/:idItem" component={AgendaScreenRoot} />
                    <Route exact path="/event/:idEvent/dashboard/:idElement/survey/:idItem" component={SurveScreenRoot} />

                    {/*<Route exact path="/event/:idEvent/dashboard/:idElement/survey/:idItem/thanks" component={SurveyThanksPage} />*/}
                    {/* <Route exact path="/event/:idEvent/dashboard/:idRoom/:idLibrary/:idFile" component={RedirectLibraryFile}/> */}
                    {/* <Route path="/redirecturl/:reqURL" component={LoginScreen}/> */}

                    <Route component={App404} />
                </Switch>
            </BrowserRouter>
            // </div>

        );
    }
}

export default App;