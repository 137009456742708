export const englishStrings = {

/********************* LOGIN PAGE *************************/
event_code:"Event Code",
enter:"Enter",
event_code_error:" Please provide a valid event code.",
evet_code_request:"Event Code is required.",
tap_to_start:"Tap to start",
/********************* MENU *************************/
main_menu:"Main Menu",
home:"Home",
logout:"Logout",
event_messages:"Event Messages",
english:"English",
italian:"Italian",
select_language:"Select Language",
select_room:"Select Room",
choose_event_room:"Choose Event Room:",
lis_materials:"List of Materials",
tweets_questions_menu:"Questions/Polls",
questions_menu:"Questions",



/********************* DASHBORAD *************************/
tweets:"QUESTIONS",
questions:"POLLS",
important_message:"Important Message",
agenda:"Agenda",
survey:"Survey",

/********************* TWEETS *************************/
approved_tweets:"APPROVED TWEETS",
live_tweets:"LIVE TWEETS",
more_recent: "More Recent",
less_recent: "Less Recent",
your_questions: "YOUR QUESTIONS",
your_tweets_note: "You did not send any question yet.",
tweet_modal_title:"Write a new question",
your_question:"Your Question",
your_name:"Your Name",
optional:"optional",
send_anonymous:"Send as Anonymous",

cancel:"Cancel",
send:"Send",

/********************* QUESTIONS *************************/
live_question:"Live Question",
all_tweets:"All Tweets",
send_answer:"Send Answer",
your_answer:"Your Answer",
thanks_your_answer:"Thanks for your answer.",
reason_comment: "Why do you prefer this option?",

/********************* PROFILE *************************/
profile_modal_title:"Your profile",
your_email_address:"Your Email address",
profile_note:"We'll never share your email with anyone else.",

/******************** Library *************************/
no_materials:"No files uploaded yet.",


save:"Save",
event_message_title:"Title",
no_agenda_language:"No program is available in this language.",

open_document:"OPEN THE DOCUMENT",
goto_eventintro:"GO TO THE EVENT HOME PAGE",

/************* SURVEY **************/
live_survey: "Live survey",
please_answer_survey: "Please go to:"




}