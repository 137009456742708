import React, {Component} from 'react';
import iframe from 'iframe';
import {generalActions} from '../Redux/Actions/generalActions';
import {connect} from 'react-redux';
import Header from '../Component/Header';
import Utility from '../Helpers/Utility';
import moment from 'moment';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';

class SurveyScreenRoot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            surveyId: this.props.match.params.idItem,
            filesList: [],
            sidebarOpen: false,
            event: 'default',
            parentInfo: 'default',
            participantInfo: 'default',
            roomsList: [],
            surveyFilesList: [],
            currentSurveyFile: '',
            rootChildrenList: [],
            surveyAnswered: false,
            iframeheight: 0,
            startFullScreenMode: true,
            userStatus: 'initialized'
        };

        this.goToSurveyPageModal = this.goToSurveyPageModal.bind(this);        
        this.goToQuestionPage = this.goToQuestionPage.bind(this);

    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (!participant) {
            this.props.history.push("/");
        } else {
            
            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (callback, unubscribe) => {
                this.unsubscribeEventInfoChange = unubscribe;

                
                if (callback.data.eventStatus === "Archived") {
                    window.location.href = "/";
                }

        this.setState({
            ...this.state,
            participantInfo: participant,
                    event: callback.data
        });

                if (!callback.data.availableLanguages.lang_it || !callback.data.availableLanguages.lang_en) {
                    if (callback.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (callback.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
        }
                
        });

        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }
        this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (rootChildrenList, unsubscribe) => {
            this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
            let orederdRootChildrenList = [];
            let orderedRoomsChildrenList = [];
                //let allElementsList = [];
            for (var i = 0; i < rootChildrenList.length; i++) {
                if (rootChildrenList[i].sParentId === "default") {
                    orederdRootChildrenList.push(rootChildrenList[i]);
                } else if (rootChildrenList[i].sParentId === this.state.roomId) {
                    orderedRoomsChildrenList.push(rootChildrenList[i]);
                }
            }

            orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
                const allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);

                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {

                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }

                        //console.log("allitems onGetSurveyFiles cycle");
                        //console.log(this.state.eventId, item.id);

                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;

                            //console.log("surveyFilesList", surveyFilesList);

                            //console.log("allitems onGetSurveyFiles files", surveyFilesList);

                            for (const surveyfile of surveyFilesList) {
                                if (surveyfile.fileUrl) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                    item.fileUrl = surveyfile.fileUrl;
                                }
                            }

                            /*
                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    allElementsList[i].surveyLinkUrl = surveyfile.fileUrl;
                                    allElementsList[i].fileUrl = surveyfile.fileUrl;
                                }
                            });
                            */

                            this.setState({ ...this.state, rootChildrenList: allElementsList });

                            var surveyVotes = JSON.parse(localStorage.getItem("surveyVotes"));
                            if (surveyVotes && surveyVotes.includes(this.state.surveyId)) {
                                this.setState({
                                    ...this.state,
                                    surveyAnswered: true
                                });
                            } else {
                                if (item.sType === 'survey' && item.id == this.state.surveyId) {

                                    //console.log("element :", item);
                                    this.setState({
                                        ...this.state,
                                        surveyAnswered: false,
                                        currentSurveyFile: item,
                                    });
                                }
                            }
                        });
                    }
                });

                //console.log("allElementsList", allElementsList);

                /*
                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {

                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }

                        //console.log("allitems onGetSurveyFiles cycle");
                        //console.log(this.state.eventId, item.id);

                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;
    
                            //console.log("allitems onGetSurveyFiles files", surveyFilesList);
                            
                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                    item.fileUrl = surveyfile.fileUrl;
                                }
                            });
    
                        });
                    }
                });
                */





        });

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }
        this.props.onGetEventRoomsOnChange(this.state.eventId, (roomsList, unsubscribe) => {
            this.unsubscribeGetEventRoomsOnChange = unsubscribe;
            if (roomsList.length > 0) {
                this.setState({
                    ...this.state,
                    roomsList: roomsList
                });
            }
        });
            
            /*
        var surveyVotes = JSON.parse(localStorage.getItem("surveyVotes"));
        if (surveyVotes && surveyVotes.includes(this.state.surveyId)) {
            this.setState({
                ...this.state,
                surveyAnswered: true
            });
        } else {
            if (this.unsubscribeGetSurveyFiles) {
                this.unsubscribeGetSurveyFiles();
            }

                console.log("onGetSurveyFiles of current survey file");
                console.log(this.state.eventId, this.state.surveyId);

                this.props.onGetSurveyFiles(this.state.eventId, this.state.surveyId, (surveyFilesList2, unsubscribe) => {
                this.unsubscribeGetSurveyFiles = unsubscribe;
                    console.log("onGetSurveyFiles of current survey file callback", surveyFilesList2);

                    if (surveyFilesList2.length > 0) {
                        for (var i = 0; i < surveyFilesList2.length; i++) {
                            if (surveyFilesList2[i] && surveyFilesList2[i].fileUrl) {

                            this.setState({
                                ...this.state,
                                surveyAnswered: false,
                                    surveyFilesList2,
                                    currentSurveyFile: surveyFilesList2[i]
                            });
                            return;
                        }

                    }

                }
            })
        }
            */
            
            
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.roomId, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            this.setState({
                ...this.state,
                parentInfo: parentInfo.data
            });
            var userAlreadyVoted = false;
            if (parentInfo.message === 'success') {
                if (parentInfo.data.currentLiveSession.status === "live") {

                    //we only go to the tweet question if the user did not already voted for the live question
                    if (userAlreadyVoted === false && storedVotes && storedVotes.includes(parentInfo.data.currentLiveSession.id)) {
                        userAlreadyVoted = true;
                    }

                    if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(parentInfo.data.currentLiveSession.id)) {
                        userAlreadyVoted = true;
                    }

                        if (!this.props.changedEventInfo || this.props.changedEventInfo.automaticJumpPoll === "Active") {
                    if (userAlreadyVoted === false) {
                                this.goToPollPage();
                            }
                    }
                }
            }

        });


        window.addEventListener("message", (event) => {
            if (typeof event.data === "string") {
                try {
                    var eventmessage = JSON.parse(event.data);
                    if (eventmessage.event == "MINIMIZE") {
                        this.setState({
                            ...this.state,
                            startFullScreenMode: false
                        });
                    } else if (eventmessage.event == "FULLSCREEN") {
                        this.setState({
                            ...this.state,
                            startFullScreenMode: true
                        });
                    }
                } catch (error) {

                }
            }
        }, false);

            
        if (this.unsubscribeGetSurveyInfoOnChange) {
            this.unsubscribeGetSurveyInfoOnChange();
        }
        this.props.onGetSurveyInfoOnChange(this.state.eventId, this.state.surveyId, (survey, unsubscribe) => {
            this.unsubscribeGetSurveyInfoOnChange = unsubscribe;
            if (survey.startFullScreenMode === 'default') {
                this.setState({
                    ...this.state,
                    startFullScreenMode: false
                });
            } else if (survey.startFullScreenMode === 'Yes') {
                this.setState({
                    ...this.state,
                    startFullScreenMode: true
                });
            }

        })


            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
                    }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);
        }
    }

    componentDidUpdate(prevProps) {

        //console.log(this.state.currentSurveyFile);
        //console.log(this.state.currentSurveyFile.fileUrl);

        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        } else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                } else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeGetSurveyFiles) {
            this.unsubscribeGetSurveyFiles();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetSurveyInfoOnChange) {
            this.unsubscribeGetSurveyInfoOnChange();
        }
    }

    goToDashboardPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId);
    }

    goToPollPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId + "/poll");
    }

    goRoomPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToAgendaPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda")
    }

    goToSurveyPageModal(survey) {

        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }

        if (this.unsubscribeGetSurveyFiles) {
            this.unsubscribeGetSurveyFiles();
        }

        //console.log("*** got to survey page modal", survey);

        this.props.onGetSurveyFiles(this.state.eventId, survey.id, (surveyFilesList, unsubscribe) => {
            this.unsubscribeGetSurveyFiles = unsubscribe;
            if (surveyFilesList.length > 0) {
                for (var i = 0; i < surveyFilesList.length; i++) {
                    if (surveyFilesList[i] && surveyFilesList[i].fileUrl) {

                        this.setState({
                            ...this.state,
                            surveyId: survey.id,
                            surveyAnswered: false,
                            surveyFilesList,
                            currentSurveyFile: surveyFilesList[i]
                        });
                        return;
                    }

                }

            }
        })
    }

    goToQuestionPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goToRootChild(item, type) {
        if (type === "agenda") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
            if (item.startFullScreenMode === 'default') {
                this.setState({
                    ...this.state,
                    startFullScreenMode: false,
                    surveyId: item.id
                });
            } else if (item.startFullScreenMode === 'Yes') {
                this.setState({
                    ...this.state,
                    startFullScreenMode: true,
                    surveyId: item.id
                });
            }
            var surveyVotes = JSON.parse(localStorage.getItem("surveyVotes"));
            if (surveyVotes && surveyVotes.includes(item.id)) {
                this.setState({
                    ...this.state,
                    surveyAnswered: true
                });
            } else {
                if (this.unsubscribeGetSurveyFiles) {
                    this.unsubscribeGetSurveyFiles();
                }

                //console.log("*** goToRootChild onGetSurveyFiles of current survey file");

                this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                    this.unsubscribeGetSurveyFiles = unsubscribe;
                    if (surveyFilesList.length > 0) {
                        for (var i = 0; i < surveyFilesList.length; i++) {
                            if (surveyFilesList[i] && surveyFilesList[i].fileUrl) {
                                this.setState({
                                    ...this.state,
                                    surveyAnswered: false,
                                    surveyFilesList,
                                    currentSurveyFile: surveyFilesList[i],
                                });
                                return;
                            }

                        }
                    }
                });
            }


        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
        } else if (type === "qrcode") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
        } else if (type === "library") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
        }
    }

    onSelectLanguage(option) {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (option === 'English') {
            var value = 'English';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        } else {
            var value = 'Italian';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        }
    }

    render() {
        //console.log("render", this.state.currentSurveyFile.fileUrl);
        var headerComponent;
        var borderType;
        var selectedLanguageStrings;
        const userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    goToAgendaPage={this.goToAgendaPage.bind(this)}
                    goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    goToDashboardPage={this.goToDashboardPage.bind(this)}
                    goToQuestionPage={this.goToQuestionPage.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    pageName={"SurveyScreen"}
                    rootChildrenList={this.state.rootChildrenList}
                    surveyId={this.state.surveyId}

                />

            </div>);

            if (this.state.eventType === 'questions') {
                borderType = 'border-bottom-question';
            } else if (this.state.eventType === 'tweets') {
                borderType = 'border-bottom-tweet';
            } else {
                borderType = "";
            }
        }

        return (
            (this.state.currentSurveyFile === '') ? (
                null
            ) : (
            <main>
                <div className={(this.state.startFullScreenMode ? 'd-none' : '')}>
                    {headerComponent}
                </div>
                <div className="container container-screen-iframe">
                    {(this.state.surveyAnswered === true) ? (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{backgroundColor: this.state.event.textColor}}>
                                <h4 className="questionHeader" style={{color: this.state.event.primaryColor}}>
                                    Survey
                                </h4>
                            </div>
                            <div className="card-body black-text" id="cardOptionContent">
                                <div className="row">
                                    <div className="col-12 text-center" style={{color: this.state.event.primaryColor}}>
                                        <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <iframe id="serviceFrameSend" src={this.state.currentSurveyFile.fileUrl + this.props.location.search} frameBorder="0"
                                style={(this.state.startFullScreenMode ? iframeFullScreenStyle : null)}/>
                    )}
                </div>
            </main>

            )
        )
    }
}

// export default HtmlScreen;

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onGetSurveyInfoOnChange: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyInfoOnChange(eventId, surveyId, callback)),
    };
}

function mapStateToProps(state) {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SurveyScreenRoot);


const iframeFullScreenStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    zindex: 999999
};