import React, { Component } from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';

class SplashComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    };

    componentDidMount() {
    //    this.props.startTimerFunction();
    //we only change the status of the splash page if the question is not live so as soon
    this.props.onSetSplashPage(this.props.splashImage, "loaded");


    }

    render() {
        return (

                //fit screen
                // <div  className="image-container" >
                // <img src={this.props.splashImage} id="imagePreview" className='rounded ' style={{ backgroundColor: '#e6e6e6' }} />
                // </div>


                <div  className="image-container" style={{backgroundImage:"url("+this.props.splashImage+")"}} >
                </div>

        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetSplashPage: (splashImageUrl, status) => dispatch(generalActions.setSplashPage(splashImageUrl,status)),

    };
}
// export default SplashComponent;
export default connect(null, mapDispatchToProps)(SplashComponent);
