import React, {Component} from 'react';
import {connect} from 'react-redux';
import {tweetsActions} from '../Redux/Actions/tweetsActions';
import {generalActions} from '../Redux/Actions/generalActions';
import Tweet from '../Component/Tweet';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';


class TweetScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // eventId: this.props.match.params.idEvent,
            activeScreen: 'tweets',
            idTweetEl: '',
            participantInfo: '',
            participantData: [],
            participantTweetsList: [],
            allLiveTweetsList: [],
            allApprovedTweetsList: [],
            orderStatus: '',
            participantName: '',
            primaryColor: '',
            textColor: '',
            anonymousStatus: false,
            event: '',
            sidebarOpen: false,
            toggleActive: false,
            textToggle: '',
            sTweetsApproved: '',
            tweetsToShow: 'Approved',
            toggleActiveOrder: false,

        };
        // this.eventId = this.props.match.params.idEvent;
        this.eventId = this.eventId = this.props.event.id;
        this.closeModal = this.closeModal.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onToggleOrder = this.onToggleOrder.bind(this)

    }


    componentDidMount() {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.event.id));


        let storedText;

        if (participant.sTweetsApproved === true) {
            storedText = "ON";
        } else if (participant.sTweetsApproved === false) {
            storedText = "OFF";
        }


        if (participant.sTweetsOrder) {
            this.setState({
                ...this.state,
                event: this.props.event,
                primaryColor: this.props.event.primaryColor,
                textColor: this.props.event.textColor,
                participantInfo: participant,
                toggleActive: participant.sTweetsApproved,
                orderStatus: participant.sTweetsOrder,
                textToggle: storedText,
                toggleActiveOrder: false,
            });
        } else if (participant.sName) {
            this.setState({
                ...this.state,
                event: this.props.event,
                primaryColor: this.props.event.primaryColor,
                textColor: this.props.event.textColor,
                participantInfo: participant,
                toggleActive: participant.sTweetsApproved,
                participantName: participant.sName,
                textToggle: storedText
            });
        } else {
            this.setState({
                ...this.state,
                event: this.props.event,
                primaryColor: this.props.event.primaryColor,
                textColor: this.props.event.textColor,
                toggleActive: participant.sTweetsApproved,
                textToggle: storedText,
                participantInfo: participant,

            });
        }
    }

    componentWillUnmount() {
        this.closeModal();
        if (document.querySelectorAll(".modal-backdrop")[0]) {
            document.querySelectorAll(".modal-backdrop")[0].remove();
        }
    }

    sendTweet() {
        var tweetInputText = document.getElementById("tweetInputText").value;
        var tweetInputName = document.getElementById("tweetInputName").value;
        var anonymoudstatusValue = document.getElementById("optionAnonymous").checked;
        if (tweetInputText !== "") {
            //  this.props.onSaveNewTweet(this.state.participantInfo.id, this.eventId, this.state.idTweetEl, tweetInputText, tweetInputName);
            if (anonymoudstatusValue === true || tweetInputName === "") {
                //if the user checked the annonymoud status while sending the tweet
                this.props.onSaveNewTweet(this.props.participantInfo.id, this.props.event.id, this.props.parentInfo.currentTweetElement, tweetInputText, "");
            } else if (tweetInputName !== "" || tweetInputName !== this.state.participantName) {
                //we also update the participant's data if the name is provided
                this.props.onSaveNewTweet(this.state.participantInfo.id, this.props.event.id, this.props.parentInfo.currentTweetElement, tweetInputText, tweetInputName);
                this.props.onUpdateParticipantData(this.props.participantInfo, this.props.event.id, 'sName', tweetInputName);
                this.setState({participantName: tweetInputName});
            }
            document.getElementById('tweetModalsForm').reset();
        }
    }


    closeModal() {
        document.getElementById('tweetModalsForm').reset();
    }


    getUserData() {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.event.id));
        this.setState({...this.state, participantInfo: participant});
    }

    onSetSidebarOpen(open) {
        // this.setState({ sidebarOpen: open });
        this.props.onSettingStatus(open, callback => {
            if (callback === 'success') {
                this.setState({sidebarOpen: open});
            }
        });
    }

    onToggle() {
        let participantInfo = JSON.parse(localStorage.getItem('participant-' + this.props.event.id));

        if (this.state.toggleActive === true) {
            this.setState({toggleActive: false, textToggle: 'OFF'});

            Object.assign(participantInfo, {'sTweetsApproved': false});
            localStorage.setItem("participant", JSON.stringify(participantInfo));

        } else {
            this.setState({toggleActive: true, textToggle: 'ON'});
            Object.assign(participantInfo, {'sTweetsApproved': true});
            localStorage.setItem("participant", JSON.stringify(participantInfo));

        }
        // this.setState({ toggleActive: !this.state.toggleActive });
    }

    onToggleOrder() {
        //case 'MoreRecent':
        if (this.state.toggleActiveOrder === true) {
            // var sortedTweets = [];
            // sortedTweets = this.props.allTweetsList.sort(function (a, b) { return a.dtmCreated > b.dtmCreated ? -1 : 1 });
            // let participantSortedTweets = this.props.participantTweetsList.sort(function (a, b) { return a.dtmCreated > b.dtmCreated ? -1 : 1 });
            //this.setState({ ...this.state, allLiveTweetsList: sortedTweets, participantTweetsList: participantSortedTweets });
            this.props.onUpdateParticipantData(this.props.participantInfo, this.props.event.id, 'sTweetsOrder', 'MoreRecent');

            this.setState({
                ...this.state,
                //tweetsListToShow: sortedTweets,
                //participantTweetsList: participantSortedTweets,
                toggleActiveOrder: false
            });
            // document.getElementById(idSwitch).classList.add("recent");
            // document.getElementById(idSwitch).firstChild.innerHTML ="More Recent";

        } else {
            //case 'LessRecent':
            // var sortedTweetsDec = [];
            // sortedTweetsDec = this.props.allTweetsList.sort(function (a, b) { return a.dtmCreated < b.dtmCreated ? -1 : 1 })
            // let participantSortedTweetsDec = this.props.participantTweetsList.sort(function (a, b) { return a.dtmCreated < b.dtmCreated ? -1 : 1 });
            //this.setState({ ...this.state, allLiveTweetsList: sortedTweetsDec, participantTweetsList: participantSortedTweetsDec });
            this.props.onUpdateParticipantData(this.props.participantInfo, this.props.event.id, 'sTweetsOrder', 'LessRecent');
            this.setState({
                ...this.state,
                //  tweetsListToShow: sortedTweetsDec,
                //participantTweetsList: participantSortedTweetsDec,
                toggleActiveOrder: true
            });
            // document.getElementById(idSwitch).classList.remove("recent");
            // document.getElementById(idSwitch).firstChild.innerHTML ="Less Recent";
        }

        // this.setState({ toggleActive: !this.state.toggleActive });
    }


    render() {
        var selectedLanguageStrings;
        var selectedLanguageCustomStrings;

        if (this.props.selectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_en;
            }
        } else {
            selectedLanguageStrings = italianStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_it;
            }
        }

        let arrayTweetsComponent;
        let arrayparticipantTweets;
        var liveTweetsNo = 0;
        if (this.props.allTweetsList) {
            if (this.props.allTweetsList.length !== 0) {
                var sortedTweets = [];
                // if (this.state.toggleActiveOrder === false) {
                //     sortedTweets = this.props.allTweetsList.sort(function (a, b) { return a.dtmCreated > b.dtmCreated ? -1 : 1 });
                // } else {
                //     //case 'LessRecent':
                //     sortedTweets = this.props.allTweetsList.sort(function (a, b) { return a.dtmCreated < b.dtmCreated ? -1 : 1 })
                // }

                if (this.props.event.autoApproveTweets === "Without Moderator") {
                    sortedTweets = this.props.allTweetsList.sort(function (a, b) {
                        return a.dtmCreated > b.dtmCreated ? 1 : -1
                    });
                } else {
                    //case 'LessRecent':
                    sortedTweets = this.props.allTweetsList.sort(function (a, b) {
                        return a.dtmLive > b.dtmLive ? 1 : -1
                    })
                }

                if (sortedTweets[0] && sortedTweets[0].dtmLive === undefined) {
                    sortedTweets.push(sortedTweets.shift());
                }

                arrayTweetsComponent = (sortedTweets).map((tweet) => {
                    if (tweet.bArchived === false) {
                        liveTweetsNo++;
                        let pkidTweet = tweet.id;
                        let sText = tweet.sText;
                        // let dtmCreated = moment(tweet.dtmCreated.toDate()).format("HH:mm");
                        // let dtmCreated = tweet.dtmCreated;
                        let sParticipantName = tweet.participantDefinedName;
                        return (
                            <Tweet
                                tweet={tweet}
                                participantId={this.props.participantInfo.id}
                                sText={sText}
                                key={pkidTweet}
                                sParticipantName={sParticipantName}
                                dtmCreated={"dtmCreated"}
                                primaryColor={this.props.event.primaryColor}
                                textColor={this.props.event.textColor}
                                event={this.props.event}
                            />
                        );
                    }
                });


            }
        }


        //Receiving participant posted tweets if they become live we will remove them from this list and add them to the incoming tweets list
        if (this.props.participantTweetsList) {
            if (this.props.participantTweetsList.length !== 0) {
                var sortedParticipantTweets = [];
                if (this.state.toggleActiveOrder === false) {
                    sortedParticipantTweets = this.props.participantTweetsList.sort(function (a, b) {
                        return a.dtmCreated > b.dtmCreated ? -1 : 1
                    });
                } else {
                    //case 'LessRecent':
                    sortedParticipantTweets = this.props.participantTweetsList.sort(function (a, b) {
                        return a.dtmCreated < b.dtmCreated ? -1 : 1
                    });
                }

                arrayparticipantTweets = (sortedParticipantTweets).map((tweet) => {
                    let pkidTweet = tweet.id;
                    let sText = tweet.sText;
                    // let dtmCreated = moment(tweet.dtmCreated.toDate()).format("HH:mm");
                    // let dtmCreated = tweet.dtmCreated;
                    let sParticipantName = tweet.participantDefinedName;
                    return (
                        <Tweet
                            tweet={tweet}
                            participantId={this.state.participantInfo.id}
                            sText={sText}
                            key={pkidTweet}
                            sParticipantName={sParticipantName}
                            dtmCreated={"dtmCreated"}
                            primaryColor={this.props.event.primaryColor}
                            textColor={this.props.event.textColor}
                            event={this.props.event}
                        />
                    );
                });
            } else {
                arrayparticipantTweets = (
                    <div className="card">
                        <div className="card-body ">
                            <p className="fontTextArea text-center" style={{color: this.props.event.primaryColor}}>
                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.you_did_not_send_any_question_yet : selectedLanguageStrings.your_tweets_note}
                            </p>
                        </div>
                    </div>
                );
            }
        }

        var tweetsColTitle;
        if (this.props.changedEventInfo.autoApproveTweets === "Without Moderator") {
            tweetsColTitle = selectedLanguageStrings.all_tweets;
        } else if (this.props.changedEventInfo.tweetsToShow === "Live") {
            tweetsColTitle = selectedLanguageStrings.live_tweets;
        } else if (this.props.changedEventInfo.tweetsToShow === "Approved") {
            tweetsColTitle = selectedLanguageStrings.approved_tweets;
        }

        return (
            <div>
                {/* <main role="main">
                    <div className="container"> */}
                {/* participant Tweets Part */}

                {(this.props.parentInfo.currentTweetElement !== "default" && this.props.changedEventInfo.showTweetsToParticipants === "Yes" && this.props.allTweetsList && this.props.allTweetsList.length !== 0) ? (
                    <div>
                        <div className="row mr-1">
                            <div className="col-7 text-left container-tweet">
                                {(liveTweetsNo === 0) ? (
                                    <div></div>
                                ) : (
                                    <h5 className="mb-1 mt-4"
                                        style={{color: this.props.event.primaryColor}}>
                                        {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_questions : selectedLanguageStrings.live_tweets}
                                    </h5>
                                )}
                            </div>
                            {/* {(this.state.toggleActiveOrder === false) ? (
                        <div className="col-5 text-right mt-1" style={{ color: this.props.event.primaryColor }}>
                            <div className="switch recent" style={{ backgroundColor: this.props.event.primaryColor }} id="switch-btn" onClick={this.onToggleOrder}>
                                <div className="textToggleOrder" style={{ color: this.props.event.textColor }}>{selectedLanguageStrings.more_recent}</div>
                                <div className="inputOrder" style={{ backgroundColor: this.props.event.textColor }}></div>
                            </div>
                        </div>
                    ) : (
                            <div className="col-5 text-right mt-1 ">
                                <div className="switch " id="switch-btn" onClick={this.onToggleOrder}>
                                    <div className="textToggleOrder" style={{ color: this.props.event.textColor }}>{selectedLanguageStrings.less_recent}</div>
                                    <div className="inputOrder" style={{ backgroundColor: this.props.event.textColor }}></div>
                                </div>
                            </div>
                        )} */}
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                {arrayTweetsComponent}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}

                {(this.props.parentInfo.currentTweetElement !== "default") ? (
                    <div className="containerUserTweets">
                        <div className="row">
                            <div className="col-8 col-sm-8 text-left">
                                <div className="container-tweet">
                                    <h5 className="mb-1 mt-4" style={{color: this.props.event.primaryColor}}>
                                        {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.your_questions : selectedLanguageStrings.your_questions}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                {arrayparticipantTweets}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}


                <div className="fixedFooter mt-6">
                    <div className="container d-flex justify-content-center">
                        {/* {arrayTweets} */}
                        {(this.props.parentInfo.currentTweetElement !== "default" && this.props.parentInfo.tweetsParticipantsWriteEnable === true) ? (
                            <button type="button" className="btn"
                                    style={{backgroundColor: this.props.event.headerBackgroundColor}} id="fixedbutton"
                                    data-toggle="modal" data-target="#newTweetModal" onClick={this.getUserData}>
                                <i className="far fa-edit fixedbuttonIcon"
                                   style={{color: this.props.event.headerTextColor}}></i>
                            </button>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>

                <div className="modal fade" id="newTweetModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"
                                    style={{color: this.props.event.primaryColor}}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.write_a_new_question : selectedLanguageStrings.tweet_modal_title}
                                </h5>
                            </div>
                            <div className="modal-body">
                                <form id='tweetModalsForm'>
                                    <div className="form-group">
                                        <label htmlFor="tweetInputText" style={{color: this.props.event.primaryColor}}>
                                            {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.your_question : selectedLanguageStrings.your_question}
                                        </label>
                                        <textarea className="form-control" id="tweetInputText" rows="3"
                                                  name="tweetInputText"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tweetInputName"
                                               style={{color: this.props.event.primaryColor}}>{selectedLanguageStrings.your_name}</label>
                                        <input type="text" className="form-control" id="tweetInputName"
                                               name="tweetInputName" defaultValue={this.state.participantInfo.sName}/>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-10 ml-3">
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="optionAnonymous" name="optionAnonymous" />
                                                <label className="form-check-label mt-1" htmlFor="optionAnonymous" style={{ color: this.props.event.primaryColor }}>
                                                   {selectedLanguageStrings.send_anonymous}
                                                </label>
                                            </div> */}
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                       name="optionAnonymous" id="optionAnonymous"/>
                                                <label className="custom-control-label pl-1" htmlFor="optionAnonymous"
                                                       style={{
                                                           color: this.props.event.primaryColor,
                                                           paddingTop: "3px"
                                                       }}>
                                                    {selectedLanguageStrings.send_anonymous}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        onClick={() => this.closeModal()}>{selectedLanguageStrings.cancel}</button>
                                <button type="button" className="btn" style={{
                                    backgroundColor: this.props.event.headerBackgroundColor,
                                    color: this.props.event.headerTextColor
                                }} data-dismiss="modal"
                                        onClick={this.sendTweet.bind(this)}>{selectedLanguageStrings.send}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEventData: (eventCode, callback) => dispatch(generalActions.getEventData(eventCode, callback)),
        onSaveNewTweet: (participantId, eventId, idTweetEl, tweetInputText, tweetInputName) => dispatch(tweetsActions.saveNewTweet(participantId, eventId, idTweetEl, tweetInputText, tweetInputName)),
        onSettingStatus: (status, callback) => dispatch(generalActions.settingStatus(status, callback)),
        onUpdateParticipantData: (participantInfo, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantInfo, eventId, option, value)),
    };
}

function mapStateToProps(state) {

    let tweets = state.tweetsReducers.allTweetsList || "empty";
    return {
        tweets,
        eventData: state.generalReducers.eventData,
        // allTweetsList: state.tweetsReducers.allTweetsList,
        // participantTweetsList: state.tweetsReducers.participantTweetsList,
        changedEventInfo: state.generalReducers.changedEventInfo
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TweetScreen);
