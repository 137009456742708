import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generalActions } from '../Redux/Actions/generalActions';
import QrReader from '../../src/react-qr-scanner-custom';

import Header from '../Component/Header';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import Flag from 'react-world-flags';
import Sidebar from "react-sidebar";
import moment from 'moment';
import Utility from '../Helpers/Utility';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';

class QrCodeScanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idEvent,
            idRoom: this.props.match.params.idElement,
            qrcodeId: this.props.match.params.idItem,
            qrText: "",
            currentFile: "default",
            result: 'No result',
            event: 'default',
            parentInfo: 'default',
            eventMessagesModal: 'eventMessagesModal',
            badgeNumber: 0,
            eventImportantModal: "eventImportantModal",
            userSelectedLanguage: 'default',
            participantInfo: 'default',
            roomsList: [],
            rootChildrenList: [],
            delay: 100,
            userStatus: 'initialized'
        };
        this.handleScan = this.handleScan.bind(this);
        this.openImageDialog = this.openImageDialog.bind(this);
        this.goToSurveyPageModal = this.goToSurveyPageModal.bind(this);
        this.goToQuestionPage = this.goToQuestionPage.bind(this);
    }

    componentDidMount() {
        // this.props.onGetLibraryFileInfo(this.state.eventId, this.state.idLibrary, this.state.idFile, file=>{
        //     if(file !=="Not Exists"){
        //     window.open(file.fileUrl,"_self");
        // //    this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.idRoom + "/library")
        //     }
        // });
        let userStoredLanguage = JSON.parse(localStorage.getItem('4meet-Language'));
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (!participant) {
            this.props.history.push("/");
        } else {

        this.setState({ ...this.state, participantInfo: participant, userSelectedLanguage: userStoredLanguage.selectedLanguage });
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.idRoom, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            this.setState({
                ...this.state,
                parentInfo: parentInfo.data
            });
            var userAlreadyVoted = false;
            if (parentInfo.message === 'success') {
                if (parentInfo.data.currentLiveSession.status === "live") {

                    //we only go to the tweet question if the user did not already voted for the live question
                    if (userAlreadyVoted === false && storedVotes && storedVotes.includes(parentInfo.data.currentLiveSession.id)) {
                        userAlreadyVoted = true;
                    }

                    if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(parentInfo.data.currentLiveSession.id)) {
                        userAlreadyVoted = true;
                    }

                    if (!this.props.changedEventInfo || this.props.changedEventInfo.automaticJumpPoll === "Active") {
                    if (userAlreadyVoted === false) {
                            this.goToPollPage();
                        }
                    }
                }
            }
        });

        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }
        this.props.onEventInfoChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeEventInfoChange = unsubscribe;
            this.setState({ ...this.state, event: callback.data });
        });
        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }        
        this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (rootChildrenList, unsubscribe) => {
            this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
            let orederdRootChildrenList = [];
            let orderedRoomsChildrenList = [];
            let allElementsList = [];
            for (var i = 0; i < rootChildrenList.length; i++) {
                if (rootChildrenList[i].sParentId === "default") {
                    orederdRootChildrenList.push(rootChildrenList[i]);
                } else if (rootChildrenList[i].sParentId === this.state.idRoom) {
                    orderedRoomsChildrenList.push(rootChildrenList[i]);
                }
            }

            orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);
                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {

                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }
                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;
    
                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                }
                            });
    
                        });
    
                    }
                });
            this.setState({ ...this.state, rootChildrenList: allElementsList });
        });

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }        
        this.props.onGetEventRoomsOnChange(this.state.eventId, (roomsList, unsubscribe) => {
            this.unsubscribeGetEventRoomsOnChange = unsubscribe;
            if (roomsList.length > 0) {
                this.setState({
                    ...this.state,
                    roomsList: roomsList
                });
            }
        });

        if (this.unsubscribeGetQRText) {
            this.unsubscribeGetQRText();
        }
        this.props.onGetQRText(this.state.eventId, this.state.qrcodeId, (qrText, unsubscribe) => {
            this.unsubscribeGetQRText = unsubscribe;
            this.setState({
                ...this.state,
                qrText: qrText
            });
        });


        let shouldFaceUser = false;
        navigator.getUserMedia = navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia;

        if (navigator.getUserMedia) {
            // navigator.getUserMedia({ audio: true, video: { width: 280, height: 280 }
            navigator.getUserMedia(
                { audio: false, video: { width: 300, height: 300, facingMode: shouldFaceUser ? 'user' : 'environment' }, },
                function (stream) {
                    var video = document.querySelector('video');
                    video.srcObject = stream;
                    // video.facingMode = "environment";
                    video.onloadedmetadata = function (e) {
                        video.play();
                    };
                },
                function (err) {

                }
            );
        } else {

        }

            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
                    }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);
        }
    }

    componentDidUpdate(prevProps) {

        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        }
        else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                }
                else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
    }
    
    componentWillUnmount() {
        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeGetSurveyFiles) {
            this.unsubscribeGetSurveyFiles();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetQRText) {
            this.unsubscribeGetQRText();
        }
    }

    handleScan(data) {
        this.setState({
            result: data,
        });
        if (data) {
            // ResponseURL = 'http://localhost:3005/event/dtjuhtn5Krideol4EdRf/dashboard/defaultRoom/nLO1sepPrRvadg3474vR/pmsBFeErujOYj16YaAnb'
            var domain = data.split('/');
            var firstPart = domain[domain.length - 7];
            var eventId = domain[domain.length - 5];
            //var roomId = domain[domain.length - 3];
            var libraryId = domain[domain.length - 3];
            var fileId = domain[domain.length - 1];
            if (firstPart === "4meet.in") {
                this.props.history.push("/event/" + eventId + "/dashboard/" + libraryId + "/show/" + fileId + "/internal/");
                //this.props.history.push("/event/"+ this.state.eventId + "/dashboard/" + this.state.idRoom + "/qrcode/" + this.state.qrcodeId);
                // this.props.onGetLibraryFileInfo(eventId, libraryId, fileId, file=>{
                //         if(file !=="Not Exists"){
                //         // window.open(file.fileUrl,"_self");
                //     //    this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.idRoom + "/library")
                //         }
                //     });
            } else {
                alert("This is not a valid 4meet Qr Code.");
            }
        }
    }

    handleError(err) {
        console.error(err);
        //alert(err);
    }

    openImageDialog() {
        this.refs.qrReader1.openImageDialog()
    }

    goToDashboardPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId);
    }

    goToPollPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId + "/poll");
    }  
        
    goRoomPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToAgendaPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda")
    }

    goToQuestionPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goToSurveyPageModal(survey) {

        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }

        if (survey.startInIframe) {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + survey.id);
        }
        else {
            this.props.onGetSurveyFilesData(this.props.changedEventInfo.id, survey.id, (surveyfiles) => {
                window.open(surveyfiles[0].fileUrl, "_blank");
            });
        }
        
    }
        
    onSelectLanguage(option) {

        if (option === 'English') {
            var value = 'English';
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
            this.setState({
                ...this.state,
                userSelectedLanguage: 'English',
                sidebarOpen: false
            });
        } else {
            var value = 'Italian';
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
            this.setState({
                ...this.state,
                userSelectedLanguage: 'Italian',
                sidebarOpen: false
            });
        }
    }

    goToRootChild(item, type) {

        if (type === "agenda") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
            this.setState({ ...this.state, surveyId: item.id });
            var surveyVotes = JSON.parse(localStorage.getItem("surveyVotes"));
            if (surveyVotes && surveyVotes.includes(item.id)) {
                this.setState({
                    ...this.state,
                    surveyAnswered: true
                });
            } else {
                if (this.unsubscribeGetSurveyFiles) {
                    this.unsubscribeGetSurveyFiles();
                }
                this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                    this.unsubscribeGetSurveyFiles = unsubscribe;
                    if (surveyFilesList.length > 0) {
                        for (var i = 0; i < surveyFilesList.length; i++) {
                            if (surveyFilesList[i] && surveyFilesList[i].fileUrl) {
                                this.setState({
                                    ...this.state,
                                    surveyAnswered: false,
                                    surveyFilesList,
                                    currentSurveyFile: surveyFilesList[i],
                                });
                                return;
                            }

                        }

                    }
                })
            }
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
        } else if (type === "qrcode") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
        } else if (type === "library") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
        }
    }

    render() {

        const previewStyle = {
            height: "100%",
        }
        var headerComponent;
        var borderType;

        var selectedLanguageStrings;
        if (this.state.userSelectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    badgeNumber={this.state.badgeNumber}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    goToDashboardPage={this.goToDashboardPage.bind(this)}
                    goToQuestionPage={this.goToQuestionPage.bind(this)}
                    goToAgendaPage={this.goToAgendaPage.bind(this)}
                    goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    pageName={"SurveyScreen"}
                    rootChildrenList={this.state.rootChildrenList}
                />
            </div>);

            if (this.state.eventType === 'questions') {
                borderType = 'border-bottom-question';
            } else if (this.state.eventType === 'tweets') {
                borderType = 'border-bottom-tweet';
            } else {
                borderType = "";
            }
        }

        return (
            <main role="main">
                <div>
                    {headerComponent}
                </div>

                <div className="qrScanner">
                    <QrReader
                        delay={100}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={previewStyle}
                    //facingMode='rear'
                    />
                </div>

                {(this.state.qrText === "") ? (
                    <p className="text-center qrText" style={{ left: 0, right: 0, margin: "auto", position: "absolute", top: "100px", padding: "0 10vw", color: this.state.event.textColor, fontWeight: "bold", textAlign: "center" }}></p>
                ) : (
                    <p className="text-center qrText" style={{ left: 0, right: 0, margin: "auto", position: "absolute", top: "100px", padding: "0 10vw", color: this.state.event.textColor, fontWeight: "bold", textAlign: "center" }}>{this.state.qrText}</p>
                )}


                {/* <QrReader
                ref="qrReader1"
                delay={this.state.delay}
                style={previewStyle}
                onError={this.handleError}
                onScan={this.handleScan}
                legacyMode
            />
            <input type="button" value="Submit QR Code" onClick={this.openImageDialog} />
            <p>{this.state.result}</p> */}
            </main>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetLibraryFileInfo: (eventId, libraryId, fileId, callback) => dispatch(generalActions.getLibraryFileInfo(eventId, libraryId, fileId, callback)),
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onGetQRText: (eventId, qrcodeId, callback) => dispatch(generalActions.getQRText(eventId, qrcodeId, callback)),
        onGetSurveyFilesData: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFilesData(eventId, surveyId, callback)),
    };
};
const mapStateToProps = state => {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
        parentInfo: state.generalReducers.parentInfo
    };


};

export default connect(mapStateToProps, mapDispatchToProps)(QrCodeScanner);





