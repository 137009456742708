import React, { Component } from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';
import Header from '../Component/Header';
import StarRatingComponent from 'react-star-rating-component';
import { questionActions } from '../Redux/Actions/questionsActions';
import Utility from '../Helpers/Utility';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';


class PollScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            event: '',
            parentInfo: 'default',
            participantInfo: '',
            rootChildrenList: [],
            roomsList: [],
            sessionId: '',
            questionId: '',
            elementId: '',
            optionsList: [],
            voted: '',
            answeredText: '',
            selectedOptionsList: [],
            questionType: '',
            textColor: '',
            sendVoteStatus: 'disabled',
            sendAnswerStatus: 'disabled',
            rating: 0,
            likeSelected: undefined,
            questionData: 'default',
            questionOptionsList: [],
            showAnswerAlert: true,
            overlayClassAlert: " ",
            userStatus: 'initialized'
        };

        this.getUserData = this.getUserData.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.sendVote = this.sendVote.bind(this);
        this.sendAnswer = this.sendAnswer.bind(this);
        this.sendVoteAndComment = this.sendVoteAndComment.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onSelectedOption = this.onSelectedOption.bind(this);
        this.onFillingComment = this.onFillingComment.bind(this);
        this.sendRatingAnswer = this.sendRatingAnswer.bind(this);
        this.sendResponse = this.sendResponse.bind(this);
        this.goToQuestionPage = this.goToQuestionPage.bind(this);
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }
        
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));

        if (!participant) {
            this.props.history.push("/");
        } else {
            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (cb_eventinfo, unsubscribe) => {
                this.unsubscribeEventInfoChange = unsubscribe;

                if (cb_eventinfo.data.eventStatus === "Archived") {
                    window.location.href = "/";
                }

                this.setState({
                    ...this.state,
                    participantInfo: participant,
                    event: cb_eventinfo.data,
                });

                if (!cb_eventinfo.data.availableLanguages.lang_it || !cb_eventinfo.data.availableLanguages.lang_en) {
                    if (cb_eventinfo.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (cb_eventinfo.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
                }                
            });

            if (this.unsubscribeGetListOfRootChildrenOnChange) {
                this.unsubscribeGetListOfRootChildrenOnChange();
            }
            this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (cb_rootchildrenlist, unsubscribe) => {
                this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
                let orederdRootChildrenList = [];
                let orderedRoomsChildrenList = [];
                let allElementsList = [];
                for (var i = 0; i < cb_rootchildrenlist.length; i++) {
                    if (cb_rootchildrenlist[i].sParentId === "default") {
                        orederdRootChildrenList.push(cb_rootchildrenlist[i]);
                    } else if (cb_rootchildrenlist[i].sParentId === this.state.roomId) {
                        orderedRoomsChildrenList.push(cb_rootchildrenlist[i]);
                    }
                }

                orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
                orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
                allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);
                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {

                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }
                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;
    
                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                }
                            });
    
                        });
    
                    }
                });
                this.setState({
                    ...this.state,
                    rootChildrenList: allElementsList,
                });

            });

            if (this.unsubscribeGetEventRoomsOnChange) {
                this.unsubscribeGetEventRoomsOnChange();
            }
            this.props.onGetEventRoomsOnChange(this.state.eventId, (cb_roomslist, unsubscribe) => {
                this.unsubscribeGetEventRoomsOnChange = unsubscribe;

                //Check if the current room exists, otherwise redirect to the rooms page.   
                let roomExists = false;
                if (cb_roomslist.length > 0) {
                    for (var i = 0; i < cb_roomslist.length; i++) {
                        if (cb_roomslist[i].id === this.state.roomId) {
                            roomExists = true;
                            break;
                        }
                    }
                }
                if (roomExists === true) {
                    this.setState({
                        ...this.state,
                        roomsList: cb_roomslist,
                    });

                } else {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
                }
            });

            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.roomId, (cb_parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;

                this.setState({
                    ...this.state,
                    parentInfo: cb_parentInfo.data
                });

                if (cb_parentInfo.message === 'success') {
                    if (cb_parentInfo.data.currentLiveSession.status === "live") {
                        this.props.onGetQuestion(this.props.changedEventInfo.id, cb_parentInfo.data.currentLiveSession.liveQuestionElement, cb_parentInfo.data.currentLiveSession.liveQuestionId, callBackQuestionData => {
                            //to prevent the loading status on the client side when the admin changes the current question to view on the admin side
                            if (this.state.questionData.id !== cb_parentInfo.data.currentLiveSession.liveQuestionId) {
                                this.setState({
                                    ...this.state,
                                    questionOptionsList: []
                                });
                            }
                            //Receiving the list of options in the current live session
                            this.props.ongetLiveSessionOptions(this.props.changedEventInfo.id, cb_parentInfo.data.currentLiveSession.liveQuestionElement, cb_parentInfo.data.currentLiveSession.liveQuestionId, cb_parentInfo.data.currentLiveSession.id, callbackOptions => {
                                this.setState({
                                    ...this.state,
                                    eventType: 'questions',
                                    questionData: callBackQuestionData,
                                    questionOptionsList: callbackOptions
                                });
                            });
                        });

                        let storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                        let storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
                        if (cb_parentInfo.data.currentLiveSession.liveQuestionId) {
                            if (storedVotes && storedVotes.includes(cb_parentInfo.data.currentLiveSession.id)) {
                                this.setState({
                                    voted: 'voted' + cb_parentInfo.data.currentLiveSession.id,
                                    questionType: 'MultipleChoice'
                                });
                            } else if (storedAnswersText && storedAnswersText.includes(cb_parentInfo.data.currentLiveSession.id)) {
                                this.setState({
                                    voted: 'voted' + cb_parentInfo.data.currentLiveSession.id,
                                    answeredText: 'answeredText',
                                    questionType: 'SimpleTextQuestion'
                                });
                            } else {
                                this.setState({
                                    elementId: cb_parentInfo.data.currentLiveSession.liveQuestionElement,
                                    questionId: cb_parentInfo.data.currentLiveSession.liveQuestionId,
                                    sessionId: cb_parentInfo.data.currentLiveSession.id,
                                    //cardTitle: callBackQuestionData.text,
                                    //letMultiplechoiceSelection: callBackQuestionData.letMultipleSelect,
                                    //optionsList: callbackOptions,
                                    voted: '',
                                    //questionType: callBackQuestionData.type,
                                    sendVoteStatus: "disabled"
                                });
                            }
                        }
                    }
                    else {
                        this.setState({
                            ...this.state,
                            questionData: "default",
                        });
                    }
                }
            });

            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
                    }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            }, () => {
                                if (conStat.selectedOptionsListStored !== 'default' && this.state.responseType === "MultipleChoice") {
                                    conStat.sendResponse(this.state.responseType, conStat.state.selectedOptionsListStored, conStat.state.voteSessionId);
                                }

                                if (conStat.participantReplyStored !== 'default' && this.state.responseType === "SimpleText") {
                                    let value = {
                                        participantReply: conStat.state.participantReplyStored,
                                        participantName: conStat.state.participantNameStored
                                    }
                                    conStat.sendResponse(this.state.responseType, value, conStat.state.voteSessionId);
                                }

                                if (conStat.selectedOptionsListStored !== 'default' && this.state.responseType === "SimpleTextAndMultipleChoice") {
                                    conStat.sendResponse(this.state.responseType, conStat.state.selectedOptionsListStored, conStat.state.voteSessionId);
                                }

                                if (conStat.selectedOptionsListStored !== 'default' && this.state.responseType === "RatingQuestion") {
                                    let value = {
                                        participantReply: conStat.state.participantRatingStored,
                                        participantName: conStat.state.participantNameStored
                                    }
                                    conStat.sendResponse(this.state.responseType, value, conStat.state.voteSessionId);
                                }
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);

        }
    }

    componentDidUpdate(prevProps, prevState) {
        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        } else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                } else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
    }

    onSelectLanguage(option) {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (option === 'English') {
            var value = 'English';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        } else {
            var value = 'Italian';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        }
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goToDashboardPage() {
        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
        var userAlreadyVoted = false;

        if (this.state.parentInfo.currentLiveSession.status === "live") {
            //we only go to the tweet question if the user did not already voted for the live question
            if (userAlreadyVoted === false && storedVotes && storedVotes.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === true) {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
            } else {
                this.setState({ ...this.state, showAnswerAlert: true, overlayClassAlert: "overlayClassAlert" });
                this.onSetSidebarOpen(false);
                document.getElementById("showAnswerModalBtn").click();
            }
        }
        else {
            this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId);
        }        
    }

    goRoomPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToAgendaPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda")
    }

    goToQuestionPage() {
        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
        var userAlreadyVoted = false;

        if (this.state.parentInfo.currentLiveSession.status === "live") {
            //we only go to the tweet question if the user did not already voted for the live question
            if (userAlreadyVoted === false && storedVotes && storedVotes.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === true) {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
            } else {
                this.setState({ ...this.state, showAnswerAlert: true, overlayClassAlert: "overlayClassAlert"});
                this.onSetSidebarOpen(false);
                document.getElementById("showAnswerModalBtn").click();
            }
        }
        else {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
        }
    }

    goToSurveyPageModal(survey) {
        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }

        if (survey.startInIframe) {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + survey.id);
        }
        else {
            this.props.onGetSurveyFilesData(this.props.changedEventInfo.id, survey.id, (surveyfiles) => {
                window.open(surveyfiles[0].fileUrl, "_blank");
            });
        }

    }

    goToRootChild(item, type) {
        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
        var userAlreadyVoted = false;

        if (this.state.parentInfo.currentLiveSession.status === "live") {
            //we only go to the tweet question if the user did not already voted for the live question
            if (userAlreadyVoted === false && storedVotes && storedVotes.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === true) {
                if (type === "agenda") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
                } else if (type === "library") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
                } else if (type === "survey") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
                } else if (type === "qrcode") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
                }
            } else {
                this.setState({ ...this.state, showAnswerAlert: true, overlayClassAlert: "overlayClassAlert" });
                this.onSetSidebarOpen(false);
                document.getElementById("showAnswerModalBtn").click();
            }
        }
        else {
            if (type === "agenda") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
            } else if (type === "survey") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
            } else if (type === "qrcode") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
            } else if (type === "library") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
            }
        }
    }

    closeAnswerAlert() {
        this.setState({ ...this.state, showAnswerAlert: false, overlayClassAlert: " " });
    }

    getUserData() {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.event.id));
        this.setState({ ...this.state, participantInfo: participant });
    }

    sendVote() {
        if (this.state.sendVoteStatus !== "disabled") {
            this.sendResponse('MultipleChoice', this.state.selectedOptionsList, this.state.sessionId);
            this.setState({
                voted: 'voted' + this.state.parentInfo.currentLiveSession.id,
                selectedOptionsList: [],
                sendVoteStatus: "disabled"
            });
        }
    }

    sendAnswer() {
        // if(this.state.sendVoteStatus !== "disabled"){
        let participantReply = document.getElementById("answerTextArea").value;
        let participantName;
        if (this.state.participantInfo && this.state.participantInfo.sName && this.state.participantInfo.sName !== "") {
            participantName = this.state.participantInfo.sName;
        } else {
            participantName = "";
        }
        let value = { participantReply: participantReply, participantName: participantName };
        this.sendResponse('SimpleText', value, this.state.sessionId);
        this.setState({
            voted: 'voted' + this.state.parentInfo.currentLiveSession.id,
            answeredText: 'answeredText',
            sendAnswerStatus: "disabled"
        });
        // }
    }

    sendVoteAndComment() {
        if (this.state.sendVoteStatus !== "disabled") {
            var comment = document.getElementById('commentTextArea').value;
            var value = { optionsList: this.state.selectedOptionsList, comment: comment };
            this.sendResponse('SimpleTextAndMultipleChoice', value, this.state.sessionId);
            this.setState({
                voted: 'voted' + this.state.parentInfo.currentLiveSession.id,
                selectedOptionsList: [],
                sendVoteStatus: "disabled",
                likeSelected: undefined
            });
        }
    }

    sendRatingAnswer() {
        if (this.state.sendVoteStatus !== "disabled") {
            let participantName;
            if (this.state.participantInfo && this.state.participantInfo.sName && this.state.participantInfo.sName !== "") {
                participantName = this.state.participantInfo.sName;
            } else {
                participantName = "";
            }
            let value = { participantRating: this.state.rating, participantName: participantName };
            this.sendResponse('RatingQuestion', value, this.state.sessionId);
            this.setState({
                voted: 'voted' + this.state.parentInfo.currentLiveSession.id,
                rating: 0,
                sendVoteStatus: "disabled"
            });
        }
    }

    sendResponse(responseType, value, sessionId) {
        if (responseType === 'MultipleChoice') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state,
                    selectedOptionsListStored: value,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.selectedOptionsListStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        constState.props.onSendVote('MultipleChoice', constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value, constState.state.participantInfo.id);
                        //localStorage.setItem('votes', this.state.sessionId);
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                        if (!storedVotes) {
                            var storedVotesNew = [];
                            storedVotesNew.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                        } else {
                            storedVotes.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the vote is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, selectedOptionsListStored: 'default' })
            } else {
                this.props.onSendVote('MultipleChoice', this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value, this.state.participantInfo.id);
                //localStorage.setItem('votes', this.state.sessionId);
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                if (!storedVotes) {
                    var storedVotesNew = [];
                    storedVotesNew.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                } else {
                    storedVotes.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                }

                this.setState({ ...this.state, selectedOptionsListStored: 'default' });
            }
        } else if (responseType === 'SimpleText') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state,
                    participantReplyStored: value.participantReply,
                    participantNameStored: value.participantName,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.participantReplyStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        constState.props.onSendAnswerText(constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value.participantReply, value.participantName, constState.state.participantInfo.id);
                        //localStorage.setItem('votes', this.state.sessionId);
                        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        if (storedAnswersText) {
                            storedAnswersText[storedAnswersText.length + 1] = constState.state.parentInfo.currentLiveSession.id;
                            localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                        } else {
                            var storedAnswersText = [];
                            storedAnswersText[0] = constState.state.parentInfo.currentLiveSession.id;
                            localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the Answer is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, participantReplyStored: 'default' })
            } else {
                constState.props.onSendAnswerText(this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value.participantReply, value.participantName, this.state.participantInfo.id);
                //localStorage.setItem('votes', this.state.sessionId);
                var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                if (storedAnswersText) {
                    storedAnswersText[storedAnswersText.length + 1] = constState.state.parentInfo.currentLiveSession.id;
                    localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                } else {
                    var storedAnswersText = [];
                    storedAnswersText[0] = constState.state.parentInfo.currentLiveSession.id;
                    localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                }
                this.setState({ ...this.state, participantReplyStored: 'default' })
            }
        } else if (responseType === 'SimpleTextAndMultipleChoice') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state,
                    selectedOptionsListStored: value,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.selectedOptionsListStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        //Get the paticipant's id from the local storage
                        constState.props.onSendVote('SimpleTextAndMultipleChoice', constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value, constState.state.participantInfo.id);
                        //localStorage.setItem('votes', this.state.sessionId);
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                        if (!storedVotes) {
                            var storedVotesNew = [];
                            storedVotesNew.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                        } else {
                            storedVotes.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the vote is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, selectedOptionsListStored: 'default' })
            } else {
                this.props.onSendVote('SimpleTextAndMultipleChoice', this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value, this.state.participantInfo.id);
                //localStorage.setItem('votes', this.state.sessionId);
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                if (!storedVotes) {
                    var storedVotesNew = [];
                    storedVotesNew.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                } else {
                    storedVotes.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                }

                this.setState({ ...this.state, selectedOptionsListStored: 'default' });
            }
        } else if (responseType === 'RatingQuestion') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state,
                    participantRatingStored: value.participantRating,
                    participantNameStored: value.participantName,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.participantRatingStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        constState.props.onSendRatingAnswer(constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value.participantReply, value.participantName, constState.state.participantInfo.id);
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                        if (!storedVotes) {
                            var storedVotesNew = [];
                            storedVotesNew.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                        } else {
                            storedVotes.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the Answer is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, participantRatingStored: 'default' })
            } else {
                constState.props.onSendRatingAnswer(this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value.participantRating, value.participantName, this.state.participantInfo.id);
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                if (!storedVotes) {
                    var storedVotesNew = [];
                    storedVotesNew.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                } else {
                    storedVotes.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                }

                constState.setState({ ...constState.state, selectedOptionsListStored: 'default' });
                this.setState({ ...this.state, participantRatingStored: 'default' })
            }
        }
    }

    onSelectedOption(option, like = false) {
        var options = this.state.selectedOptionsList;
        var exist = false;
        var existIndex;

        if (this.state.questionData.letMultipleSelect === true) {
            for (var i = 0; i < options.length; i++) {
                if (options[i] === option.id) {
                    exist = true;
                    existIndex = i;
                }
            }

            if (exist === false) {
                if (this.state.questionData.multipleChoiceLimitAnswer > 0) {
                    if (options.length < this.state.questionData.multipleChoiceLimitAnswer) {
                        options.push(option.id);
                    }
                } else {
                    options.push(option.id);
                }
            } else {
                options.splice(existIndex, 1);
            }
        } else {
            //If it is single choice we only store the last option of the participant if he has change his selection
            // so the options list will only contain the last selected choice of the participant
            options = [];
            options.push(option.id);
        }
        if (this.state.questionData.type === "SimpleTextAndMultipleChoice" && this.state.questionData.simpleTextAnswerMandatory === true) {
            var commentStatus = document.getElementById('commentTextArea').value;
            if (options.length !== 0) {
                if (commentStatus !== "") {
                    this.setState({ ...this.state, selectedOptionsList: options, sendVoteStatus: "" });
                } else {
                    this.setState({ ...this.state, selectedOptionsList: options, sendVoteStatus: "disabled" });
                }
            } else {
                this.setState({ ...this.state, selectedOptionsList: options, sendVoteStatus: "disabled" });
            }
        } else {
            if (options.length !== 0) {
                this.setState({ ...this.state, selectedOptionsList: options, sendVoteStatus: "" });
            } else {
                this.setState({ ...this.state, selectedOptionsList: options, sendVoteStatus: "disabled" });
            }
        }

        if (this.state.questionData.type === "SimpleTextAndMultipleChoice" && this.state.questionData.layout === "like-dislike") {
            if (like) {
                this.setState({ likeSelected: true });
            } else {
                this.setState({ likeSelected: false });
            }
        }
    }

    onFillingComment() {
        var commentStatus = document.getElementById('commentTextArea').value;
        if (commentStatus !== "") {
            if (this.state.selectedOptionsList.length !== 0) {
                this.setState({ ...this.state, sendVoteStatus: "" });
            } else {
                this.setState({ ...this.state, sendVoteStatus: "disabled" });
            }
        } else {
            this.setState({ ...this.state, sendVoteStatus: "disabled" });
        }
    }

    onToggle() {
        let participantData = JSON.parse(localStorage.getItem('participant-' + this.props.event.id));
        if (this.state.toggleActive === true) {
            this.setState({ toggleActive: false, textToggle: 'OFF' });
            Object.assign(participantData, { 'sTweetsApproved': false });
            localStorage.setItem("participant", JSON.stringify(participantData));
        } else {
            this.setState({ toggleActive: true, textToggle: 'ON' });
            Object.assign(participantData, { 'sTweetsApproved': true });
            localStorage.setItem("participant", JSON.stringify(participantData));
        }
    }

    onStarClick(nextValue, prevValue, name) {
        this.setState({ ...this.state, rating: nextValue, sendVoteStatus: " " });
    }

    onSetSidebarOpen(open) {
        // this.setState({ sidebarOpen: open });
        this.props.onSettingStatus(open, callback => {
            if (callback === 'success') {
                this.setState({ sidebarOpen: open });
            }
        });
    }


    render() {
        var headerComponent;
        var borderType;
        var selectedLanguageStrings;
        var selectedLanguageCustomStrings;
        const userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_en;
            }
        } else {
            selectedLanguageStrings = italianStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_it;
            }
        }

        let optionsListComponents = [];
        if (this.state.questionOptionsList) {
            optionsListComponents = (this.state.questionOptionsList || []).map((option, i) => {
                var backgroundOption = this.state.event.textColor;
                var textColorOption = this.state.event.primaryColor;
                var voteBarColor;
                var optionOrderType;
                if (this.state.questionData.optionDecorationValue === "Alphabet") {
                    optionOrderType = "a"
                } else if (this.state.questionData.optionDecorationValue === "Number") {
                    optionOrderType = "1"
                } else if (this.state.questionData.optionDecorationValue === "Bullet") {
                    optionOrderType = "disc"
                }

                if (this.state.selectedOptionsList.length > 0) {
                    for (var i = 0; i < this.state.selectedOptionsList.length; i++) {
                        if (option.id === this.state.selectedOptionsList[i]) {
                            backgroundOption = this.state.event.primaryColor;
                            textColorOption = this.state.event.textColor;
                        }
                    }
                }
                if (this.state.questionData.type === "SimpleTextAndMultipleChoice" && this.state.questionData.layout === "like-dislike") {
                    let dislikeColor;
                    let likeColor;
                    let likeBackgroundCOlor;
                    let dislikeBackgroundColor;
                    if (this.state.likeSelected === undefined) {
                        likeColor = '#28A745';
                        dislikeColor = '#DC3545';
                        likeBackgroundCOlor = 'white';
                        dislikeBackgroundColor = 'white';
                    } else if (this.state.likeSelected === true) {
                        likeColor = this.state.event.textColor;
                        dislikeColor = '#DC3545';
                        likeBackgroundCOlor = this.state.event.primaryColor;
                        dislikeBackgroundColor = 'white';
                    } else {
                        dislikeColor = this.state.event.textColor;
                        likeColor = '#28A745';
                        likeBackgroundCOlor = 'white';
                        dislikeBackgroundColor = this.state.event.primaryColor;
                    }

                    return (
                        <div className="col-12 p-0" key={option.id}>
                            {(option.type === "like") ? (
                                <div className="col-12 p-0"
                                    onClick={() => {
                                        this.onSelectedOption(option, true)
                                    }}>
                                    <div className="btn col-12 p-4"
                                        style={{ backgroundColor: likeBackgroundCOlor }}>
                                        <div className="row justify-content-start">
                                            <i className="fas fa-thumbs-up optionLikeIcon pr-2"
                                                style={{ color: likeColor }}></i>
                                            <label
                                                className="fontTextArea"
                                                style={{ color: likeColor }}>{option.text}</label>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-12 p-0" key={option.id}
                                    onClick={() => {
                                        this.onSelectedOption(option, false)
                                    }}>
                                    <div className="btn col-12 p-4"
                                        style={{ backgroundColor: dislikeBackgroundColor }}>
                                        <div className="row justify-content-start align-items-center">
                                            <i className="fas fa-thumbs-down optionLikeIcon pr-2"
                                                style={{ color: dislikeColor }}></i>
                                            {/* <label className="fontTextArea text-danger" >{option.text}</label> */}
                                            <div
                                                className="fontTextArea"
                                                style={{ color: dislikeColor }}>
                                                {(this.state.questionData.optionDecorationValue === "None") ? (
                                                    <div>{option.text}</div>
                                                ) : (
                                                    <li type={optionOrderType} id={option.id}>{option.text}</li>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                } else if (this.state.questionData.type === "MultipleChoice") {
                    return (
                        <div key={option.id}>
                            <div className="container-option" style={{ backgroundColor: backgroundOption }}
                                onClick={() => {
                                    this.onSelectedOption(option)
                                }}>
                                {/* <div style={{ color: textColorOption }}>{optionDecoration}</div> */}
                                <div style={{ color: textColorOption }}>
                                    {(this.state.questionData.optionDecorationValue === "None") ? (
                                        <div>{option.text}</div>
                                    ) : (
                                        <li type={optionOrderType} id={option.id}>{option.text}</li>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        //If the question option lets the participant to select multiple choice it will show the multiple choice to select othersise it will be
                        //radio button to let select only one
                        <div key={option.id}>
                            <div className="container-option" style={{ backgroundColor: backgroundOption }}
                                onClick={() => {
                                    this.onSelectedOption(option)
                                }}>
                                {/* <div style={{ color: textColorOption }}>{optionDecoration}</div> */}
                                <div style={{ color: textColorOption }}>
                                    {(this.state.questionData.optionDecorationValue === "None") ? (
                                        <div>{option.text}</div>
                                    ) : (
                                        <li type={optionOrderType} id={option.id}>{option.text}</li>
                                    )}
                                </div>
                            </div>
                        </div>

                    )
                }
            });
        }

        var questionComponent = (<div></div>);
        var orderedOptionsListcomponent;
        if (this.state.questionData.optionDecorationValue === "Alphabet" || this.state.questionData.optionDecorationValue === "Number") {
            orderedOptionsListcomponent = <ol className="questionOptionsList">
                {optionsListComponents}
            </ol>
        } else if (this.state.questionData.optionDecorationValue === "Bullet") {
            orderedOptionsListcomponent = <ul className="questionOptionsList">
                {optionsListComponents}
            </ul>
        } else {
            orderedOptionsListcomponent = <div className="w-100">
                {optionsListComponents}
            </div>
        }

        if (this.state.questionData !== "default" && this.state.questionData.type === "MultipleChoice") {
            questionComponent = (<div>
                {(this.state.voted === 'voted' + this.state.parentInfo.currentLiveSession.id) ?
                    (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                                <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body black-text" id="cardOptionContent">
                                <div className="row">
                                    <div className="col-12 text-center"
                                        style={{ color: this.state.event.primaryColor }}>
                                        <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {(optionsListComponents.length === 0) ? (
                                <div className="row">
                                    <div className="col-12 text-center spinOption">
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            ) : (
                                <div className="card questionCard" id="card-box">
                                    <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                                        <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                            {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                        </h4>
                                    </div>
                                    <div className="card-body-question black-text">
                                        {/* <p className="card-title questionText">{this.state.questionData.text}</p> */}
                                        <h4 className="card-title questionText"
                                            style={{ color: this.state.event.primaryColor }}>
                                            {this.state.questionData.text}
                                        </h4>
                                        <div className="card-text" id="cardOptionContent">
                                            {/* {optionsListComponents} */}
                                            {orderedOptionsListcomponent}
                                        </div>

                                        <div className="card-footer bg-white text-center ">
                                            <button className={"btn fontTextArea " + this.state.sendVoteStatus}
                                                style={{
                                                    backgroundColor: this.state.event.primaryColor,
                                                    color: this.state.event.textColor
                                                }} onClick={this.sendVote}>
                                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                            </button>
                                        </div>
                                        <div className="col-10">
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
            </div>
            )
        } else if (this.state.questionData !== "default" && this.state.questionData.type === "SimpleTextAndMultipleChoice") {
            questionComponent = (<div>
                {(this.state.voted === 'voted' + this.state.parentInfo.currentLiveSession.id) ? (
                    <div className="card questionCard" id="card-box">
                        <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                            <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                            </h4>
                        </div>
                        <div className="card-body black-text" id="cardOptionContent">
                            <div className="row">
                                <div className="col-12 text-center"
                                    style={{ color: this.state.event.primaryColor }}>
                                    <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        {(optionsListComponents.length === 0) ? (
                            <div className="row">
                                <div className="col-12 text-center spinOption">
                                    <i className="fa fa-spinner fa-spin"></i>
                                </div>
                            </div>
                        ) : (
                            <div className="card questionCard" id="card-box">
                                <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                                    <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                        {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                    </h4>
                                </div>
                                <div className="card-body-question black-text">
                                    {/* <p className="card-title questionText">{this.state.questionData.text}</p> */}
                                    <h4 className="card-title questionText"
                                        style={{ color: this.state.event.primaryColor }}>
                                        {this.state.questionData.text}
                                    </h4>
                                    {(this.state.questionData.layout == "like-dislike") ? (
                                        <div>
                                            <div className="row m-2 ml-3">
                                                {/* {optionsListComponents} */}
                                                {orderedOptionsListcomponent}
                                            </div>
                                            <div className="form-group commentTextAreaField">
                                                <textarea className="form-control fontTextArea "
                                                    id="commentTextArea" rows="3"
                                                    onChange={this.onFillingComment}></textarea>
                                            </div>

                                        </div>
                                    ) : (
                                        <div>
                                            <p className="card-text" id="cardOptionContent">
                                                {/* {optionsListComponents} */}
                                                {orderedOptionsListcomponent}
                                            </p>
                                            <div className="form-group commentTextAreaField">
                                                {/* <label htmlFor="commentTextArea">{selectedLanguageStrings.reason_comment}</label> */}
                                                <textarea className="form-control fontTextArea "
                                                    id="commentTextArea" rows="3"
                                                    onChange={this.onFillingComment}></textarea>
                                            </div>
                                        </div>
                                    )}

                                    <div className="card-footer bg-white text-center ">
                                        <button className={"btn fontTextArea " + this.state.sendVoteStatus} style={{
                                            backgroundColor: this.state.event.primaryColor,
                                            color: this.state.event.textColor
                                        }} onClick={this.sendVoteAndComment}>
                                            {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                        </button>
                                    </div>
                                    <div className="col-10">
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            )
        } else if (this.state.questionData !== "default" && this.state.questionData.type === "SimpleTextQuestion") {
            questionComponent = (
                <div>
                    {(this.state.voted === 'voted' + this.state.parentInfo.currentLiveSession.id) ? (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                                <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body black-text" id="cardOptionContent">
                                <div className="row">
                                    <div className="col-12 text-center" style={{ color: this.state.event.primaryColor }}>
                                        <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                                <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body-question black-text">
                                <form>
                                    {/* <h5><p className="card-title questionText">{this.state.questionData.text}</p></h5> */}
                                    <div className="card-title questionText fontTextArea"
                                        style={{ color: this.state.event.primaryColor }}>
                                        {this.state.questionData.text}
                                    </div>
                                    <div className="form-group ml-2 mr-2">
                                        <label htmlFor="answerTextArea" style={{ color: this.state.event.primaryColor }}>
                                            <h4>{selectedLanguageStrings.your_answer} : </h4></label>
                                        <textarea className="form-control fontTextArea" id="answerTextArea" rows="2"
                                            name="answerTextArea" onChange={this.handleEventChange}></textarea>
                                    </div>
                                </form>
                            </div>
                            <div className="card-footer bg-white text-center fontTextArea">
                                <button className={"btn fontTextArea " + this.state.sendAnswerStatus} style={{
                                    backgroundColor: this.state.event.primaryColor,
                                    color: this.state.event.textColor
                                }} onClick={this.sendAnswer}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else if (this.state.questionData !== "default" && this.state.questionData.type === "RatingQuestion") {
            questionComponent = (
                <div>
                    {(this.state.voted === 'voted' + this.state.parentInfo.currentLiveSession.id) ? (
                        <div className="card questionCard" id="card-box">
                            <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                                <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body black-text" id="cardOptionContent">
                                <div className="row">
                                    <div className="col-12 text-center" style={{ color: this.state.event.primaryColor }}>
                                        <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card" id="card-box">
                            <div className="card-header" style={{ backgroundColor: this.state.event.primaryColor }}>
                                <h4 className="questionHeader" style={{ color: this.state.event.textColor }}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_poll : selectedLanguageStrings.live_question}
                                </h4>
                            </div>
                            <div className="card-body-question black-text">
                                <div className="card-title questionText fontTextArea"
                                    style={{ color: this.state.event.primaryColor }}>
                                    {this.state.questionData.text}
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        {(this.state.questionData.numberOfStars < 6) ? (
                                            <StarRatingComponent
                                                name="rate1"
                                                starCount={parseInt(this.state.questionData.numberOfStars)}
                                                value={this.state.rating}
                                                renderStarIcon={() => <span><i
                                                    className="fas fa-star starIconRating"></i></span>}
                                                onStarClick={this.onStarClick.bind(this)}
                                                emptyStarColor={"grey"}
                                            // starColor={this.state.event.primaryColor }
                                            />
                                        ) : (
                                            <StarRatingComponent
                                                name="rate1"
                                                starCount={parseInt(this.state.questionData.numberOfStars)}
                                                value={this.state.rating}
                                                renderStarIcon={() => <span><i
                                                    className="fas fa-star starIconRating2"></i></span>}
                                                onStarClick={this.onStarClick.bind(this)}
                                                emptyStarColor={"grey"}
                                            // starColor={this.state.event.primaryColor }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-center fontTextArea">
                                <button className={"btn fontTextArea " + this.state.sendVoteStatus} style={{
                                    backgroundColor: this.state.event.primaryColor,
                                    color: this.state.event.textColor
                                }} onClick={this.sendRatingAnswer}>
                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.send_answer : selectedLanguageStrings.send_answer}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )
        }

        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    goToAgendaPage={this.goToAgendaPage.bind(this)}
                    goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    goToDashboardPage={this.goToDashboardPage.bind(this)}
                    goToQuestionPage={this.goToQuestionPage.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    pageName={"PollScreen"}
                    rootChildrenList={this.state.rootChildrenList}
                    surveyId={this.state.surveyId}
                />
            </div>);

            if (this.state.eventType === 'questions') {
                borderType = 'border-bottom-question';
            } else if (this.state.eventType === 'tweets') {
                borderType = 'border-bottom-tweet';
            } else {
                borderType = "";
            }
        }

        let containerscreenclass = "";
        let backgroundimagestyle = "";
        if (this.state.event.themeParticipantImageUrl === "default") {
            containerscreenclass = "container-screen-default";
            backgroundimagestyle = "";
        } else {
            containerscreenclass = "container-screen";
            if (this.state.event.themeParticipantImageUrl == undefined) {
                backgroundimagestyle = "";
            }
            else {
                backgroundimagestyle = `url(" ${this.state.event.themeParticipantImageUrl} ")`;
            }
        }

        return (
            <main>
                <div>
                    <div>
                        {headerComponent}
                    </div>
                    <div>
                        <div className="imageTheme" style={{ backgroundImage: backgroundimagestyle }}>
                            <div className={`container ${containerscreenclass}`}>
                                <div className="row pt-4">
                                    {(this.state.parentInfo.roomIconColor && this.state.roomsList.length > 1) ? (
                                        <div className="col-12 text-success text-center">
                                            <h4 style={{ color: this.state.parentInfo.roomIconColor }}>
                                                {/* <i className="fas fa-users menuIcons pr-3" style={{color: this.state.parentInfo.roomIconColor }}></i> */}
                                                <i className="fas fa-door-open menuIcons pr-3"
                                                    style={{ color: this.state.parentInfo.roomIconColor }}></i>
                                                <b>{this.state.parentInfo.title}</b>
                                            </h4>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12" id="optionsCard">
                                                {(this.state.parentInfo.currentLiveSession && this.state.parentInfo.currentLiveSession.status === "live") ? (
                                                    <div>
                                                        {questionComponent}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto poolWaitingContainer">
                                                                <img className="poolWaitingImg" src="/files/waiting.png"></img>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="showAnswerModalBtn" className="bg-success" data-toggle="modal"
                        data-target="#showAnswerModal">
                    </div>
                    {(this.state.showAnswerAlert === true) ? (
                        <div className="modal fade no-border" id="showAnswerModal" tabIndex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content p-3 ">
                                    <div className="row align-items-center">
                                        <div className="col-10 alertAnswer">
                                            Before navigating to another page please answer the live question.
                                        </div>
                                        <div className="col-2 text-right">
                                            {/* <button type="button" className="btn alertAnswer pt-0 pb-0 pl-1 pr-1" data-dismiss="modal"
                                            style={{ backgroundColor: this.props.changedEventInfo.primaryColor, color: this.props.changedEventInfo.textColor }}>
                                                Ok 
                                            </button> */}
                                            <button type="button" className=" alertAnswer pt-0 pb-0 pl-1 pr-1"
                                                data-dismiss="modal">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </main>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onGetQuestion: (eventId, elementId, questionId, callback) => dispatch(questionActions.getQuestion(eventId, elementId, questionId, callback)),
        ongetLiveSessionOptions: (eventId, elementId, questionId, liveSessionId, callback) => dispatch(questionActions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback)),
        onSendVote: (type, eventId, elementId, questionId, sessionId, votesList, participantsId) => dispatch(generalActions.sendVote(type, eventId, elementId, questionId, sessionId, votesList, participantsId)),
        onSendAnswerText: (eventId, elementId, questionId, sessionId, participantReply, participantName, participantsId) => dispatch(generalActions.sendAnswerText(eventId, elementId, questionId, sessionId, participantReply, participantName, participantsId)),
        onSendRatingAnswer: (eventId, elementId, questionId, sessionId, participantRating, participantName, participantsId) => dispatch(generalActions.sendRatingAnswer(eventId, elementId, questionId, sessionId, participantRating, participantName, participantsId)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onSettingStatus: (status, callback) => dispatch(generalActions.settingStatus(status, callback)),
        onGetEventData: (eventCode, callback) => dispatch(generalActions.getEventData(eventCode, callback)),
        onGetSurveyFilesData: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFilesData(eventId, surveyId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback))
    };
}

const mapStateToProps = state => {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PollScreen);