import { tweetFunctions } from '../../FirebaseFunctions/TweetFunctions';
import{actionTypes} from '../Actions/actionTypes';
export const GET_ALL_TWEETS = "GET_ALL_TWEETS";

export const tweetsActions = {
    getAllTweets,
    getparticipantTweets,
    saveNewTweet,
};

function getAllTweets(eventId, tweetElId, option, callback){
    return dispatch => {
        tweetFunctions.getAllTweets(eventId, tweetElId, option,
                (resultList, unsubscribe) => {
                    //callback(resultList);
                    dispatch(success(resultList));
                    callback(resultList, unsubscribe);
                    
                },
                error => {
                 //   dispatch(failure(error));
                }
            );
    };

    function success(allTweetsList) { return { type: actionTypes.GET_ALL_TWEETS_SUCCESS, allTweetsList } }
}

function getparticipantTweets(participantId, eventId, tweetIdEl, callback){
    return dispatch => {
        tweetFunctions.getparticipantTweets(participantId, eventId, tweetIdEl, 
            (participantTweetsList, unsubscribe) => {
                    dispatch(success(participantTweetsList));
                    callback(participantTweetsList, unsubscribe);
                },
                error => {
                 //   dispatch(failure(error));
                }
            );
    };

    function success(participantTweetsList) { return { type: actionTypes.GET_ALL_PARTICIPANT_TWEETS_SUCCESS, participantTweetsList } }
}

function saveNewTweet(participantId, eventId, idTweetEl, tweetInputText, tweetInputName){
    return dispatch => {
        tweetFunctions.saveNewTweet(participantId, eventId, idTweetEl, tweetInputText, tweetInputName).then(
                result => {
                    dispatch(success());
                },
                error => {
                 //   dispatch(failure(error));
                }
            );
    };

    function success() { return { type: actionTypes.SAVE_NEW_TWEET_SUCCESS} }
}

