import { actionTypes } from './actionTypes';
import { questionFunctions } from '../../FirebaseFunctions/QuestionFunctions';

export const questionActions = {
    getLiveSessionOptions,
    getQuestion,

}

function getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback) {
    return dispatch => {

        questionFunctions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId).then(
            querySnapShot => {
                let optionsList = [];
                querySnapShot.forEach(document => {
                    let option = {
                        id: document.id,
                        text: document.data().text,
                        optionsOrderIndex: document.data().optionsOrderIndex
                    };
                    if(document.data().type){
                        option.type =  document.data().type;
                    }
                    // optionsList.push(option);
                     // we put the option in the same order(index) as the user has been defined and has been stored in the firebase
                     optionsList[document.data().optionsOrderIndex] = option;
                    
                });

                dispatch(success(optionsList));
                callback(optionsList);

            },
            error => {
                //dispatch(failure(error))
            }
        );
    };

    function success(questionOptions) { return { type: actionTypes.Fetch_ALL_QUESTION_OPTIONS_SUCCESS, questionOptions } }
}

function getQuestion(eventId, elementId, questionId, callback) {

    return dispatch => {
        questionFunctions.getQuestion(eventId, elementId, questionId).then(
            querySnapShot => {
                if(querySnapShot.data()){
                    let questionData = querySnapShot.data();
                    questionData.id = querySnapShot.id;
                // let questionData = {
                //     id: querySnapShot.id,
                //     text: querySnapShot.data().text,
                //     type: querySnapShot.data().type,
                //     letMultipleSelect: querySnapShot.data().letMultipleSelect,
                //     simpleTextAnswerMandatory: querySnapShot.data().simpleTextAnswerMandatory,
                //     layout: querySnapShot.data().layout,
                //     optionDecorationValue: querySnapShot.data().optionDecorationValue,
                //     // numberOfStars: querySnapShot.data().numberOfStars
                // };
            

                dispatch(success(questionData));
                callback(questionData);
            }else{
                let questionData = {
                    id: querySnapShot.id,
                    text: 'No Data',
                    type: 'No Data',
                    letMultipleSelect: false,
                };
                dispatch(success(questionData));
                callback(questionData);
            }
            },
            error => {
                //dispatch(failure(error))
            }
        );
    };

    function success(questionData) { return { type: actionTypes.Fetch_QUESTION_DATA_SUCCESS, questionData } }
}


