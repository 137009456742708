import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generalActions } from '../Redux/Actions/generalActions';
import Header from '../Component/Header';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import Utility from '../Helpers/Utility';
import Flag from 'react-world-flags';
import Sidebar from "react-sidebar";
import moment from 'moment';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';



class LibraryScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            libraryId: this.props.match.params.idItem,
            libraryName: "",
            libraryInfo: "default",
            filesList: "default",
            sidebarOpen: false,
            event: 'default',
            parentInfo: 'default',
            participantInfo: 'default',
            roomsList: [],
            rootChildrenList: [],
            userStatus: 'initialized'
        };
        this.goToFileViewer = this.goToFileViewer.bind(this);
        this.goToSurveyPageModal = this.goToSurveyPageModal.bind(this);
        this.goToQuestionPage = this.goToQuestionPage.bind(this);
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (!participant) {
            this.props.history.push("/");
        } else {
            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (cb_eventinfo, unsubscribe) => {
                this.unsubscribeEventInfoChange = unsubscribe;

                if (cb_eventinfo.data.eventStatus === "Archived") {
                    window.location.href = "/";
                }

                this.setState({
                    ...this.state,
                    participantInfo: participant,
                    event: cb_eventinfo.data,
                });

                if (!cb_eventinfo.data.availableLanguages.lang_it || !cb_eventinfo.data.availableLanguages.lang_en) {
                    if (cb_eventinfo.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (cb_eventinfo.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
                }                
            });                        
            
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.roomId, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            if (this.unsubscribeGetLibraryInfoOnChange) {
                this.unsubscribeGetLibraryInfoOnChange();
            }
            this.props.onGetLibraryInfoOnChange(this.state.eventId, this.state.libraryId, (libraryInfo, unsubscribe) => {
                this.unsubscribeGetLibraryInfoOnChange = unsubscribe;
                if (this.unsubscribeGetLibraryFiles) {
                    this.unsubscribeGetLibraryFiles();
                }
                this.props.onGetLibraryFiles(this.state.eventId, this.state.libraryId, (filesList, unsubscribe) => {
                    this.unsubscribeGetLibraryFiles = unsubscribe;
                        let userStoredLanguage = Utility.getEventLanguage();
                    this.setState({ ...this.state, filesList: filesList, libraryInfo: libraryInfo, userStoredLanguage: userStoredLanguage });
                });
            });

            this.setState({
                ...this.state,
                parentInfo: parentInfo.data,
            });

            var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
            var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));

                var userAlreadyVoted = false;
            if (parentInfo.message === 'success') {
                if (parentInfo.data.currentLiveSession.status === "live") {

                    //we only go to the tweet question if the user did not already voted for the live question
                        if (userAlreadyVoted === false && storedVotes && storedVotes.includes(parentInfo.data.currentLiveSession.id)) {
                            userAlreadyVoted = true;
                    }

                        if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(parentInfo.data.currentLiveSession.id)) {
                            userAlreadyVoted = true;
                    }

                        if (!this.props.changedEventInfo || this.props.changedEventInfo.automaticJumpPoll === "Active") {
                            if (userAlreadyVoted === false) {
                                this.goToPollPage();
                            }
                    }
                }
            }
        });

        if (this.unsubscribeGetLibraryInfoOnChange) {
            this.unsubscribeGetLibraryInfoOnChange();
        }
        this.props.onGetLibraryInfoOnChange(this.state.eventId, this.state.libraryId, (libraryInfo, unsubscribe) => {
            this.unsubscribeGetLibraryInfoOnChange = unsubscribe;
            this.setState({ ...this.state, libraryName: libraryInfo });
        });

        this.setState({ ...this.state, participantInfo: participant, });

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }
        this.props.onGetEventRoomsOnChange(this.state.eventId, (roomsList, unsubscribe) => {
            this.unsubscribeGetEventRoomsOnChange = unsubscribe;
            if (roomsList.length > 0) {
                this.setState({
                    ...this.state,
                    roomsList: roomsList
                });
            }
        });

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }        
        this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (rootChildrenList, unsubscribe) => {
            this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
            let orederdRootChildrenList = [];
            let orderedRoomsChildrenList = [];
            let allElementsList = [];
            for (var i = 0; i < rootChildrenList.length; i++) {
                if (rootChildrenList[i].sParentId === "default") {
                    orederdRootChildrenList.push(rootChildrenList[i]);
                } else if (rootChildrenList[i].sParentId === this.state.roomId) {
                    orderedRoomsChildrenList.push(rootChildrenList[i]);
                }
            }

            orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);
                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {

                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }
                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;
    
                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                }
                            });
    
                        });
    
                    }
                });
            this.setState({ ...this.state, rootChildrenList: allElementsList });
        });


            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
        }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);
        }
    }

    componentDidUpdate(prevProps) {

        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        }
        else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                }
                else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeGetLibraryInfoOnChange) {
            this.unsubscribeGetLibraryInfoOnChange();
        }

        if (this.unsubscribeGetLibraryFiles) {
            this.unsubscribeGetLibraryFiles();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
    }
        
    goToRootChild(item, type) {

        var fileExists = true;
        if (type === "agenda") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
        } else if (type === "qrcode") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
        } else if (type === "library") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
            this.setState({ ...this.state, libraryId: item.id });
            let userStoredLanguage = Utility.getEventLanguage();

            if (this.unsubscribeGetLibraryInfoOnChange) {
                this.unsubscribeGetLibraryInfoOnChange();
            }
            this.props.onGetLibraryInfoOnChange(this.state.eventId, this.state.libraryId, (libraryInfo, unsubscribe) => {
                this.unsubscribeGetLibraryInfoOnChange = unsubscribe;
                if (this.unsubscribeGetLibraryFiles) {
                    this.unsubscribeGetLibraryFiles();
                }
                this.props.onGetLibraryFiles(this.state.eventId, this.state.libraryId, (filesList, unsubscribe) => {
                    this.unsubscribeGetLibraryFiles = unsubscribe;
                    this.setState({ ...this.state, filesList: filesList, libraryInfo: libraryInfo, userStoredLanguage: userStoredLanguage });
                });
            });
        }
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToDashboardPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId);
    }

    goToPollPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId + "/poll");
    }

    goRoomPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goToAgendaPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda")
    }

    goToQuestionPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goToSurveyPageModal(survey) {

        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }

        if (survey.startInIframe) {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + survey.id);
        }
        else {
            this.props.onGetSurveyFilesData(this.props.changedEventInfo.id, survey.id, (surveyfiles) => {
                window.open(surveyfiles[0].fileUrl, "_blank");
            });
        }
    }

    onSelectLanguage(option) {

        if (option === 'English') {
            var value = 'English';
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        } else {
            var value = 'Italian';
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        }
    }

    goToFileViewer(file) {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + this.state.libraryId + "/show/" + file.id + "/internal/");
    }

    render() {
        var selectedLanguageStrings;
        let userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        var libraryTitle = '';
        if (this.state.libraryInfo !== 'default') {
            libraryTitle = this.state.libraryInfo.title;
        }

        var fileTypeComponent;
        let imageTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        var filesListcomponent
        if (this.props.libraryFilesList && this.props.libraryFilesList.length > 0) {
            filesListcomponent = this.props.libraryFilesList.map((file, index) => {
                if (imageTypes.includes(file.type)) {
                    fileTypeComponent = <i className="fas fa-file-image text-success"></i>
                } else if (file.type === "application/pdf") {
                    fileTypeComponent = <i className="fas fa-file-pdf text-danger"></i>
                } else if (file.type === "video/mp4" || file.type === "video/flv") {
                    fileTypeComponent = <i className="fas fa-file-video text-warning"></i>
                } else if (file.type === "text/plain") {
                    fileTypeComponent = <i className="fas fa-file-alt text-info"></i>
                } else {
                    fileTypeComponent = <i className="fas fa-file-download text-secondary"></i>
                }

                return (
                    <div className="row mb-2 align-items-center" key={index}>
                        <div className="col-2 pr-0 col-sm-4 col-md-1 col-lg-1  text-center card-body-files-icons">
                            {fileTypeComponent}
                        </div>
                        <div className="col-10 col-sm-8 col-md-11 col-lg-11 pl-0">
                            <div className="card ">
                                {/* <a href={file.fileUrl} target="_blank"> */}
                                <div className="card-body-files" onClick={() => { this.goToFileViewer(file) }}>
                                    {file.name}
                                </div>
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            filesListcomponent = (
                <div className="card ">
                    <div className="card-body text-center">
                        <p className="fontTextArea text-secondary">{selectedLanguageStrings.no_materials}</p>
                    </div>
                </div>
            )
        }

        var headerComponent;
        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    goToDashboardPage={this.goToDashboardPage.bind(this)}
                    goToQuestionPage={this.goToQuestionPage.bind(this)}
                    goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToAgendaPage={this.goToAgendaPage.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    pageName={"LibraryScreen"}
                    libraryId={this.state.libraryId}
                    rootChildrenList={this.state.rootChildrenList}
                />

            </div>);
        }
        var containerContentComponent = (
            <div>
                <div className="row pt-2 pb-4">
                    <div className="col-12 text-center ">
                        <h3 style={{ color: this.props.changedEventInfo.textColor }}>{libraryTitle}</h3>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12">
                        {filesListcomponent}
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                <div>
                    {headerComponent}
                </div>
                {(this.state.filesList === "default") ? (
                    //we show a spinner untile fetch all the event's data from the firebase
                    <div className="loaderContainer center spinOption">
                        <i className="fa fa-spinner fa-spin" ></i>
                    </div>
                ) : (
                    <div>
                        <main role="main">
                            {(this.state.event.themeParticipantImageUrl === "default") ? (
                                <div className="container container-screen-default" style={{ paddingTop: "80px" }}>
                                    {containerContentComponent}
                                </div>
                            ) : (
                                <div className="imageTheme" style={{ backgroundImage: "url(" + this.state.event.themeParticipantImageUrl + ")" }} >
                                    <div className="container container-screen" style={{ paddingTop: "80px" }}>
                                        {containerContentComponent}
                                    </div>
                                </div>
                            )}
                        </main>
                    </div>
                )}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetLibraryFiles: (eventId, libraryId, callback) => dispatch(generalActions.getLibraryFiles(eventId, libraryId, callback)),
        onGetLibraryInfoOnChange: (eventId, libraryId, callback) => dispatch(generalActions.getLibraryInfoOnChange(eventId, libraryId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onGetSurveyFilesData: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFilesData(eventId, surveyId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback))
    };
};
const mapStateToProps = state => {

    return {
        libraryFilesList: state.generalReducers.libraryFilesList,
        parentInfo: state.generalReducers.parentInfo,
        changedEventInfo: state.generalReducers.changedEventInfo,
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryScreen);