import React, { Component, useEffect } from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';
import Header from '../Component/Header';
import Tweet from '../Component/Tweet';
import Utility from '../Helpers/Utility';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import { tweetsActions } from '../Redux/Actions/tweetsActions';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';


class QuestionScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            event: '',
            parentInfo: 'default',
            participantInfo: '',
            rootChildrenList: [],
            roomsList: [],
            allTweetsList: [],
            participantTweetsList: [],
            userStatus: 'initialized'
        };

        this.closeModal = this.closeModal.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onToggleOrder = this.onToggleOrder.bind(this)
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));

        if (!participant) {
            this.props.history.push("/");
        } else {
            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (cb_eventinfo, unsubscribe) => {
                this.unsubscribeEventInfoChange = unsubscribe;

                this.setState({
                    ...this.state,
                    participantInfo: participant,
                    event: cb_eventinfo.data
                });

                if (!cb_eventinfo.data.availableLanguages.lang_it || !cb_eventinfo.data.availableLanguages.lang_en) {
                    if (cb_eventinfo.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (cb_eventinfo.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
                }                 
            });

            if (this.unsubscribeGetListOfRootChildrenOnChange) {
                this.unsubscribeGetListOfRootChildrenOnChange();
            }
            this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (cb_rootchildrenlist, unsubscribe) => {
                this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
                let orederdRootChildrenList = [];
                let orderedRoomsChildrenList = [];
                let allElementsList = [];
                for (var i = 0; i < cb_rootchildrenlist.length; i++) {
                    if (cb_rootchildrenlist[i].sParentId === "default") {
                        orederdRootChildrenList.push(cb_rootchildrenlist[i]);
                    } else if (cb_rootchildrenlist[i].sParentId === this.state.roomId) {
                        orderedRoomsChildrenList.push(cb_rootchildrenlist[i]);
                    }
                }

                orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
                orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
                allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);
                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {
    
                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }
                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;

                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                }
                            });
    
                        });
    
                    }
                });
                this.setState({
                    ...this.state,
                    rootChildrenList: allElementsList,
                });

            });

            if (this.unsubscribeGetEventRoomsOnChange) {
                this.unsubscribeGetEventRoomsOnChange();
            }
            this.props.onGetEventRoomsOnChange(this.state.eventId, (cb_roomslist, unsubscribe) => {
                this.unsubscribeGetEventRoomsOnChange = unsubscribe;

                //Check if the current room exists, otherwise redirect to the rooms page.   
                let roomExists = false;
                if (cb_roomslist.length > 0) {
                    for (var i = 0; i < cb_roomslist.length; i++) {
                        if (cb_roomslist[i].id === this.state.roomId) {
                            roomExists = true;
                            break;
                        }
                    }
                }
                if (roomExists === true) {
                    this.setState({
                        ...this.state,
                        roomsList: cb_roomslist,
                    });

                } else {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
                }
            });

            if (this.unsubscribeGetParentInfoOnChange) {
                this.unsubscribeGetParentInfoOnChange();
            }
            this.props.onGetParentInfoOnChange(this.state.eventId, this.state.roomId, (cb_parentInfo, unsubscribe) => {
                this.unsubscribeGetParentInfoOnChange = unsubscribe;
                this.setState({
                    ...this.state,
                    parentInfo: cb_parentInfo.data
                });

                
                if (cb_parentInfo.message === 'success') {


                    if (this.props.changedEventInfo.showTweetsToParticipants === "Yes") {
                        if (this.props.changedEventInfo.autoApproveTweets === "Without Moderator") {
                            if (this.unsubscribeGetAllTweets) {
                                this.unsubscribeGetAllTweets();
                            }
                            this.props.onGetAllTweets(this.props.changedEventInfo.id, cb_parentInfo.data.currentTweetElement, "Without Moderator", (cb_allTweets, unsubscribe) => {
                                this.unsubscribeGetAllTweets = unsubscribe;
                                this.setState({ ...this.state, allTweetsList: cb_allTweets });
                            });

                            if (this.unsubscribeGetParticipantTweets) {
                                this.unsubscribeGetParticipantTweets();
                            }
                            this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, cb_parentInfo.data.currentTweetElement, (cb_participantTweets, unsubscribe) => {
                                this.unsubscribeGetParticipantTweets = unsubscribe;
                                this.setState({ ...this.state, participantTweetsList: cb_participantTweets });
                            });
                        } else {
                            if (this.unsubscribeGetAllTweets) {
                                this.unsubscribeGetAllTweets();
                            }
                            this.props.onGetAllTweets(this.props.changedEventInfo.id, cb_parentInfo.data.currentTweetElement, this.props.changedEventInfo.tweetsToShow, (cb_allTweets, unsubscribe) => {
                                this.unsubscribeGetAllTweets = unsubscribe;
                                this.setState({ ...this.state, allTweetsList: cb_allTweets });
                            });

                            if (this.unsubscribeGetParticipantTweets) {
                                this.unsubscribeGetParticipantTweets();
                            }
                            this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, cb_parentInfo.data.currentTweetElement, (cb_participantTweets, unsubscribe) => {
                                this.unsubscribeGetParticipantTweets = unsubscribe;
                                this.setState({ ...this.state, participantTweetsList: cb_participantTweets });
                            });
                        }
                    } else {
                        if (this.unsubscribeGetParticipantTweets) {
                            this.unsubscribeGetParticipantTweets();
                        }
                        this.props.onGetparticipantTweets(participant.id, this.props.changedEventInfo.id, cb_parentInfo.data.currentTweetElement, (cb_participantTweets, unsubscribe) => {
                            this.unsubscribeGetParticipantTweets = unsubscribe;
                            this.setState({ ...this.state, participantTweetsList: cb_participantTweets });
                        });
                    }

                }


            });


            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
                    }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        } else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                } else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
        
        if (this.state.parentInfo !== "default") {
            if (this.state.parentInfo.currentLiveSession.status === "live") {
                        
                let storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                let storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
    
                var userAlreadyVoted = false;
                //we only go to the tweet question if the user did not already voted for the live question
                if (userAlreadyVoted === false && storedVotes && storedVotes.includes(this.state.parentInfo.currentLiveSession.id)) {
                    userAlreadyVoted = true;
                }
    
                if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(this.state.parentInfo.currentLiveSession.id)) {
                    userAlreadyVoted = true;
                }
    
                if (!this.props.changedEventInfo || this.props.changedEventInfo.automaticJumpPoll ==="Active") {
                    if (userAlreadyVoted === false) {
                        this.goToPollPage();
                    }
                }
            }        

            if ((prevProps.changedEventInfo.showTweetsToParticipants != this.props.changedEventInfo.showTweetsToParticipants) ||
                (prevProps.changedEventInfo.autoApproveTweets != this.props.changedEventInfo.autoApproveTweets) ||
                (prevProps.changedEventInfo.tweetsToShow != this.props.changedEventInfo.tweetsToShow)
            ) {
                if (this.props.changedEventInfo.showTweetsToParticipants === "Yes") {
                    if (this.props.changedEventInfo.autoApproveTweets === "Without Moderator") {
                        if (this.unsubscribeGetAllTweets) {
                            this.unsubscribeGetAllTweets();
                        }
                        this.props.onGetAllTweets(this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, "Without Moderator", (cb_allTweets, unsubscribe) => {
                            this.unsubscribeGetAllTweets = unsubscribe;
                            this.setState({ ...this.state, allTweetsList: cb_allTweets });
                        });

                        if (this.unsubscribeGetParticipantTweets) {
                            this.unsubscribeGetParticipantTweets();
                        }
                        this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, (cb_participantTweets, unsubscribe) => {
                            this.unsubscribeGetParticipantTweets = unsubscribe;
                            this.setState({ ...this.state, participantTweetsList: cb_participantTweets });
                        });
                    } else {
                        if (this.unsubscribeGetAllTweets) {
                            this.unsubscribeGetAllTweets();
                        }
                        this.props.onGetAllTweets(this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, this.props.changedEventInfo.tweetsToShow, (cb_allTweets, unsubscribe) => {
                            this.unsubscribeGetAllTweets = unsubscribe;
                            this.setState({ ...this.state, allTweetsList: cb_allTweets });
                        });

                        if (this.unsubscribeGetParticipantTweets) {
                            this.unsubscribeGetParticipantTweets();
                        }
                        this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, (cb_participantTweets, unsubscribe) => {
                            this.unsubscribeGetParticipantTweets = unsubscribe;
                            this.setState({ ...this.state, participantTweetsList: cb_participantTweets });
                        });
                    }
                } else {
                    if (this.unsubscribeGetParticipantTweets) {
                        this.unsubscribeGetParticipantTweets();
                    }
                    this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, (cb_participantTweets, unsubscribe) => {
                        this.unsubscribeGetParticipantTweets = unsubscribe;
                        this.setState({ ...this.state, participantTweetsList: cb_participantTweets });
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        this.closeModal();
        if (document.querySelectorAll(".modal-backdrop")[0]) {
            document.querySelectorAll(".modal-backdrop")[0].remove();
        }
    }

        
    onSelectLanguage(option) {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (option === 'English') {
            var value = 'English';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        } else {
            var value = 'Italian';
            this.props.onUpdateParticipantData(participant, participant.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        }
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goToDashboardPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId);
    }

    goToPollPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId + "/poll");
    }    

    goRoomPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToAgendaPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda")
    }

    goToSurveyPageModal(survey) {
        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }
        
        if (survey.startInIframe) {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + survey.id);
        }
        else {
            this.props.onGetSurveyFilesData(this.props.changedEventInfo.id, survey.id, (surveyfiles) => {
                window.open(surveyfiles[0].fileUrl, "_blank");
            });
        }

    }

    goToRootChild(item, type) {
        if (type === "agenda") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
        } else if (type === "qrcode") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
        } else if (type === "library") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
        }
    }

    sendTweet() {
        var tweetInputText = document.getElementById("tweetInputText").value;
        var tweetInputName = document.getElementById("tweetInputName").value;
        var anonymoudstatusValue = document.getElementById("optionAnonymous").checked;
        if (tweetInputText !== "") {
            if (anonymoudstatusValue === true || tweetInputName === "") {
                //if the user checked the annonymoud status while sending the tweet
                this.props.onSaveNewTweet(this.state.participantInfo.id, this.state.event.id, this.state.parentInfo.currentTweetElement, tweetInputText, "");
            } else if (tweetInputName !== "" || tweetInputName !== this.state.participantName) {
                //we also update the participant's data if the name is provided
                this.props.onSaveNewTweet(this.state.participantInfo.id, this.state.event.id, this.state.parentInfo.currentTweetElement, tweetInputText, tweetInputName);
                this.props.onUpdateParticipantData(this.state.participantInfo, this.state.event.id, 'sName', tweetInputName);
                this.setState({ participantName: tweetInputName });
            }
            document.getElementById('tweetModalsForm').reset();
        }
    }

    closeModal() {
        document.getElementById('tweetModalsForm').reset();
    }


    getUserData() {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.event.id));
        this.setState({ ...this.state, participantInfo: participant });
    }

    onSetSidebarOpen(open) {
        // this.setState({ sidebarOpen: open });
        this.props.onSettingStatus(open, callback => {
            if (callback === 'success') {
                this.setState({ sidebarOpen: open });
            }
        });
    }

    onToggle() {
        let participantInfo = JSON.parse(localStorage.getItem('participant-' + this.state.event.id));

        if (this.state.toggleActive === true) {
            this.setState({ toggleActive: false, textToggle: 'OFF' });

            Object.assign(participantInfo, { 'sTweetsApproved': false });
            localStorage.setItem("participant", JSON.stringify(participantInfo));

        } else {
            this.setState({ toggleActive: true, textToggle: 'ON' });
            Object.assign(participantInfo, { 'sTweetsApproved': true });
            localStorage.setItem("participant", JSON.stringify(participantInfo));

        }
        // this.setState({ toggleActive: !this.state.toggleActive });
    }

    onToggleOrder() {
        //case 'MoreRecent':
        if (this.state.toggleActiveOrder === true) {
            // var sortedTweets = [];
            // sortedTweets = this.state.allTweetsList.sort(function (a, b) { return a.dtmCreated > b.dtmCreated ? -1 : 1 });
            // let participantSortedTweets = this.state.participantTweetsList.sort(function (a, b) { return a.dtmCreated > b.dtmCreated ? -1 : 1 });
            //this.setState({ ...this.state, allLiveTweetsList: sortedTweets, participantTweetsList: participantSortedTweets });
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.event.id, 'sTweetsOrder', 'MoreRecent');

            this.setState({
                ...this.state,
                //tweetsListToShow: sortedTweets,
                //participantTweetsList: participantSortedTweets,
                toggleActiveOrder: false
            });
            // document.getElementById(idSwitch).classList.add("recent");
            // document.getElementById(idSwitch).firstChild.innerHTML ="More Recent";

        } else {
            //case 'LessRecent':
            // var sortedTweetsDec = [];
            // sortedTweetsDec = this.state.allTweetsList.sort(function (a, b) { return a.dtmCreated < b.dtmCreated ? -1 : 1 })
            // let participantSortedTweetsDec = this.state.participantTweetsList.sort(function (a, b) { return a.dtmCreated < b.dtmCreated ? -1 : 1 });
            //this.setState({ ...this.state, allLiveTweetsList: sortedTweetsDec, participantTweetsList: participantSortedTweetsDec });
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.event.id, 'sTweetsOrder', 'LessRecent');
            this.setState({
                ...this.state,
                //  tweetsListToShow: sortedTweetsDec,
                //participantTweetsList: participantSortedTweetsDec,
                toggleActiveOrder: true
            });
            // document.getElementById(idSwitch).classList.remove("recent");
            // document.getElementById(idSwitch).firstChild.innerHTML ="Less Recent";
        }

        // this.setState({ toggleActive: !this.state.toggleActive });
    }

    render() {
        var headerComponent;
        var borderType;
        var selectedLanguageStrings;
        var selectedLanguageCustomStrings;
        const userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_en;
            }
        } else {
            selectedLanguageStrings = italianStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_it;
            }
        }

        let arrayTweetsComponent;
        let arrayparticipantTweets;
        var liveTweetsNo = 0;
        if (this.state.allTweetsList) {
            if (this.state.allTweetsList.length !== 0) {
                var sortedTweets = [];
                if (this.state.event.autoApproveTweets === "Without Moderator") {
                    sortedTweets = this.state.allTweetsList.sort(function (a, b) {
                        return a.dtmCreated > b.dtmCreated ? 1 : -1
                    });
                } else {

                    if (this.state.event.tweetsToShow === "Approved") {
                        sortedTweets = this.state.allTweetsList.sort(function (a, b) {
                            return a.dtmApproved > b.dtmApproved ? 1 : -1
                        })
                    }
                    else if (this.state.event.tweetsToShow === "Live") {
                    sortedTweets = this.state.allTweetsList.sort(function (a, b) {
                        return a.dtmLive > b.dtmLive ? 1 : -1
                    })
                }
                }

                /*
                if (sortedTweets[0] && sortedTweets[0].dtmLive === undefined) {
                    sortedTweets.push(sortedTweets.shift());
                }
                */

                arrayTweetsComponent = (sortedTweets).map((tweet) => {
                    if (tweet.bArchived === false) {
                        liveTweetsNo++;
                        let pkidTweet = tweet.id;
                        let sText = tweet.sText;
                        // let dtmCreated = moment(tweet.dtmCreated.toDate()).format("HH:mm");
                        // let dtmCreated = tweet.dtmCreated;
                        let sParticipantName = tweet.participantDefinedName;
                        return (
                            <Tweet
                                tweet={tweet}
                                participantId={this.state.participantInfo.id}
                                sText={sText}
                                key={pkidTweet}
                                sParticipantName={sParticipantName}
                                dtmCreated={"dtmCreated"}
                                primaryColor={this.state.event.primaryColor}
                                textColor={this.state.event.textColor}
                                event={this.state.event}
                            />
                        );
                    }
                });


            }
        }


        //Receiving participant posted tweets if they become live we will remove them from this list and add them to the incoming tweets list
        if (this.state.participantTweetsList) {
            if (this.state.participantTweetsList.length !== 0) {
                var sortedParticipantTweets = [];
                if (this.state.toggleActiveOrder === false) {
                    sortedParticipantTweets = this.state.participantTweetsList.sort(function (a, b) {
                        return a.dtmCreated > b.dtmCreated ? -1 : 1
                    });
                } else {
                    //case 'LessRecent':
                    sortedParticipantTweets = this.state.participantTweetsList.sort(function (a, b) {
                        return a.dtmCreated < b.dtmCreated ? -1 : 1
                    });
                }

                arrayparticipantTweets = (sortedParticipantTweets).map((tweet) => {
                    let pkidTweet = tweet.id;
                    let sText = tweet.sText;
                    // let dtmCreated = moment(tweet.dtmCreated.toDate()).format("HH:mm");
                    // let dtmCreated = tweet.dtmCreated;
                    let sParticipantName = tweet.participantDefinedName;
                    return (
                        <Tweet
                            tweet={tweet}
                            participantId={this.state.participantInfo.id}
                            sText={sText}
                            key={pkidTweet}
                            sParticipantName={sParticipantName}
                            dtmCreated={"dtmCreated"}
                            primaryColor={this.state.event.primaryColor}
                            textColor={this.state.event.textColor}
                            event={this.state.event}
                        />
                    );
                });
            } else {
                arrayparticipantTweets = (
                    <div className="card">
                        <div className="card-body ">
                            <p className="fontTextArea text-center" style={{ color: this.state.event.primaryColor }}>
                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.you_did_not_send_any_question_yet : selectedLanguageStrings.your_tweets_note}
                            </p>
                        </div>
                    </div>
                );
            }
        }

        var tweetsColTitle;
        if (this.props.changedEventInfo.autoApproveTweets === "Without Moderator") {
            tweetsColTitle = selectedLanguageStrings.all_tweets;
        } else if (this.props.changedEventInfo.tweetsToShow === "Live") {
            tweetsColTitle = selectedLanguageStrings.live_tweets;
        } else if (this.props.changedEventInfo.tweetsToShow === "Approved") {
            tweetsColTitle = selectedLanguageStrings.approved_tweets;
        }

        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    goToAgendaPage={this.goToAgendaPage.bind(this)}
                    goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    goToDashboardPage={this.goToDashboardPage.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    pageName={"QuestionScreen"}
                    rootChildrenList={this.state.rootChildrenList}
                    surveyId={this.state.surveyId}
                />
            </div>);

            if (this.state.eventType === 'questions') {
                borderType = 'border-bottom-question';
            } else if (this.state.eventType === 'tweets') {
                borderType = 'border-bottom-tweet';
            } else {
                borderType = "";
            }
        }

        let containerscreenclass = "";
        let backgroundimagestyle = "";
        if (this.state.event.themeParticipantImageUrl === "default") {
            containerscreenclass = "container-screen-default";
            backgroundimagestyle ="";
        } else {
            containerscreenclass = "container-screen";
            if (this.state.event.themeParticipantImageUrl == undefined) {
                backgroundimagestyle = "";
            }
            else {
                backgroundimagestyle = `url(" ${this.state.event.themeParticipantImageUrl} ")`;
            }
        }

        return (
            <main>
                <div>
                    {headerComponent}
                </div>
                <div>
                    <div className="imageTheme" style={{ backgroundImage: backgroundimagestyle }}>
                        <div className={`container ${containerscreenclass}`}>
                            <div className="row pt-4">
                                {(this.state.parentInfo.roomIconColor && this.state.roomsList.length > 1) ? (
                                    <div className="col-12 text-success text-center">
                                        <h4 style={{ color: this.state.parentInfo.roomIconColor }}>
                                            {/* <i className="fas fa-users menuIcons pr-3" style={{color: this.state.parentInfo.roomIconColor }}></i> */}
                                            <i className="fas fa-door-open menuIcons pr-3"
                                                style={{ color: this.state.parentInfo.roomIconColor }}></i>
                                            <b>{this.state.parentInfo.title}</b>
                                        </h4>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <div>
                                        {(this.state.parentInfo.currentTweetElement !== "default" && this.props.changedEventInfo.showTweetsToParticipants === "Yes" && this.state.allTweetsList && this.state.allTweetsList.length !== 0) ? (
                                            <div>
                                                <div className="row mr-1">
                                                    <div className="col-7 text-left container-tweet">
                                                        {(liveTweetsNo === 0) ? (
                                                            <div></div>
                                                        ) : (
                                                            <h5 className="mb-1 mt-4"
                                                                style={{ color: this.state.event.primaryColor }}>
                                                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.live_questions : selectedLanguageStrings.live_tweets}
                                                            </h5>
                                                        )}
                                                    </div>
                                                    {/* {(this.state.toggleActiveOrder === false) ? (
                        <div className="col-5 text-right mt-1" style={{ color: this.state.event.primaryColor }}>
                            <div className="switch recent" style={{ backgroundColor: this.state.event.primaryColor }} id="switch-btn" onClick={this.onToggleOrder}>
                                <div className="textToggleOrder" style={{ color: this.state.event.textColor }}>{selectedLanguageStrings.more_recent}</div>
                                <div className="inputOrder" style={{ backgroundColor: this.state.event.textColor }}></div>
                            </div>
                        </div>
                    ) : (
                            <div className="col-5 text-right mt-1 ">
                                <div className="switch " id="switch-btn" onClick={this.onToggleOrder}>
                                    <div className="textToggleOrder" style={{ color: this.state.event.textColor }}>{selectedLanguageStrings.less_recent}</div>
                                    <div className="inputOrder" style={{ backgroundColor: this.state.event.textColor }}></div>
                                </div>
                            </div>
                        )} */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        {arrayTweetsComponent}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}

                                        {(this.state.parentInfo.currentTweetElement !== "default") ? (
                                            <div className="containerUserTweets">
                                                <div className="row">
                                                    <div className="col-8 col-sm-8 text-left">
                                                        <div className="container-tweet">
                                                            <h5 className="mb-1 mt-4" style={{ color: this.state.event.primaryColor }}>
                                                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.your_questions : selectedLanguageStrings.your_questions}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        {arrayparticipantTweets}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}


                                        <div className="fixedFooter mt-6">
                                            <div className="container d-flex justify-content-center">
                                                {/* {arrayTweets} */}
                                                {(this.state.parentInfo.currentTweetElement !== "default" && this.state.parentInfo.tweetsParticipantsWriteEnable === true) ? (
                                                    <button type="button" className="btn"
                                                        style={{ backgroundColor: this.state.event.headerBackgroundColor }} id="fixedbutton"
                                                        data-toggle="modal" data-target="#newTweetModal" onClick={this.getUserData}>
                                                        <i className="far fa-edit fixedbuttonIcon"
                                                            style={{ color: this.state.event.headerTextColor }}></i>
                                                    </button>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="modal fade" id="newTweetModal" tabIndex="-1" role="dialog"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel"
                                                            style={{ color: this.state.event.primaryColor }}>
                                                            {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.write_a_new_question : selectedLanguageStrings.tweet_modal_title}
                                                        </h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        <form id='tweetModalsForm'>
                                                            <div className="form-group">
                                                                <label htmlFor="tweetInputText" style={{ color: this.state.event.primaryColor }}>
                                                                    {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.your_question : selectedLanguageStrings.your_question}
                                                                </label>
                                                                <textarea className="form-control" id="tweetInputText" rows="3"
                                                                    name="tweetInputText"></textarea>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="tweetInputName"
                                                                    style={{ color: this.state.event.primaryColor }}>{selectedLanguageStrings.your_name}</label>
                                                                <input type="text" className="form-control" id="tweetInputName"
                                                                    name="tweetInputName" defaultValue={this.state.participantInfo.sName} />
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-sm-10 ml-3">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            name="optionAnonymous" id="optionAnonymous" />
                                                                        <label className="custom-control-label pl-1" htmlFor="optionAnonymous"
                                                                            style={{
                                                                                color: this.state.event.primaryColor,
                                                                                paddingTop: "3px"
                                                                            }}>
                                                                            {selectedLanguageStrings.send_anonymous}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                                            onClick={() => this.closeModal()}>{selectedLanguageStrings.cancel}</button>
                                                        <button type="button" className="btn" style={{
                                                            backgroundColor: this.state.event.headerBackgroundColor,
                                                            color: this.state.event.headerTextColor
                                                        }} data-dismiss="modal"
                                                            onClick={this.sendTweet.bind(this)}>{selectedLanguageStrings.send}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onGetAllTweets: (eventId, tweetElId, option, callback) => dispatch(tweetsActions.getAllTweets(eventId, tweetElId, option, callback)),
        onGetparticipantTweets: (participantId, eventId, tweetIdEl, callback) => dispatch(tweetsActions.getparticipantTweets(participantId, eventId, tweetIdEl, callback)),
        onSaveNewTweet: (participantId, eventId, idTweetEl, tweetInputText, tweetInputName) => dispatch(tweetsActions.saveNewTweet(participantId, eventId, idTweetEl, tweetInputText, tweetInputName)),
        onUpdateParticipantData: (participantInfo, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantInfo, eventId, option, value)),
        onGetSurveyFilesData: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFilesData(eventId, surveyId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback))
    };
}

const mapStateToProps = state => {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionScreen);
