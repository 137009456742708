import React, { Component } from 'react';
import moment from 'moment';
import Utility from '../Helpers/Utility';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import { connect } from 'react-redux';



class MessageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

        this.confirmedImportantMessage = this.confirmedImportantMessage.bind(this);
        this.confirmedMessagesList = this.confirmedMessagesList.bind(this);

    }

    /*
    FC: Not used. 
    Important Message showed in Message List modal with normal priority messages.
    To be deleted if not necessary for future use.
    */
    confirmedImportantMessage() {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.changedEventInfo.id));
        var visitedMessages = [];
        if (participant.id) {
            visitedMessages = participant.id;
        }
        if (this.props.unreadImportantMessages.length > 0) {
            for (var i = 0; i < this.props.unreadImportantMessages.length; i++) {
                if (!visitedMessages.includes(this.props.unreadImportantMessages[i].id)) {
                    visitedMessages.push(this.props.unreadImportantMessages[i].id);
                }
                Object.assign(participant, { 'VisitedMessages': visitedMessages });
                localStorage.setItem('participant-' + this.props.changedEventInfo.id, JSON.stringify(participant));
            }
        }
        this.props.setBadgeNumber();
    }

    confirmedMessagesList() {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.changedEventInfo.id));
        var visitedMessages = [];

        if (participant.VisitedMessages) visitedMessages = participant.VisitedMessages;

        for (var i = 0; i < this.props.eventMessageList.length; i++) {
            if (!visitedMessages.includes(this.props.eventMessageList[i].id))
                visitedMessages.push(this.props.eventMessageList[i].id);
        }

        Object.assign(participant, { 'VisitedMessages': visitedMessages });
        localStorage.setItem('participant-' + this.props.changedEventInfo.id, JSON.stringify(participant));

        this.props.setBadgeNumber();
    }


    // let participant = JSON.parse(localStorage.getItem('participant-' + this.props.changedEventInfo.id));
    // for (var i=0; i<this.props.eventMessageList.length;i++){
    //     if (!participant.iVisitedMessages || (participant.iVisitedMessages && participant.iVisitedMessages< this.props.eventMessageList.length) ) {
    //         Object.assign(participant, { 'iVisitedMessages': this.props.eventMessageList.length });
    //         localStorage.setItem('participant-' + this.props.changedEventInfo.id, JSON.stringify(participant));
    //     } 
    // }


    render() {

        var selectedLanguageStrings;
        let userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }
        var eventMessagesComponent = (
            <div>
                {this.props.eventMessageList.map((eventMessage, index) => (
                    // (index===0 || index%2 === 0)?(
                    (eventMessage.messageImportance === "high") ? (
                        <div className="row messageRow" key={index}>
                            <div className="col-8 text-left pt-1 " style={{ backgroundColor: "#f8d7da", color: "#721c24" }}><b>{eventMessage.title}</b></div>
                            <div className="col-4 text-right pt-1 " style={{ backgroundColor: "#f8d7da", color: "#721c24", fontSize: "13px" }}>{moment(eventMessage.dtmMessage.toDate()).format("DD-MM-YY HH:mm")}</div>
                            <div className="col-12 pb-1 " style={{ backgroundColor: "#f8d7da", color: "#721c24" }}>{eventMessage.body}</div>
                        </div>
                    ) : (
                        <div className="row messageRow" key={index}>
                            <div className="col-8 text-left  pt-1" style={{ backgroundColor: "#d4edda", color: "#155724" }}><b>{eventMessage.title}</b></div>
                            <div className="col-4 text-right pt-1" style={{ backgroundColor: "#d4edda", color: "#155724", fontSize: "13px" }}>{moment(eventMessage.dtmMessage.toDate()).format("DD-MM-YY HH:mm")}</div>
                            <div className="col-12 pb-1" style={{ backgroundColor: "#d4edda", color: "#155724" }}>{eventMessage.body}</div>
                        </div>
                    )
                ))}
            </div>
        );


        var importantMessagesComponent = (
            <div>
                {this.props.unreadImportantMessages.map((importantMessage, index) => (
                    <div className="card p-2 mb-2" key={index}>
                        <div className="mb-2">
                            <b>{selectedLanguageStrings.event_message_title}: {importantMessage.title}</b>
                        </div>
                        <div className="">
                            {importantMessage.body}
                        </div>
                    </div>
                ))}

            </div>
        );

        return (
            <div>
                <div className="modal fade eventMessageModal" id={this.props.eventMessagesModal} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: this.props.changedEventInfo.primaryColor }}>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ color: this.props.changedEventInfo.textColor }}>
                                    <b>{selectedLanguageStrings.event_messages}</b>
                                </h5>
                            </div>
                            <div className="modal-body">
                                {eventMessagesComponent}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.confirmedMessagesList}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <button id="eventImportantModalBtn" className="bg-success" data-toggle="modal" data-target="#eventImportantModal">
                </button> */}
                <div className="modal fade" id="eventImportantModal" tabIndex="-1" role="dialog" aria-labelledby="profileModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header" style={{ backgroundColor: this.props.changedEventInfo.primaryColor }}>
                                <h5 className="modal-title" id="exampleModalLabel">{selectedLanguageStrings.important_message}</h5>
                            </div>
                            <div className="modal-body">
                                {importantMessagesComponent}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.confirmedImportantMessage}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    }
}

export default connect(mapStateToProps, null)(MessageModal);
