import { lang } from 'moment';
import { version } from '../../package.json';

class Utility {

    constructor() {
    }

    static get INSTANCE() {
        if (this._utility === undefined) {
            this._utility = new Utility();
        }

        return this._utility;
    }

    //Return true if app version has changed, false otherwise.
    checkAppVersionChanged() {
        var appVersion = localStorage.getItem('4meet-client-version');
        if (!(appVersion && appVersion === version)) {
            return true;
        }else {
            return false;
        }
    }

    initializeLocalStorage() {
        //Reset all local storage, save current app version and set default language.
        localStorage.clear();
        localStorage.setItem("4meet-client-version", version);
    }    

    setDefaultLanguage() {
        let userLanguage = this.detectLanguage();
        let language = 'English';

        if (userLanguage.includes("it")) {
            language = 'Italian';
            localStorage.setItem("4meet-Language-Login", language);
            localStorage.setItem("4meet-Language", language);
        } else {
            language = 'English';
            localStorage.setItem("4meet-Language-Login", language);
            localStorage.setItem("4meet-Language", language);
        }
        return language;
    }    

    detectLanguage() {
        return (navigator.languages && navigator.languages[0]) ||
            navigator.language ||
            navigator.userLanguage;
    }

    getEventLanguage() {
        let eventLanguage = localStorage.getItem('4meet-Language');

        //If eventLanguage is not set, set it to the user language.
        if (!eventLanguage) {
            let userLanguage = this.detectLanguage();
            if (userLanguage.includes("it")) {
                eventLanguage = 'Italian';
            }
            else {
                eventLanguage = 'English';
            }
            localStorage.setItem("4meet-Language", eventLanguage);
        }
        
        return eventLanguage;
    }

    setEventLanguage(language) {
        localStorage.setItem("4meet-Language", language);
    }

    getLoginLanguage() {
        let loginLanguage = localStorage.getItem('4meet-Language-Login');

        //If loginLanguage is not set, set it to the user language.
        if (!loginLanguage) {
            let userLanguage = this.detectLanguage();
            if (userLanguage.includes("it")) {
                loginLanguage = 'Italian';
            }
            else {
                loginLanguage = 'English';
            }
            localStorage.setItem("4meet-Language-Login", loginLanguage);
        }

        return loginLanguage;
    }    

    setLoginLanguage(language) {
        localStorage.setItem("4meet-Language-Login", language);
    }
}

export default Utility.INSTANCE;