import React, {Component} from 'react';
import firebase from '../Helpers/firebase';
import firebase1 from 'firebase';
import iframe from 'iframe';
import {generalActions} from '../Redux/Actions/generalActions';
import {connect} from 'react-redux';
import Header from '../Component/Header';
import moment from 'moment';
import Utility from '../Helpers/Utility';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';

class SurveyThanksPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            surveyId: this.props.match.params.idItem,
            filesList: [],
            sidebarOpen: false,
            event: 'default',
            parentInfo: 'default',
            eventMessagesModal: 'eventMessagesModal',
            sortedEventMessageList: [],
            badgeNumber: 0,
            eventImportantModal: "eventImportantModal",
            userSelectedLanguage: 'defult',
            participantInfo: 'default',
            roomsList: [],
            surveyFilesList: [],
            currentSurveyFile: '',
            rootChildrenList: [],
            surveyLocalStorageStatus: ''

        };
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            this.setDefaultLanguage();
        }

        let userStoredLanguage = JSON.parse(localStorage.getItem('4meet-Language'));
        this.setState({
            ...this.state,
            userSelectedLanguage: userStoredLanguage.selectedLanguage
        });

        var surveyVotes = JSON.parse(localStorage.getItem("surveyVotes"));
        if (surveyVotes && surveyVotes.length !== 0) {
            surveyVotes[surveyVotes.length + 1] = this.state.surveyId;
            localStorage.setItem("surveyVotes", JSON.stringify(surveyVotes));
        } else {
            var surveyVotesNew = [];
            surveyVotesNew.push(this.state.surveyId);
            localStorage.setItem("surveyVotes", JSON.stringify(surveyVotesNew));
        }

        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }
        this.props.onEventInfoChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeEventInfoChange = unsubscribe;
            this.setState({...this.state, event: callback.data});
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }
    }

    setDefaultLanguage() {
        var userLanguage = Utility.setDefaultLanguage();
        this.setState({ ...this.state, userSelectedLanguage: userLanguage });
    }
        
    render() {
        var headerComponent;
        var borderType;

        var selectedLanguageStrings;
        if (this.state.userSelectedLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        return (
            <main>

                <div className="container">
                    <div className="row ">
                        <div className="col-12 pt-5">
                            <div className="card questionCard " id="card-box">
                                <div className="card-header" style={{backgroundColor: this.state.event.textColor}}>
                                    <h4 className="questionHeader" style={{color: this.state.event.primaryColor}}>
                                        Survey
                                    </h4>
                                </div>
                                <div className="card-body black-text" id="cardOptionContent">
                                    <div className="row">
                                        <div className="col-12 text-center"
                                             style={{color: this.state.event.primaryColor}}>
                                            <h5>{selectedLanguageStrings.thanks_your_answer}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

// export default HtmlScreen;

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
    };
}

function mapStateToProps(state) {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyThanksPage);