import firestore from '../Helpers/firebase';
import firebase1 from 'firebase';

export const questionFunctions = {
    getLiveSessionOptions,
    getQuestion,
}

function getLiveSessionOptions(eventId, elementId, questionId, liveSessionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/" + questionId + "/Answers").get();
}

function getQuestion(eventId, elementId, questionId) {
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + elementId + "/questions/").doc(questionId).get();
}

