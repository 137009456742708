import React, { Component } from 'react';
class App404 extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return(
            <div>
                404 page
            </div>
        );
    }
}
export default App404;