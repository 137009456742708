import React, {Component} from 'react';
import {connect} from 'react-redux';
import {tweetsActions} from '../Redux/Actions/tweetsActions';
import {generalActions} from '../Redux/Actions/generalActions';
import Tweet from '../Component/Tweet';
import Utility from '../Helpers/Utility';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import * as firebase from 'firebase';


class RoomsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: '',
            eventId: this.props.match.params.idEvent,
            roomsList: [],
            event: "default"
        };
        // this.onSelectedRoom = this.onSelectedRoom.bind(this);
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));

        if (!participant) {
            this.props.history.push("/");
        } else {
            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeEventInfoChange = unsubscribe;
                this.setState({...this.state, event: callback.data});

                if (!callback.data.availableLanguages.lang_it || !callback.data.availableLanguages.lang_en) {
                    if (callback.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (callback.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
                }

            if (this.unsubscribeGetEventRoomsOnChange) {
                this.unsubscribeGetEventRoomsOnChange();
            }
            this.props.onGetEventRoomsOnChange(this.state.eventId, (roomsList, unsubscribe) => {
                this.unsubscribeGetEventRoomsOnChange = unsubscribe;
                if (roomsList.length > 1) {
                    roomsList.sort((a, b) => (a.iOrder > b.iOrder) ? 1 : -1)
                    this.setState({...this.state, roomsList: roomsList});
                } else {
                        //If only a room is present, check to go to Event Start Url or go to Room.
                        this.goToEvent(callback.data.startUrl, roomsList[0]);
                }
            });
            });
        }
    }

    componentDidUpdate(prevProps) {

        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        } else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                } else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }
    }

    onSelectLanguage(option) {
        if (option === 'English') {
            var value = {selectedLanguage: 'English'};
            //this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
        } else {
            var value = {selectedLanguage: 'Italian'};
            //this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
        }
    }

    onSelectedRoom(room) {
        this.goToEvent(this.state.event.startUrl, room);
    }

    goToEvent(eventStartUrl, room) {
        //If present, go to Event Start Url.
        if (eventStartUrl !== undefined && eventStartUrl !== "") {
            this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + room.id + this.state.event.startUrl);
        }
        //Otherwise, if present go to Room startUrl Page.
        else if (room.startUrl !== undefined && room.startUrl !== "") {
            this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + room.id + room.startUrl);
        }
        //Otherwise go to Room Home Page.
        else {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + room.id);
    }
    }

    render() {
        var selectedLanguageStrings;
        var selectedLanguageCustomStrings;
        const userStoredLanguage = Utility.getEventLanguage();

        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_en;
            }
        } else {
            selectedLanguageStrings = italianStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_it;
            }
        }

        var roomsComponents;

        if (this.state.roomsList.length > 0) {
            roomsComponents = this.state.roomsList.map((room, index) => {
                return (
                    <div className="p-3 text-center roomBox" key={room.id}
                         style={{borderBottomColor: this.props.changedEventInfo.headerBackgroundColor}}
                         onClick={this.onSelectedRoom.bind(this, room)}>
                        <div className="roomsIcon">
                            {/* <i className="fas fa-users" style={{color: room.roomIconColor }}></i> */}
                            <i className="fas fa-door-open" style={{color: room.roomIconColor}}></i>
                        </div>

                        <div className="roomsTitle" style={{color: room.roomIconColor}}>
                            {room.title}
                        </div>
                    </div>
                );
            });

            var containerContentComponent = (
                <div>
                    <div className="row pt-5">
                        <div className="col-12 text-center">
                            <h3 style={{color: this.props.changedEventInfo.primaryColor}}>
                                {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.select_room : selectedLanguageStrings.select_room}
                            </h3>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-2">
                        </div>
                        <div className="col-8">
                            {roomsComponents}
                        </div>
                    </div>
                </div>
            )
            return (
                <main role="main">
                    {(this.state.event.themeParticipantImageUrl === "default") ? (
                        <div className="container container-screen-default" style={{paddingTop: "0px"}}>
                            {containerContentComponent}
                        </div>
                    ) : (
                        <div className="imageTheme"
                             style={{backgroundImage: "url(" + this.state.event.themeParticipantImageUrl + ")"}}>
                            <div className="container container-screen" style={{paddingTop: "0px"}}>
                                {containerContentComponent}
                            </div>
                        </div>
                    )}
                </main>
            )
        } else
            return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
    };
}

function mapStateToProps(state) {

    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsScreen);

