import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generalActions } from '../Redux/Actions/generalActions';
import Header from '../Component/Header';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import Flag from 'react-world-flags';
import Sidebar from "react-sidebar";
import moment from 'moment';

class RedirectLibraryFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventId: this.props.match.params.idEvent,
            idRoom: this.props.match.params.idRoom,
            idLibrary: this.props.match.params.idLibrary,
            idFile: this.props.match.params.idFile,
            currentFile:"default"
            
        };
    }

    componentDidMount() {
        // this.props.onGetLibraryFileInfo(this.state.eventId, this.state.idLibrary, this.state.idFile, file=>{
        //     if(file !=="Not Exists"){
        //     window.open(file.fileUrl,"_self");
        //     this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.idRoom + "/library")
        //     }
        // });
    }

    render(){
        return(
            <div id="openFile">
            
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetLibraryFileInfo: (eventId, libraryId, fileId, callback) => dispatch(generalActions.getLibraryFileInfo(eventId, libraryId, fileId, callback)),
    };
};
const mapStateToProps = state => {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    };


};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectLibraryFile);

