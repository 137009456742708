// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/database';
import * as firebase from 'firebase';

// Initialize Firebase 
var config = {
    apiKey: "AIzaSyCSTM1xFwbIFVqnw-aUEA5Y8vRZvFM-3Ew",
    authDomain: "kpad-netizens.firebaseapp.com",
    databaseURL: "https://kpad-netizens.firebaseio.com",
    projectId: "kpad-netizens",
    storageBucket: "kpad-netizens.appspot.com",
    messagingSenderId: "428049299436",
    appId: "1:428049299436:web:ec7f516d39dc84974b72c9"
};
firebase.initializeApp(config);
    

var firestore = firebase.firestore();
//firestore.settings({timestampsInSnapshots: true});
firestore.settings({experimentalAutoDetectLongPolling : true});

export default firestore;

// export const askForPermissioToReceiveNotifications = async () => {
//        //Checks if a browser supports notification capabilities.
//     // if (!firebase.messaging.isSupported()) {
//     //     console.log('Browser does not support notification capabilities !!!!!');
//     //     return;
//     //   }
//     const messaging = firebase.messaging();
//     messaging.requestPermission()
//     .then(function(){
//         console.log('Have Permission');
//         return messaging.getToken();
//     })
//     .then(function(token){
//         console.log('token is:' + token);
//         let participant = JSON.parse(localStorage.getItem('participant'));
//         this.props.onAddClientToken(userId,eventId)
//     })
//     .catch(function(err){
//         console.log("Error Occured" + err);
//     })

//     //The reason to is that if the page is already open is better to show on the page instead of showing on the notification !!!!!!
// messaging.onMessage(function(payload){
//     //we can show it as a little notification badge or something else
//     console.log('onMessage: ', payload); 
// });
//     // //Checks if a browser supports notification capabilities.
//     // if (!firebase.messaging.isSupported()) {
//     //     console.log('Browser does not support notification capabilities !!!!!');
//     //     return;
//     //   }
//     // console.log('askForPermissioToReceiveNotifications is called ');
//     // try {
  
//     //   const messaging = firebase.messaging();
  
//     //   await messaging.requestPermission();
//     //   const token = await messaging.getToken();
//     //   console.log('user token: ', token);
  
//     //   return token;
//     // } catch (error) {
//     //   console.error(error);
//     // }
//    }