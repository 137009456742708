import firestore from '../Helpers/firebase';
import firebase1 from 'firebase';

export const tweetFunctions = {
    getAllTweets,
    getparticipantTweets,
    saveNewTweet
}

function getAllTweets(eventId, tweetIdEl, option, callback) {

    var unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets").onSnapshot(querySnapShot => {
        let tweetsList = [];
        querySnapShot.forEach(tweetDoc => {
            if (!tweetDoc.data().elementIsDeleted || tweetDoc.data().elementIsDeleted && tweetDoc.data().elementIsDeleted === false) {
                if (option === 'Without Moderator') {
                    let tweet = {
                        id: tweetDoc.id,
                        dtmCreated: tweetDoc.data().dtmCreated,
                        participantRefId: tweetDoc.data().participantRefId,
                        participantDefinedName: tweetDoc.data().participantDefinedName,
                        sText: tweetDoc.data().sText,
                        live: tweetDoc.data().live,
                        bApproved: tweetDoc.data().bApproved,
                        bArchived: tweetDoc.data().bArchived,
                        dtmLive: tweetDoc.data().dtmLive,
                        dtmApproved: tweetDoc.data().dtmApproved
                    };
                    tweetsList.push(tweet);
                } else if (option === "Live") {
                    if (tweetDoc.data().live === true) {
                        let tweet = {
                            id: tweetDoc.id,
                            dtmCreated: tweetDoc.data().dtmCreated,
                            participantRefId: tweetDoc.data().participantRefId,
                            participantDefinedName: tweetDoc.data().participantDefinedName,
                            sText: tweetDoc.data().sText,
                            live: tweetDoc.data().live,
                            bApproved: tweetDoc.data().bApproved,
                            bArchived: tweetDoc.data().bArchived,
                            dtmLive: tweetDoc.data().dtmLive,
                            dtmApproved: tweetDoc.data().dtmApproved
                        };
                        tweetsList.push(tweet);
                    }
                } else if (option === "Approved") {
                    if (tweetDoc.data().bApproved === true) {
                        let tweet = {
                            id: tweetDoc.id,
                            dtmCreated: tweetDoc.data().dtmCreated,
                            participantRefId: tweetDoc.data().participantRefId,
                            participantDefinedName: tweetDoc.data().participantDefinedName,
                            sText: tweetDoc.data().sText,
                            live: tweetDoc.data().live,
                            bApproved: tweetDoc.data().bApproved,
                            bArchived: tweetDoc.data().bArchived,
                            dtmLive: tweetDoc.data().dtmLive,
                            dtmApproved: tweetDoc.data().dtmApproved
                        };
                        tweetsList.push(tweet);
                    }
                }
            }
        });
        callback(tweetsList, unsubscribe);
    });
}


function getparticipantTweets(participantId, eventId, tweetIdEl, callback) {
    var unsubscribe = firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets").where("participantRefId", "==", participantId).where("elementIsDeleted", "==", false).onSnapshot(querySnapShot => {
        let participantTweetsList = [];
        querySnapShot.forEach(tweetDoc => 
            {
            let tweet = {
                id: tweetDoc.id,
                dtmCreated: tweetDoc.data().dtmCreated,
                participantRefId: tweetDoc.data().participantRefId,
                participantDefinedName: tweetDoc.data().participantDefinedName,
                sText: tweetDoc.data().sText,
                bApproved: tweetDoc.data().bApproved,
                bArchived: tweetDoc.data().bArchived,
                live: tweetDoc.data().live,
                dtmLive: tweetDoc.data().dtmLive,
                dtmApproved: tweetDoc.data().dtmApproved
            };
            participantTweetsList.push(tweet);
        });
        callback(participantTweetsList, unsubscribe);
    });
}

function saveNewTweet(participantId, eventId, tweetIdEl, tweetInputText, tweetInputName) {
    let tweet = {
        bApproved: false,
        bArchived: false,
        bShown: false,
        dtmCreated: firebase1.firestore.FieldValue.serverTimestamp(),
        participantRefId: participantId,
        participantDefinedName: tweetInputName,
        sText: tweetInputText,
        live: false,
        elementIsDeleted: false
    };
    return firestore.collection("events/" + eventId + "/elements/default/elementsItems/" + tweetIdEl + "/tweets/").add(tweet);
}