import { generalFunctions } from '../../FirebaseFunctions/GeneralFunctions';
import { actionTypes } from '../Actions/actionTypes';

export const GET_EVENT_INFO = "GET_EVENT_INFO";

export const generalActions = {

    getEventData,
    getEventDataById,
    updateParticipantData,
    login,
    settingStatus,
    getEventInfoOnChange,
    sendVote,
    sendAnswerText,
    sendRatingAnswer,
    setSplashPage,
    updateClientNotificationToken,
    getAllEventMessages,
    getEventRoomsOnChange,
    getParentInfo,
    getParentInfoOnChange,
    getLibraryFiles,
    getLibraryInfoOnChange,
    geteventDataDefault,
    getListOfRootChildrenOnChange,
    getAllAgendaFilesOnChange,
    getSurveyFiles,
    getSurveyInfoOnChange,
    getLibraryFileInfo,
    getParticipantsNumber,
    getQRText,
    getFileNameOnChange,
    getServerHeartBeatOnChange,
    resetServerHeartBeat,
    getSurveyFilesData
}

function login(eventId, value, callback) {
    return dispatch => {
        generalFunctions.login(eventId, value)
            .then(docref => {
                let participant = { 'id': docref.id, 'eventId': eventId }
                localStorage.setItem('participant-' + eventId, JSON.stringify({
                    'id': docref.id,
                    'eventId': eventId
                }));
                // as soon as the user logs in we will also create the voting array to prevent stucking in this part for the question

                dispatch(success(participant));
                callback("success");
            })
            .catch(function (error) {
                //dispatch(failure(error));
            })
    };

    function success(participant) {
        return {type: actionTypes.LOGIN_REQUEST_SUCCESS, participant}
    }
}

function getEventData(eventCode, callback) {
    return dispatch => {
        generalFunctions.getEventData(eventCode).then(
            querySnapShot => {
                let eventList = [];
                querySnapShot.forEach(eventDoc => {

                    let event = eventDoc.data();
                    event.id = eventDoc.id;
                    eventList.push(event);
                });
                if (eventList.length === 0) {

                    //  callback('CodeNotExist');
                    dispatch(success('CodeNotExist'));
                    callback('CodeNotExist');
                } else {

                    //eventId = eventList[0].id;
                    // callback(eventList[0]);
                    dispatch(success(eventList[0]));
                    callback(eventList[0]);
                }
                // dispatch(success(result));
                // callback(result);
            },
            error => {
                //dispatch(failure(error))
            }
        );
    };

    function success(eventData) {
        return {type: actionTypes.GET_EVENT_DATA, eventData}
    }
}

function getEventDataById(eventId, callback) {
    return dispatch => {
        generalFunctions.getEventDataById(eventId).then(eventDoc => {
            let eventList = [];
            let event = eventDoc.data();
            event.id = eventDoc.id;
            eventList.push(event);
            if (eventList.length === 0) {
                //  callback('CodeNotExist');
                dispatch(success('CodeNotExist'));
                callback('CodeNotExist');
            } else {
                dispatch(success(eventList[0]));
                callback(eventList[0]);
            }
        });
    };

    function success(eventData) {
        return {type: actionTypes.GET_EVENT_DATA, eventData}
    }
}

function getListOfRootChildrenOnChange(eventId, callback) {
    return dispatch => {
        generalFunctions.getListOfRootChildrenOnChange(eventId,
            (rootChildrenList, unsubscribe) => {
                dispatch(success(rootChildrenList));
                callback(rootChildrenList, unsubscribe);
                // localStorage.setItem("sToken-"+idEvent, docref.id);
            });
    }

    function success(rootChildrenList) {
        return {type: actionTypes.GET_ROOT_CHILDREN_SUCCESS, rootChildrenList}
    }
}

function getAllAgendaFilesOnChange(eventId, roomId, agendaId, callback) {
    return dispatch => {
        generalFunctions.getAllAgendaFilesOnChange(eventId, roomId, agendaId,
            (agendaFilesList,unsubscribe) => {
                dispatch(success(agendaFilesList));
                callback(agendaFilesList, unsubscribe);
            });
    }

    function success(agendaFilesList) {
        return {type: actionTypes.GET_ALL_AGENDA_FILES_SUCCESS, agendaFilesList}
    }
}

function getEventRoomsOnChange(eventId, callback) {
    return dispatch => {
        generalFunctions.getEventRoomsOnChange(eventId,
            (eventRoomsList, unsubscribe) => {
                dispatch(success(eventRoomsList));
                callback(eventRoomsList, unsubscribe);
                // localStorage.setItem("sToken-"+idEvent, docref.id);
            });
    }

    function success(eventRoomsList) {
        return {type: actionTypes.GET_EVENT_ROOMS_SUCCESS, eventRoomsList}
    }

}

function getLibraryInfoOnChange(eventId, libraryId, callback) {
    return dispatch => {
        generalFunctions.getLibraryInfoOnChange(eventId, libraryId,
            (libraryDoc, unsubscribe) => {
                let libraryData = libraryDoc.data();
                libraryData.id = libraryDoc.id;
                dispatch(success(libraryData));
                callback(libraryData, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(libraryData) {
        return {type: actionTypes.GET_LIBRARY_INFO_SUCCESS, libraryData}
    }
}

function getFileNameOnChange(eventId, libraryId, fileId, callback) {
    return dispatch => {
        generalFunctions.getFileNameOnChange(eventId, libraryId, fileId,
            (fileDoc, unsubscribe) => {
                callback(fileDoc.data(), unsubscribe);
            },
        );
    };
}

function getLibraryFiles(eventId, libraryId, callback) {
    return dispatch => {
        generalFunctions.getLibraryFiles(eventId, libraryId,
            (libraryFilesList, unsubscribe) => {
                dispatch(success(libraryFilesList));
                callback(libraryFilesList, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(libraryFilesList) {
        return {type: actionTypes.GET_ALL_LIBRARY_FILES_SUCCESS, libraryFilesList}
    }
}

function getSurveyFiles(eventId, surveyId, callback) {
    return dispatch => {
        generalFunctions.getSurveyFiles(eventId, surveyId,
            (surveyFilesList, unsubscribe) => {
                dispatch(success(surveyFilesList));
                callback(surveyFilesList, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(surveyFilesList) {
        return {type: actionTypes.GET_ALL_SURVEY_FILES_SUCCESS, surveyFilesList}
    }
}

function getSurveyFilesData(eventId, surveyId, callback) {
    return dispatch => {

        generalFunctions.getSurveyFilesData(eventId, surveyId).then(surveyFilesDoc => {
            let surveyFilesList = [];
            surveyFilesDoc.forEach(filedoc => {
                surveyFilesList.push(filedoc.data());
            });
            callback(surveyFilesList);
        });
    };
}

function getParentInfo(eventId, selectedRoomId, callback) {
    return dispatch => {
        generalFunctions.getParentInfo(eventId, selectedRoomId).then(
            result => {
                let parentInfo = result.data();
                parentInfo.id = result.id;

                dispatch(success(parentInfo));
                callback({ message: 'success', data: parentInfo });
            });
    }

    function success(parentInfo) {
        return {type: actionTypes.GET_PARENT_ELEMENT_INFO__SUCCESS, parentInfo}
    }
}

function getParentInfoOnChange(eventId, selectedRoomId, callback) {
    return dispatch => {
        generalFunctions.getParentInfoOnChange(eventId, selectedRoomId,
            (result, unsubscribe) => {
                let parentInfo = result.data();
                parentInfo.id = result.id;

                dispatch(success(parentInfo));
                callback({ message: 'success', data: parentInfo }, unsubscribe);
                // localStorage.setItem("sToken-"+idEvent, docref.id);
            });
    }

    function success(parentInfo) {
        return {type: actionTypes.GET_PARENT_ELEMENT_INFO__SUCCESS, parentInfo}
    }
}

function updateParticipantData(participant, eventId, option, value) {
    return dispatch => {
        generalFunctions.updateParticipantData(participant, eventId, option, value).then(
            result => {
                // dispatch(success());
                switch (option) {
                    case 'sTweetsOrder':
                        Object.assign(participant, { 'sTweetsOrder': value });
                        dispatch(success(participant));
                        break;

                    case 'sName':
                        Object.assign(participant, { 'sName': value });
                        dispatch(success(participant));
                        break;

                    case 'newLogin':
                        dispatch(success(participant));
                        break;

                    case 'userInfo':
                        Object.assign(participant, { 'sName': value.name, 'sEmail': value.email });
                        dispatch(success(participant));
                        break;

                    default:
                        return true;
                }
            },
            error => {
                //   dispatch(failure(error));
            }
        );
    };

    function success(participantData) {
        return {type: actionTypes.PARTICIPANT_PREFERENCES_UPDATED_SUCCESS, participantData}
    }
}

function settingStatus(status, callback) {
    return dispatch => {
        dispatch(success(status));
        callback('success');
    }

    function success(status) {
        return {type: actionTypes.SETTING_STATUS_UPDATED_SUCCESS, status}
    }
}

function geteventDataDefault(eventId, callback) {
    return dispatch => {
        generalFunctions.geteventDataDefault(eventId).then(result => {
            let eventData = result.data();
            eventData.id = result.id;
            dispatch(success(eventData));
            callback({ message: 'success', data: eventData });
        });
    }

    function success(eventData) {
        return {type: actionTypes.Fetch_EVENT_INFO_CHANGE_SUCCESS, eventData}
    }
}

function getEventInfoOnChange(eventId, callback) {
    return dispatch => {
        generalFunctions.getEventInfoOnChange(eventId,
            (result, unsubscribe) => {
                let eventData = result.data();
                eventData.id = result.id;
                dispatch(success(eventData));
                callback({ message: 'success', data: eventData }, unsubscribe);
            }
        );
    };

    function success(eventData) {
        return {type: actionTypes.Fetch_EVENT_INFO_CHANGE_SUCCESS, eventData}
    }
}

function sendVote(type, eventId, elementId, questionId, sessionId, votesList, participantsId) {
    return dispatch => {
        generalFunctions.sendVote(type, eventId, elementId, questionId, sessionId, votesList, participantsId, result => {
            if (result.message === 'SUCCESS') {
                dispatch(success(votesList));
            }
        });

    };

    function success(votesList) {
        return {type: actionTypes.SEND_VOTE_SUCCESS, votesList}
    }
}

function sendAnswerText(eventId, elementId, questionId, sessionId, participantReply, participantName, participantsId) {
    return dispatch => {
        generalFunctions.sendAnswerText(eventId, elementId, questionId, sessionId, participantReply, participantName, participantsId).then(result => {
            dispatch(success(participantReply));
        })
        // if(result === 'SUCCESS'){
        //     dispatch(success(participantReply));
        // }
    };

    function success() {
        return {type: actionTypes.SEND_ANSWER_TEXT_SUCCESS,}
    }
}

function sendRatingAnswer(eventId, elementId, questionId, sessionId, participantRating, participantName, participantsId) {
    return dispatch => {
        generalFunctions.sendRatingAnswer(eventId, elementId, questionId, sessionId, participantRating, participantName, participantsId).then(result => {
            dispatch(success(participantRating));
        })
        // if(result === 'SUCCESS'){
        //     dispatch(success(participantReply));
        // }
    };

    function success() {
        return {type: actionTypes.SEND_RATING_ANSWER_SUCCESS,}
    }
}

function setSplashPage(splashImageUrl, status) {
    return dispatch => {
        dispatch(success({ imageUrl: splashImageUrl, status: status }));
    }

    function success(splashImageData) {
        return {type: actionTypes.SET_SPLASH_PAGE_SUCCESS, splashImageData}
    }
}

function updateClientNotificationToken(userId, eventId, token) {
    return dispatch => {
        generalFunctions.updateClientNotificationToken(userId, eventId, token).then(
            result => {
                dispatch(success());
            }
        )

    }

    function success() {
        return {type: actionTypes.UPDATE_USER_NOTIFICATION_TOKEN_SUCCESS}
    }
}

function getAllEventMessages(eventId, callback) {
    return dispatch => {
        generalFunctions.getAllEventMessages(eventId, (eventMessageList, unsubscribe) => {


            // ).then(
            // querySnapShot => {
            //     let eventMessageList = [];
            //     querySnapShot.forEach(eventMessageDoc => {
            //         let eventMessageData = {
            //             id: eventMessageDoc.id,
            //             body: eventMessageDoc.data().body,
            //             title: eventMessageDoc.data().title,
            //             messageImportance: eventMessageDoc.data().messageImportance
            //         }
            //         eventMessageList.push(eventMessageData);
            //     });
            dispatch(success(eventMessageList));
            callback(eventMessageList, unsubscribe);
        }
        )

    }

    function success(eventMessageList) {
        return {type: actionTypes.FETCH_ALL_EVENT_MESSAGES_SUCCESS, eventMessageList}
    }
}

function getSurveyInfoOnChange(eventId, elementId, callback) {
    return dispatch => {
        generalFunctions.getSurveyInfoOnChange(eventId, elementId,
            (surveyInfo, unsubscribe) => {
                dispatch(success(surveyInfo));
                callback(surveyInfo, unsubscribe);
            },
            // error => dispatch(failure(error))
        );
    };

    function success(surveyInfo) {
        return {type: actionTypes.GET_SURVEY_INFO_ON_CHANGE_SUCCESS, surveyInfo}
    }
}

function getLibraryFileInfo(eventId, libraryId, fileId, callback) {
    return dispatch => {
        generalFunctions.getLibraryFileInfo(eventId, libraryId, fileId).then(libraryDoc => {
            if (libraryDoc.exists === true) {
                let libraryFile = libraryDoc.data();
                libraryFile.id = libraryDoc.id;

                dispatch(success(libraryFile));
                callback(libraryFile);
            } else {
                dispatch(success());
                callback("Not Exists");
            }
        });
    };

    function success(libraryFile) {
        return {type: actionTypes.GET_LIBRARY_FILE_SUCCESS, libraryFile}
    }
}

function getParticipantsNumber(eventId, callback) {
    return dispatch => {
        generalFunctions.getParticipantsNumber(eventId).then(result => {
            dispatch(success());
            callback(result.docs.length);
        });
    };

    function success() {
        return {type: actionTypes.GET_PARTICIPANTS_NUMBERS_SUCCESS}
    }

}

function getQRText(eventId, qrcodeId, callback) {
    return dispatch => {
        generalFunctions.getQRText(eventId, qrcodeId,
            (qrText, unsubscribe) => {
                dispatch(success(qrText));
                callback(qrText, unsubscribe);
            });
    };

    function success(qrText) {
        return {type: actionTypes.GET_QR_TEXT, qrText}
    }

}

function getServerHeartBeatOnChange(eventId) {
    return dispatch => {
        generalFunctions.getServerHeartBeatOnChange(eventId, (serverHeartBeat, unsubscribe) => {
                if (serverHeartBeat === undefined) {
                    serverHeartBeat = {
                        counter: -1,
                        unsubscribe: null
                    };
                }
                else {
                serverHeartBeat.unsubscribe = unsubscribe;
                }
                dispatch(success(serverHeartBeat));
            });
    };

    function success(serverHeartBeat) {
        return {type: actionTypes.UPDATE_SERVERHEARTBEAT_SUCCESS, serverHeartBeat}
    }
}

function resetServerHeartBeat() {
    let serverHeartBeat = null;
    return {type: actionTypes.UPDATE_SERVERHEARTBEAT_SUCCESS, serverHeartBeat}
}