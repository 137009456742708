import React, { Component } from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';
import Utility from '../Helpers/Utility';
import SplashComponent from '../Component/SplashComponent';
import {englishStrings} from '../Languages/en_EN';
import {italianStrings} from '../Languages/it_IT';
import {version} from '../../package.json';


class LogInScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputClass: '',
            eventCode: '',
            submitted: false,
            splashPage:false,
            event:'',
            splashImageUrl:'',
            planText1:"default",
            planText2:"default",
            modalSubject:"default"
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.startTimerToGo = this.startTimerToGo.bind(this);
        this.detectLanguage = this.detectLanguage.bind(this);
        this.closePlanModal = this.closePlanModal.bind(this);
        this.goToIntroduction = this.goToIntroduction.bind(this);

        this.redirectURL = this.props.match.params.reqURL;          
    }

    componentDidMount() {        
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            this.setDefaultLanguage();
        }
        
        var loginLanguage = Utility.getLoginLanguage();

        //we check that if we have already defined the user langugage based on the device language or the user selected her prefered language from the menu
        if (!loginLanguage) {
            this.setDefaultLanguage();
        }

        if (this.props.serverHeartBeat !== null) {
            if (this.props.serverHeartBeat.unsubscribe !== null) {
            this.props.serverHeartBeat.unsubscribe();
            }
            this.props.onResetServerHeartBeat();
        }
    
    }

    setDefaultLanguage() {
        var userLanguage = this.detectLanguage();
        if(userLanguage.includes("it")){
            Utility.setLoginLanguage("Italian");
        }else{
            Utility.setLoginLanguage("English");
        }
        Utility.setEventLanguage("Italian");
    }

    detectLanguage(){
        return (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
    }

    handleEventChange = (e) => {

        switch (e.target.name) {
            case "eventCode":
                this.setState({ [e.target.eventCode]: e.target.value, inputClass: 'validating' });
                break;

            default:
                return true;
        }
    }


    handleChange(e) {
        const { name, value } = e.target;
        if ([name])
            this.setState({ [name]: value.toLowerCase() });
    }

    handleSubmit(e) {

        e.preventDefault();

        this.setState({ submitted: true });
        const { eventCode } = this.state;
        

        if (eventCode) {
            var trimmedEventCode = eventCode.trim();
            this.props.onGetEventData(trimmedEventCode, callback => {
                if (callback === 'CodeNotExist') {
                    this.setState({
                        ...this.state,
                        inputClass: "is-invalid",
                    });
                } 
                else {
                    this.setState({
                        ...this.state,
                        event:callback
                    });
                    let participant = JSON.parse(localStorage.getItem('participant-'+callback.id));
                    this.props.onGetParticipantsNumber(callback.id, participantsNumbers => {});
                    if (participant && participant.length !== 0 ) {
                        // we check if the user is logging to the same event thet he has been previously logged in or is a new event 
                        // FC: it is not necessary to have the eventID in localstorage "partecipant". 
                        if(participant.eventId === callback.id){
                            if(callback.eventStatus === "Archived"){
                                this.setState({
                                    ...this.state, planText1: "This event is not accessible anymore.",
                                planText2:"For further information please contat us through the following email address:",
                                    modalSubject: "Access denied."
                                });
                                document.getElementById("planEmailModalBtn").click();
                            }else{
                                const loginLanguage = Utility.getLoginLanguage();
                                this.props.onUpdateParticipantData(participant, callback.id, 'newLogin', loginLanguage);
                                this.goToIntroduction(callback.id);
                                /*
                                if(callback.splashImageUrl === "default"){
                                    this.props.history.push("/event/"+callback.id+"/rooms"); 
                                }else{
                                    this.props.history.push("/event/"+ callback.id + "/introduction");
                                }
                                */
                            }
                        }else{
                            // check the event limitations
                            this.props.onGetParticipantsNumber(callback.id, participantsNumbers =>{
                                if(participantsNumbers >= callback.participantsNumberLimit ){
                                        document.getElementById("planEmailModal").click();
                                }else{
                                    const loginLanguage = Utility.getLoginLanguage();
                                    this.props.onLogin(callback.id, loginLanguage, result => {
                                        if (result === "success") {
                                            this.goToIntroduction(callback.id);
                                            /*
                                            if(callback.splashImageUrl === "default"){
                                                this.props.history.push("/event/"+callback.id+"/rooms"); 
                                            }else{
                                                this.props.history.push("/event/"+ callback.id + "/introduction");
                                            }
                                            */
                                        }
                                    });
                                }
                            })
                        }
                    } else {
                        if(callback.eventStatus === "Trial"){
                            this.props.onGetParticipantsNumber(callback.id, participantsNumbers =>{
                                if(participantsNumbers > callback.participantsNumberLimit ){
                                    this.setState({
                                        ...this.state, planText1: "You reached the maximum number of participants logged in for the trial version.",
                                                    planText2:"For further information regarding to our plans please contat us through the following email address:",
                                        modalSubject: "Plan Status"
                                    });
                                    document.getElementById("planEmailModalBtn").click();
                                }else{
                                    var value = "Italian";
                                    this.props.onLogin(callback.id, value, result => {
                                        if (result === "success") {
                                            this.goToIntroduction(callback.id);
                                            /*
                                            if(callback.splashImageUrl === "default"){
                                                this.props.history.push("/event/"+callback.id+"/rooms"); 
                                            }else{                                               
                                                this.props.history.push("/event/"+ callback.id + "/introduction");                                                
                                            }
                                            */
                                        }
                                    });
                                }
                            });
                        }else if(callback.eventStatus === "Archived"){
                            this.setState({
                                ...this.state, planText1: "This event is not accessible anymore.",
                            planText2:"For further information please contat us through the following email address:",
                                modalSubject: "Access denied."
                            });
                            document.getElementById("planEmailModalBtn").click();
                        }else{
                            var value = "Italian";
                            this.props.onLogin(callback.id, value, result => {
                                if (result === "success") {
                                    this.goToIntroduction(callback.id);
                                    /*
                                    if(callback.splashImageUrl === "default"){
                                        this.props.history.push("/event/"+callback.id+"/rooms"); 
                                    }else{
                                        this.props.history.push("/event/"+ callback.id + "/introduction");
                                    }
                                    */
                                }
                            });
                        }
                    }
                }
            });
        }
    }

    goToIntroduction(eventid) {
        /*
        if (callback.splashImageUrl === "default") {
            this.props.history.push("/event/" + callback.id + "/rooms");
        } else {
            this.props.history.push("/event/" + callback.id + "/introduction");
        }
        */
        this.props.history.push("/event/" + eventid + "/introduction");
    }

    startTimerToGo(){
        var properties = this.props;
        var eventId = this.state.event.id;
        setTimeout(function(){
            properties.history.push("/event/"+ eventId + "/introduction");
    }, 5000);
    }

    closePlanModal(){
		document.getElementById("closePlanModal").click();
	}

    render() {
        var selectedLanguageStrings;
        const loginLanguage = Utility.getLoginLanguage();
        if (loginLanguage === 'English') {
        selectedLanguageStrings = englishStrings;
       }else{
        selectedLanguageStrings = italianStrings;
       }
        const { loggingIn } = this.props;
        const { eventCode, submitted } = this.state;
        return (
            <main role="main">
                <div className="container ">
                    <form className="form loginScreen" onSubmit={this.handleSubmit}>
                        <div className="form-row">
                            <div className="col-1 col-lg-3">
                            </div>
                            <div className="col-10 col-lg-6  text-center">
                                <label htmlFor="eventCode" className="mb-2">{selectedLanguageStrings.event_code}: </label>
                                <div className={'input-group form-group' + (submitted && !eventCode ? ' has-error' : '')}>
                                    <div className="input-group-prepend">
                                        <span id="eventCodeLabel" className="input-group-text">#</span>
                                    </div>
                                    <input type="text" className={"form-control " + this.state.inputClass} value={eventCode} id="eventCode" name="eventCode" aria-label="Enter event code" aria-describedby="basic-addon1" onChange={this.handleChange} />

                                    <div className="invalid-feedback">
                                        {selectedLanguageStrings.event_code_error}
                                    </div>

                                    {submitted && !eventCode &&
                                        <div className="help-block">{selectedLanguageStrings.evet_code_request}</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-1 col-lg-3">
                            </div>
                            <div className="col-10 col-lg-6 text-center">
                                <button type="submit" className="col-12 col-lg-12 btn btn-primary logInBtn" id="login"> {selectedLanguageStrings.enter} </button>
                                {loggingIn}
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-2">
                        </div>
                        <div className="col-3 text-center">
                            <br/>
                        </div>
                    </div>
                    <div className="app-version">
                        4meet.in v. {version}
                    </div>

                    <div data-toggle="modal" data-target="#planEmailModal" id="planEmailModalBtn"></div>
                    <div className="modal fade" id="planEmailModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content planModal">
                            <div className="modal-header bg-primary">
                                <h5 className="modal-title text-white" id="exampleModalLabel">{this.state.modalSubject}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div className="modal-body emailPlanModal mx-2">
                            <p className="mb-3">
                                {this.state.planText1}
                            </p>
                            <p>
                                {this.state.planText2}<a href="mailto:info@netizens.it" target="_top">info@netizens.it</a>
                            </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="closePlanModal">
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary text-white" onClick={this.closePlanModal}>
                                    <a href="mailto:info@netizens.it" target="_top" className="emailPlanLink">
                                        Send Email
                                    </a>
                                </button>
                            </div>
                            </div>
                        </div>
					</div>
                </div>       
            </main>
        )
    }
}

//export default LogInScreen;

const mapDispatchToProps = dispatch => {
    return {

        onLogin: (eventId,value, callback) => dispatch(generalActions.login(eventId,value, callback)),
        onGetEventData: (eventCode, callback) => dispatch(generalActions.getEventData(eventCode, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onSetSplashPage: (splashImageUrl, status) => dispatch(generalActions.setSplashPage(splashImageUrl,status)),
        onGetParticipantsNumber: (eventId, callback) => dispatch(generalActions.getParticipantsNumber(eventId, callback)),
        onResetServerHeartBeat: () => dispatch(generalActions.resetServerHeartBeat()),
        // onGetEventAdminInfo: (eventId, callback) => dispatch(generalActions.getEventAdminInfo(eventId, callback)),

    };
}


function mapStateToProps(state) {
    const eventData = state.generalReducers.eventData;
    const serverHeartBeat = state.generalReducers.serverHeartBeat;
    const { loggingIn } = state.generalReducers;

    return {
        eventData,
        loggingIn,
        serverHeartBeat
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LogInScreen);
