import { actionTypes } from '../Actions/actionTypes';

let initialState = {
    questionList: null,
    questionOptions: [],
    questionData:[]
};

export function questionReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.Fetch_ALL_QUESTION_OPTIONS_SUCCESS:
            return {
                ...state,
                questionOptions: action.questionOptions,
            };

        case actionTypes.Fetch_QUESTION_DATA_SUCCESS:
            return {
                ...state,
                questionData: action.questionData
            }

        default:
            return state;
    }
};