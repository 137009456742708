import React, { Component } from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';
import Header from '../Component/Header';
import OldPollScreen from './OldPollScreen';
import OldQuestionScreen from './OldQuestionScreen';
import HeaderTab from '../Component/HeaderTab';
import { tweetsActions } from '../Redux/Actions/tweetsActions';
import { questionActions } from '../Redux/Actions/questionsActions';

// import firebase from 'firebase/app';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';
import 'react-tippy/dist/tippy.css';
// import { askForPermissioToReceiveNotifications } from '../Helpers/firebase';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import Utility from '../Helpers/Utility';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: "dashboard",
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            event: '',
            questionData: 'default',
            textColor: '',
            primaryColor: '',
            eventType: 'default',
            tweetTabStatus: 'disabled',
            questionTabStatus: 'disabled',
            tweetsListToShow: [],
            participantTweetsList: [],
            participantInfo: '',
            toggleActiveOrder: false,
            alertHappend: 'default',
            userStatus: 'initialized',
            componentInitialized: false,
            selectedOptionsListStored: 'default',
            voteSessionId: '',
            participantReplyStored: 'default',
            participantRatingStored: 'default',
            participantNameStored: '',
            responseType: 'default',
            loadNewQuestion: '',
            splashPageStatus: 'default',
            sidebarOpen: false,
            showMessageAlert: false,
            messageReceived: 'default',
            eventMessagesModal: 'eventMessagesModal',
            pushedEventMessageList: [],
            parentInfo: 'default',
            roomsList: [],
            allTweetsList: [],
            questionOptionsList: [],
            rootChildrenList: [],
            showAnswerAlert: true,
            overlayClassAlert: " ",

        };

        this.goRoomPage = this.goRoomPage.bind(this);
        this.goLibraryPage = this.goLibraryPage.bind(this);
        this.goToAgendaPage = this.goToAgendaPage.bind(this);
        this.goIntroduction = this.goIntroduction.bind(this);
        this.goLogInPage = this.goLogInPage.bind(this);
        this.goToSurveyPageModal = this.goToSurveyPageModal.bind(this);
        this.goToQuestionPage = this.goToQuestionPage.bind(this);
        this.askForPermission = this.askForPermission.bind(this);
        this.closeAnswerAlert = this.closeAnswerAlert.bind(this);
        
    }

    componentDidMount() {
        this.askForPermission();

        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        //we check if the participant is already logged in or not especially when the participant opens an incognitive tab or if deleted the local storage content
        if (!participant) {
            this.props.history.push("/");
        } else {
            if (this.unsubscribeEventInfoChange) {
                this.unsubscribeEventInfoChange();
            }
            this.props.onEventInfoChange(this.state.eventId, (callback, unsubscribe) => {
                this.unsubscribeEventInfoChange = unsubscribe;

                if (callback.data.eventStatus === "Archived") {
                    window.location.href = "/";
                }

                this.setState({
                    ...this.state,
                    event: callback.data,
                    participantInfo: participant,
                });

                if (!callback.data.availableLanguages.lang_it || !callback.data.availableLanguages.lang_en) {
                    if (callback.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (callback.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
                }

                //this.setState({ ...this.state, event: callback.data, participantInfo: participant});
                if (this.unsubscribeGetListOfRootChildrenOnChange) {
                    this.unsubscribeGetListOfRootChildrenOnChange();
                }
                this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (rootChildrenList, unsubscribe) => {
                    this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
                    let orederdRootChildrenList = [];
                    let orderedRoomsChildrenList = [];
                    let allElementsList = [];
                    for (var i = 0; i < rootChildrenList.length; i++) {
                        if (rootChildrenList[i].sParentId === "default") {
                            orederdRootChildrenList.push(rootChildrenList[i]);
                        } else if (rootChildrenList[i].sParentId === this.state.roomId) {
                            orderedRoomsChildrenList.push(rootChildrenList[i]);
                        }
                    }

                    orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
                    orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
                    allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);
                    allElementsList.map((item, index) => {
                        if (item.sType === 'survey') {

                            if (this.unsubscribeGetSurveyFiles) {
                                this.unsubscribeGetSurveyFiles();
                            }
                            this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                                this.unsubscribeGetSurveyFiles = unsubscribe;
        
                                surveyFilesList.forEach(surveyfile => {
                                    if (surveyfile) {
                                        item.surveyLinkUrl = surveyfile.fileUrl;
                                    }
                                });
        
                            });
        
                        }
                    });
                    this.setState({ ...this.state, rootChildrenList: allElementsList });
                });

                if (this.unsubscribeGetEventRoomsOnChange) {
                    this.unsubscribeGetEventRoomsOnChange();
                }
                this.props.onGetEventRoomsOnChange(this.state.eventId, (roomsList, unsubscribe) => {
                    this.unsubscribeGetEventRoomsOnChange = unsubscribe;

                    //Check if the current room exists, otherwise redirect to the rooms page.   
                    var roomExists = false;
                    if (roomsList.length > 0) {
                        for (var i = 0; i < roomsList.length; i++) {
                            if (roomsList[i].id === this.state.roomId) {
                                roomExists = true;
                                break;
                            }
                        }
                    }
                    if (roomExists === true) {
                        this.setState({
                            ...this.state,
                            roomsList: roomsList
                        });
                    } else {
                        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
                    }
                });

                if (this.unsubscribeParentInfoOnChange) {
                    this.unsubscribeParentInfoOnChange();
                }
                this.props.onGetParentInfoOnChange(this.state.eventId, this.state.roomId, (parentInfo, unsubscribe) => {
                    this.unsubscribeParentInfoOnChange = unsubscribe;
                    this.setState({
                        ...this.state,
                        parentInfo: parentInfo.data
                    });

                    if (parentInfo.message === 'success') {
                        if (parentInfo.data.currentLiveSession.status === "live") {
                            this.props.onGetQuestion(this.props.changedEventInfo.id, parentInfo.data.currentLiveSession.liveQuestionElement, parentInfo.data.currentLiveSession.liveQuestionId, callBackQuestionData => {
                                //to prevent the loading status on the client side when the admin changes the current question to view on the admin side
                                if (this.state.questionData.id !== parentInfo.data.currentLiveSession.liveQuestionId) {
                                    this.setState({
                                        ...this.state,
                                        questionOptionsList: []
                                    });
                                }
                                //Receiving the list of options in the current live session
                                this.props.ongetLiveSessionOptions(this.props.changedEventInfo.id, parentInfo.data.currentLiveSession.liveQuestionElement, parentInfo.data.currentLiveSession.liveQuestionId, parentInfo.data.currentLiveSession.id, callbackOptions => {
                                    this.setState({
                                        ...this.state,
                                        eventType: 'questions',
                                        questionData: callBackQuestionData,
                                        questionOptionsList: callbackOptions
                                    });
                                });
                            });

                        } else {
                            this.setState({ ...this.state, eventType: 'tweets', questionData: 'default' });
                        }
                        if (this.props.changedEventInfo.showTweetsToParticipants === "Yes") {
                            if (this.props.changedEventInfo.autoApproveTweets === "Without Moderator") {
                                if (this.unsubscribeGetAllTweets) {
                                    this.unsubscribeGetAllTweets();
                                }
                                this.props.onGetAllTweets(this.props.changedEventInfo.id, parentInfo.data.currentTweetElement, "Without Moderator", (allTweetscallback, unsubscribe) => {
                                    this.unsubscribeGetAllTweets = unsubscribe;
                                    this.setState({ ...this.state, allTweetsList: allTweetscallback });
                                });

                                if (this.unsubscribeGetParticipantTweets) {
                                    this.unsubscribeGetParticipantTweets();
                                }
                                this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, parentInfo.data.currentTweetElement, (participantTweetscallback, unsubscribe) => {
                                    this.unsubscribeGetParticipantTweets = unsubscribe;
                                    this.setState({ ...this.state, participantTweetsList: participantTweetscallback });
                                });
                            } else {
                                if (this.unsubscribeGetAllTweets) {
                                    this.unsubscribeGetAllTweets();
                                }
                                this.props.onGetAllTweets(this.props.changedEventInfo.id, parentInfo.data.currentTweetElement, this.props.changedEventInfo.tweetsToShow, (allTweetscallback, unsubscribe) => {
                                    this.unsubscribeGetAllTweets = unsubscribe;
                                    this.setState({ ...this.state, allTweetsList: allTweetscallback });
                                });

                                if (this.unsubscribeGetParticipantTweets) {
                                    this.unsubscribeGetParticipantTweets();
                                }
                                this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, parentInfo.data.currentTweetElement, (participantTweetscallback, unsubscribe) => {
                                    this.unsubscribeGetParticipantTweets = unsubscribe;
                                    this.setState({ ...this.state, participantTweetsList: participantTweetscallback });
                                });
                            }
                        } else {
                            if (this.unsubscribeGetParticipantTweets) {
                                this.unsubscribeGetParticipantTweets();
                            }
                            this.props.onGetparticipantTweets(participant.id, this.props.changedEventInfo.id, parentInfo.data.currentTweetElement, (participantTweetscallback, unsubscribe) => {
                                this.unsubscribeGetParticipantTweets = unsubscribe;
                                this.setState({ ...this.state, participantTweetsList: participantTweetscallback });
                            });
                        }
                    }
                });
            });


            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to 
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in 
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
                    }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data 
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this 
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect() 
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);


        }
    }

    componentDidUpdate(prevProps) {
        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        } else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                } else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }

        if (this.state.parentInfo !== "default") {
            if (this.state.parentInfo.currentLiveSession.status === "live") {
                        
                let storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                let storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
    
                var userAlreadyVoted = false;
                //we only go to the tweet question if the user did not already voted for the live question
                if (userAlreadyVoted === false && storedVotes && storedVotes.includes(this.state.parentInfo.currentLiveSession.id)) {
                    userAlreadyVoted = true;
                }
    
                if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(this.state.parentInfo.currentLiveSession.id)) {
                    userAlreadyVoted = true;
                }
    
                if (!this.props.changedEventInfo || this.props.changedEventInfo.automaticJumpPoll === "Active") {
                    if (userAlreadyVoted === false) {
                        this.goToPollPage();
                    }
                }
            }        
        }        
    }

    componentWillUnmount() {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeParentInfoOnChange) {
            this.unsubscribeParentInfoOnChange();
        }

        if (this.unsubscribeGetAllTweets) {
            this.unsubscribeGetAllTweets();
        }

        if (this.unsubscribeGetParticipantTweets) {
            this.unsubscribeGetParticipantTweets();
        }
    }

    handleTabChangeEvent(eventStatus) {
        if (eventStatus === 'tweets') {
            if (this.props.changedEventInfo.autoApproveTweets === "Without Moderator") {
                if (this.unsubscribeGetAllTweets) {
                    this.unsubscribeGetAllTweets();
                }
                this.props.onGetAllTweets(this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, "Without Moderator", (allTweetscallback, unsubscribe) => {
                    this.unsubscribeGetAllTweets = unsubscribe;
                    this.setState({ ...this.state, allTweetsList: allTweetscallback });
                });

                if (this.unsubscribeGetParticipantTweets) {
                    this.unsubscribeGetParticipantTweets();
                }
                this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, (callback, unsubscribe) => {
                    this.unsubscribeGetParticipantTweets = unsubscribe;
                    this.setState({ ...this.state, participantTweetsList: callback });
                });
            } else {
                if (this.unsubscribeGetAllTweets) {
                    this.unsubscribeGetAllTweets();
                }
                this.props.onGetAllTweets(this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, this.props.changedEventInfo.tweetsToShow, (callback, unsubscribe) => {
                    this.unsubscribeGetAllTweets = unsubscribe;
                    this.setState({ ...this.state, allTweetsList: callback });
                });
            }

            if (this.unsubscribeGetParticipantTweets) {
                this.unsubscribeGetParticipantTweets();
            }
            this.props.onGetparticipantTweets(this.state.participantInfo.id, this.props.changedEventInfo.id, this.state.parentInfo.currentTweetElement, (callback, unsubscribe) => {
                this.unsubscribeGetParticipantTweets = unsubscribe;
                this.setState({ ...this.state, eventType: 'tweets', participantTweetsList: callback });
            });

            // this.setState({ ...this.state, eventType: 'tweets' });
        } else if (eventStatus === 'questions') {
            this.setState({ ...this.state, eventType: 'questions' });
            this.props.onGetQuestion(this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, callBackQuestionData => {
                this.setState({
                    ...this.state,
                    questionOptionsList: []
                });
                this.props.ongetLiveSessionOptions(this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, callbackOptions => {
                    this.setState({
                        ...this.state,
                        // eventType: 'questions', 
                        questionData: callBackQuestionData,
                        questionOptionsList: callbackOptions
                    });
                });
            });

        }
    }

    sendResponse(responseType, value, sessionId) {
        if (responseType === 'MultipleChoice') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state,
                    selectedOptionsListStored: value,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.selectedOptionsListStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        constState.props.onSendVote('MultipleChoice', constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value, constState.state.participantInfo.id);
                        //localStorage.setItem('votes', this.state.sessionId);
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                        if (!storedVotes) {
                            var storedVotesNew = [];
                            storedVotesNew.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                        } else {
                            storedVotes.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the vote is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, selectedOptionsListStored: 'default' })
            } else {
                this.props.onSendVote('MultipleChoice', this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value, this.state.participantInfo.id);
                //localStorage.setItem('votes', this.state.sessionId);
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                if (!storedVotes) {
                    var storedVotesNew = [];
                    storedVotesNew.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                } else {
                    storedVotes.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                }

                this.setState({ ...this.state, selectedOptionsListStored: 'default' });
            }
        } else if (responseType === 'SimpleText') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state,
                    participantReplyStored: value.participantReply,
                    participantNameStored: value.participantName,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.participantReplyStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        constState.props.onSendAnswerText(constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value.participantReply, value.participantName, constState.state.participantInfo.id);
                        //localStorage.setItem('votes', this.state.sessionId);
                        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        if (storedAnswersText) {
                            storedAnswersText[storedAnswersText.length + 1] = constState.state.parentInfo.currentLiveSession.id;
                            localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                        } else {
                            var storedAnswersText = [];
                            storedAnswersText[0] = constState.state.parentInfo.currentLiveSession.id;
                            localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the Answer is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, participantReplyStored: 'default' })
            } else {
                constState.props.onSendAnswerText(this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value.participantReply, value.participantName, this.state.participantInfo.id);
                //localStorage.setItem('votes', this.state.sessionId);
                var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                if (storedAnswersText) {
                    storedAnswersText[storedAnswersText.length + 1] = constState.state.parentInfo.currentLiveSession.id;
                    localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                } else {
                    var storedAnswersText = [];
                    storedAnswersText[0] = constState.state.parentInfo.currentLiveSession.id;
                    localStorage.setItem("storedAnswersText", JSON.stringify(storedAnswersText));
                }
                this.setState({ ...this.state, participantReplyStored: 'default' })
            }
        } else if (responseType === 'SimpleTextAndMultipleChoice') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state, 
                    selectedOptionsListStored: value,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.selectedOptionsListStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        //Get the paticipant's id from the local storage
                        constState.props.onSendVote('SimpleTextAndMultipleChoice', constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value, constState.state.participantInfo.id);
                        //localStorage.setItem('votes', this.state.sessionId);
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                        if (!storedVotes) {
                            var storedVotesNew = [];
                            storedVotesNew.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                        } else {
                            storedVotes.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the vote is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, selectedOptionsListStored: 'default' })
            } else {
                this.props.onSendVote('SimpleTextAndMultipleChoice', this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value, this.state.participantInfo.id);
                //localStorage.setItem('votes', this.state.sessionId);
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                if (!storedVotes) {
                    var storedVotesNew = [];
                    storedVotesNew.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                } else {
                    storedVotes.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                }

                this.setState({ ...this.state, selectedOptionsListStored: 'default' });
            }
        } else if (responseType === 'RatingQuestion') {
            var constState = this;
            if (this.state.userStatus === "Offline") {
                this.setState({
                    ...this.state,
                    participantRatingStored: value.participantRating,
                    participantNameStored: value.participantName,
                    voteSessionId: sessionId,
                    responseType: responseType
                });
                //we store the options so as soon as user becomes online we will send it to the firebase
            } else if (this.state.userStatus === "Online" && this.state.participantRatingStored !== 'default') {
                setTimeout(function () {
                    if (sessionId === constState.state.parentInfo.currentLiveSession.id && constState.state.parentInfo.currentLiveSession.status === "live") {
                        constState.props.onSendRatingAnswer(constState.props.changedEventInfo.id, constState.state.parentInfo.currentLiveSession.liveQuestionElement, constState.state.parentInfo.currentLiveSession.liveQuestionId, constState.state.parentInfo.currentLiveSession.id, value.participantReply, value.participantName, constState.state.participantInfo.id);
                        //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                        //even in refreshing the page
                        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                        if (!storedVotes) {
                            var storedVotesNew = [];
                            storedVotesNew.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                        } else {
                            storedVotes.push(constState.state.parentInfo.currentLiveSession.id);
                            localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                        }
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    } else {
                        alert('voting session has been already closed the Answer is discarded.');
                        constState.setState({
                            ...constState.state,
                            selectedOptionsListStored: 'default',
                            voteSessionId: '',
                            responseType: 'default'
                        });
                    }
                }, 5000, constState);
                this.setState({ ...this.state, participantRatingStored: 'default' })
            } else {
                constState.props.onSendRatingAnswer(this.props.changedEventInfo.id, this.state.parentInfo.currentLiveSession.liveQuestionElement, this.state.parentInfo.currentLiveSession.liveQuestionId, this.state.parentInfo.currentLiveSession.id, value.participantRating, value.participantName, this.state.participantInfo.id);
                //we need to store the session Id of the released vote so the participant will not be able to vote again on the same session
                //even in refreshing the page
                var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
                if (!storedVotes) {
                    var storedVotesNew = [];
                    storedVotesNew.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotesNew));
                } else {
                    storedVotes.push(this.state.parentInfo.currentLiveSession.id);
                    localStorage.setItem("storedVotes", JSON.stringify(storedVotes));
                }

                constState.setState({ ...constState.state, selectedOptionsListStored: 'default' });
                this.setState({ ...this.state, participantRatingStored: 'default' })
            }
        }
    }


    goRoomPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library");
    }

    goToAgendaPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda");
    }
    
    goToQuestionPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
    }

    goToPollPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId + "/poll");
    }

    goToSurveyPageModal(survey) {
    	
        //Save in Survey Alert that survey popup has been viewed and OK is pressed to go to survey.
        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }

        if (survey.startInIframe) {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + survey.id);
        }
        else {
            this.props.onGetSurveyFilesData(this.props.changedEventInfo.id, survey.id, (surveyfiles) => {
                window.open(surveyfiles[0].fileUrl, "_blank");
            });
        }
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToRootChild(item, type) {
        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));
        var userAlreadyVoted = false;

        if (this.state.parentInfo.currentLiveSession.status === "live") {
            //we only go to the tweet question if the user did not already voted for the live question
            if (userAlreadyVoted === false && storedVotes && storedVotes.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(this.state.parentInfo.currentLiveSession.id)) {
                userAlreadyVoted = true;
            }

            if (userAlreadyVoted === true) {
                if (type === "agenda") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
                } else if (type === "library") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
                } else if (type === "survey") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
                } else if (type === "qrcode") {
                    this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
                }
            } else {
                this.setState({ ...this.state, showAnswerAlert: true, overlayClassAlert: "overlayClassAlert" });
                document.getElementById("showAnswerModalBtn").click();
            }
        } else {

            if (type === "agenda") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
            } else if (type === "survey") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
            } else if (type === "qrcode") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
            } else if (type === "library") {
                this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
            }
        }


        // if (type === "agenda") {
        //     this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
        // } else if (type === "survey") {
        //     this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
        // }
    }


    closeAnswerAlert() {
        this.setState({ ...this.state, showAnswerAlert: false, overlayClassAlert: " " });
    }

    askForPermission = async () => {
        //Checks if a browser supports notification capabilities.
        if (!firebase.messaging.isSupported()) {
            return;
        }
        var conState = this;
        var messaging = firebase.messaging();
        messaging.requestPermission()
            .then(function () {
                return messaging.getToken();
            })
            .then(function (token) {
                //alert(token);
                //we checkk if the stored token in the local storage is the same or not
                if (conState.state.participantInfo) {
                    if (!conState.state.participantInfo.sNotificationToken || conState.state.participantInfo.sNotificationToken !== token) {
                        Object.assign(conState.state.participantInfo, { 'sNotificationToken': token });
                        localStorage.setItem('participant-' + conState.state.participantInfo.eventId, JSON.stringify(conState.state.participantInfo));
                        conState.props.onUpdateClientNotificationToken(conState.state.participantInfo.id, conState.state.participantInfo.eventId, token)
                    }
                }
            })

            .catch(function (err) {

            })

        //The reason to is that if the page is already open is better to show on the page instead of showing on the notification !!!!!!
        messaging.onMessage(function (payload) {
            //we can show it as a little notification badge or something else
            if (payload.data.messageImportance === "high") {

                conState.setState({
                    ...conState.state,
                    showMessageAlert: true,
                    messageReceived: payload,
                    eventImportantModal: 'eventImportantModal'
                });
                document.getElementById('eventImportantModalBtn').click();
                // confirmAlert({
                //     title:  payload.notification.title,
                //     message: payload.notification.body,
                //     buttons: [
                //       {
                //           label:'Close'
                //         // label: 'Yes',
                //         // onClick: () => alert('Click Yes')
                //       },
                //     //   {
                //     //     label: 'No',
                //     //     onClick: () => alert('Click No')
                //     //   }
                //     ]
                //   })
            } else {
                conState.setState({ ...conState.state, messageReceived: payload });
            }
        });
    }

    onSelectLanguage(option) {

        if (option === 'English') {
            var value = 'English';
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('English');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });
        } else {
            var value = 'Italian';
            this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
            Utility.setEventLanguage('Italian');
            this.setState({
                ...this.state,
                sidebarOpen: false
            });

        }
    }

    render() {
        var selectedLanguageStrings;



        let userStoredLanguage = Utility.getEventLanguage();        
        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }

        var headerComponent;
        var activeScreenComponent;
        var borderType;
        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    goToAgendaPage={this.goToAgendaPage.bind(this)}
                    goToQuestionPage={this.goToQuestionPage.bind(this)}
					goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    pageName={"DashboardScreen"}
                    rootChildrenList={this.state.rootChildrenList}
                />

            </div>);

            if (this.state.eventType === 'questions') {
                borderType = 'border-bottom-question';
            } else if (this.state.eventType === 'tweets') {
                borderType = 'border-bottom-tweet';
            } else {
                borderType = "";
            }
        }

        console.log(this.state.event);
        return (
            <main role="main">
                <div>
                    <div>
                        {headerComponent}
                    </div>

                    {(this.state.event.themeParticipantImageUrl === "default") ? (
                        <div className="container container-screen-default ">
                            <div className="row pt-4">
                                {(this.state.parentInfo.roomIconColor && this.state.roomsList.length > 1) ? (
                                    <div className="col-12 text-success text-center">
                                        <h4 style={{ color: this.state.parentInfo.roomIconColor }}>
                                            {/* <i className="fas fa-users menuIcons pr-3" style={{color: this.state.parentInfo.roomIconColor }}></i> */}
                                            <i className="fas fa-door-open menuIcons pr-3"
                                               style={{color: this.state.parentInfo.roomIconColor}}></i>
                                            <b>{this.state.parentInfo.title}</b>
                                        </h4>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            {
                                (this.state.event.homeImageUrl === "default") ? (
                                    <div></div>
                                ) : (
                                    <div>
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-auto poolWaitingContainer">
                                                <img className="poolWaitingImg" src={this.state.event.homeImageUrl}></img>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        <div className="imageTheme"
                             style={{backgroundImage: "url(" + this.state.event.themeParticipantImageUrl + ")"}}>
                            <div className="container container-screen ">
                                <div className="row pt-4">
                                    {(this.state.parentInfo.roomIconColor && this.state.roomsList.length > 1) ? (
                                        <div className="col-12 text-success text-center">
                                            <h4 style={{ color: this.state.parentInfo.roomIconColor }}>
                                                {/* <i className="fas fa-users menuIcons pr-3" style={{color: this.state.parentInfo.roomIconColor }}></i> */}
                                                <i className="fas fa-door-open menuIcons pr-3"
                                                   style={{color: this.state.parentInfo.roomIconColor}}></i>
                                                <b>{this.state.parentInfo.title}</b>
                                            </h4>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {
                                    (this.state.event.homeImageUrl === "default") ? (
                                        <div></div>
                                    ) : (
                                        <div>
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-auto poolWaitingContainer">
                                                    <img className="poolWaitingImg" src={this.state.event.homeImageUrl}></img>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )}




                    <div id="showAnswerModalBtn" className="bg-success" data-toggle="modal"
                         data-target="#showAnswerModal">
                    </div>
                    {(this.state.showAnswerAlert === true) ? (
                        <div className="modal fade no-border" id="showAnswerModal" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content p-3 ">
                                    <div className="row align-items-center">
                                        <div className="col-10 alertAnswer">
                                            Before navigating to another page please answer the live question.
                                        </div>
                                        <div className="col-2 text-right">
                                            {/* <button type="button" className="btn alertAnswer pt-0 pb-0 pl-1 pr-1" data-dismiss="modal" 
                                            style={{ backgroundColor: this.props.changedEventInfo.primaryColor, color: this.props.changedEventInfo.textColor }}>
                                                Ok 
                                            </button> */}
                                            <button type="button" className=" alertAnswer pt-0 pb-0 pl-1 pr-1"
                                                    data-dismiss="modal">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </main>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetAllTweets: (eventId, tweetElId, option, callback) => dispatch(tweetsActions.getAllTweets(eventId, tweetElId, option, callback)),
        onGetparticipantTweets: (participantId, eventId, tweetIdEl, callback) => dispatch(tweetsActions.getparticipantTweets(participantId, eventId, tweetIdEl, callback)),
        ongetLiveSessionOptions: (eventId, elementId, questionId, liveSessionId, callback) => dispatch(questionActions.getLiveSessionOptions(eventId, elementId, questionId, liveSessionId, callback)),
        onGetQuestion: (eventId, elementId, questionId, callback) => dispatch(questionActions.getQuestion(eventId, elementId, questionId, callback)),
        onSendVote: (type, eventId, elementId, questionId, sessionId, votesList, participantsId) => dispatch(generalActions.sendVote(type, eventId, elementId, questionId, sessionId, votesList, participantsId)),
        onSendAnswerText: (eventId, elementId, questionId, sessionId, participantReply, participantName, participantsId) => dispatch(generalActions.sendAnswerText(eventId, elementId, questionId, sessionId, participantReply, participantName, participantsId)),
        onSendRatingAnswer: (eventId, elementId, questionId, sessionId, participantRating, participantName, participantsId) => dispatch(generalActions.sendRatingAnswer(eventId, elementId, questionId, sessionId, participantRating, participantName, participantsId)),
        onSetSplashPage: (splashImageUrl, status) => dispatch(generalActions.setSplashPage(splashImageUrl, status)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onGetParentInfo: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfo(eventId, selectedRoomId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onGetSurveyFilesData: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFilesData(eventId, surveyId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback))
    };
}

function mapStateToProps(state) {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
        eventType: state.generalReducers.eventType,
        splashPageData: state.generalReducers.splashPageData,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);